import { SET_USERRESET_STATE, SET_USER_DATA } from '../Constants';
import { SET_USERS_PROFILE_DATA } from '../Constants';
import { SET_USERS_GUARDIAN } from '../Constants';
import { SET_LOGIN_LINK } from '../Constants';
import { SET_USERS_LANG } from '../Constants';
import { SET_LOCATION } from '../Constants';
import { SET_SEARCH } from '../Constants';
import { SET_ENTITY_TOKEN } from '../Constants';
import { SET_ENTITY_DATA } from '../Constants';
import { SET_FOLLOWERS_DATA } from '../Constants';
import { SET_FEEDS_LOAD } from '../Constants';
import { SET_TOAST } from '../Constants';
import { SET_FEEDSSHOWING } from '../Constants';
import { SET_DEVICEFCM_TOKEN_DATA } from '../Constants';
import { SET_ACTIVE_STATE } from '../Constants';
import { SET_FOLLOW_REQUEST_DATA } from '../Constants';
import { SET_IS_NOTIFICATION_ENABLED } from '../Constants';
import { SET_IS_CONVO_SCREEN_OPEN } from '../Constants';
import { SET_LAST_GET_FEED } from '../Constants';
import { SET_BACKGROUND_MESSAGE } from '../Constants';



export const setUser = (data)=>({
  type: SET_USER_DATA,
  payload: data
});
export const setDeviceFcmToken = (data) => ({
  type: SET_DEVICEFCM_TOKEN_DATA,
  payload: data
});
export const setusersprofile = (data) => ({
    type: SET_USERS_PROFILE_DATA,
    payload: data
});
export const setuserisguardian = (data) => ({
    type: SET_USERS_GUARDIAN,
    payload: data
});
export const setLoginLink = (data) => ({
    type: SET_LOGIN_LINK,
    payload: data
});
export const setusersLang = (data) => ({
    type: SET_USERS_LANG,
    payload: data
});
export const setLocation = (data) => ({
    type: SET_LOCATION,
    payload: data
});
export const setSearch = (data) => ({
    type: SET_SEARCH,
    payload: data
});
export const setEntityToken = (data) => ({
    type: SET_ENTITY_TOKEN,
    payload: data
});

export const setEntitydata = (data) => ({
    type: SET_ENTITY_DATA,
    payload: data
});
export const setFollowersData = (data) => ({
    type: SET_FOLLOWERS_DATA,
    payload: data
});

export const setFeedsLoad = (data) => ({
    type: SET_FEEDS_LOAD,
    payload: data
});

export const setToastShow = (data) => ({
    type: SET_TOAST,
    payload: data
} );

export const setFeedShowing = ( data ) => ( {
    type: SET_FEEDSSHOWING,
    payload: data
} );

export const setisActive = ( data ) => ( {
    type: SET_ACTIVE_STATE,
    payload: data
} );

export const setFollowRequestData = ( data ) => ( {
    type: SET_FOLLOW_REQUEST_DATA,
    payload: data
} );

export const setIsNotificationEnabled = (data) => ({
    type: SET_IS_NOTIFICATION_ENABLED,
    payload: data
});

export const setIsConvoScreenOpen = (data) => ({
    type: SET_IS_CONVO_SCREEN_OPEN,
    payload: data
} );

export const setresetState = () => ({
  type: SET_USERRESET_STATE,
});

export const setLastFeedUpdate = ( data ) => ( {
    type: SET_LAST_GET_FEED,
    payload: data
} );

export const setBackgroundMessage = ( data ) => ( {
    type: SET_BACKGROUND_MESSAGE,
    payload: data
} );
