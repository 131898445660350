import React from 'react';
import { VStack, Text, HStack, Box, Pressable } from 'native-base';
import { useNavigate } from 'react-router-dom';
import { Community, CreateClass, CreateCommunity, CreateElite, CreateGraduation, CreateGroup, CreateNeighborhood, CreateOrganization, CreateSchool, CreateTeam, GroupIcon, OrganizationIcon, CreateClub} from '../../assets/icons/icon-assets';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Loc } from '../../App/Loc';

const CreateNew = ( { setShowCreateNewPage, setType, setIsShowEdit }) => {
    const currentUser = useSelector((state : RootStateOrAny) => state.user.user[ 0 ]);
    const handleNavigate = (type) => {
        setShowCreateNewPage( true );
        setIsShowEdit( false );
        setType(type);
    };
    return (
        <>
            <Box
                bgColor={'AVColor.white'}
                shadow={4}
                borderRadius={'10px'}
                borderColor={'AVColor.buttonBg'}
                width={'100%'}
                height={'auto'}
                paddingBottom={'15px'}
            >
                <Text
                    fontWeight={'400'}
                    fontSize={'24px'}
                    color={'AVColor.primary'}
                    paddingLeft={'22px'}
                    paddingTop={'15px'}
                >
                    {Loc.currentLang.post.createNew}
                </Text>
                <VStack width={'100%'} marginTop={'10px'} >
                    {/*Team*/}
                    <Pressable isDisabled={currentUser?.status === 'Deleted'}
                        onPress={ () => { handleNavigate("Team") }}
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={ '11px' }>
                            <Box alignSelf={'center'}>
                                <CreateTeam width={'27'} height={'20'} color={'#32AE78'} />
                                </Box>
                                <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.team }</Text>

                        </HStack>
                    </Pressable>
                    {/*Club*/}
                    <Pressable isDisabled={currentUser?.status === 'Deleted'}
                        onPress={ () => { handleNavigate("Club") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateClub width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={500}>{Loc.currentLang.menu.club}</Text>
                        </HStack>
                    </Pressable>
                    {/*EliteClub*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("EliteClub") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateElite width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.eliteclub }</Text>
                        </HStack>
                    </Pressable>
                    {/*School*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("School") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateSchool width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.school }</Text>
                        </HStack>
                    </Pressable>
                    {/*Group*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("Group") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateGroup width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.group }</Text>
                        </HStack>
                    </Pressable>
                    {/*Class*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("Class") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateClass width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.class }</Text>
                        </HStack>
                    </Pressable>
                    {/*Graduation Class*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("GradClass") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateGraduation width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.gradClass }</Text>
                        </HStack>
                    </Pressable>
                    {/*Organization*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("Organization") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateOrganization width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.createOrganization }</Text>
                        </HStack>
                    </Pressable>
                    {/*Neighborhood*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("Neighborhood") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        justifyContent={'center'}
                        paddingLeft={'22px'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateNeighborhood width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.neighborHood }</Text>
                        </HStack>
                    </Pressable>
                    {/* Community*/}
                    <Pressable isDisabled={ currentUser?.status === 'Deleted' }
                        onPress={ () => { handleNavigate("Community") } }
                        _hover={{
                            backgroundColor: 'gray.100'
                        }}
                        height={'40px'}
                        paddingLeft={'22px'}
                        justifyContent={'center'}
                    >
                        <HStack space={'11px'}>
                            <Box alignSelf={'center'}>
                                <CreateCommunity width={ '27' } height={ '20' } color={ '#32AE78' } />
                            </Box>
                            <Text fontSize='16px' fontWeight={ 500 }>{ Loc.currentLang.menu.community}</Text>
                        </HStack>
                    </Pressable>

                </VStack>
            </Box>
        </>
    );
}
export default React.memo(CreateNew);