export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_FEEDS_DATA = 'SET_FEEDS_DATA';
export const SET_CONVO_DATA = 'SET_CONVO_DATA';
export const SET_AlERTS_DATA = 'SET_AlERTS_DATA';
export const SET_FEEDS_UPDATE = 'SET_FEEDS_UPDATE';
export const SET_USERS_PROFILE_DATA = 'SET_USERS_PROFILE_DATA';
export const SET_NEW_AlERTS_DATA = 'SET_NEW_AlERTS_DATA';
export const SET_USERS_GUARDIAN = 'SET_USERS_GUARDIAN';
export const SET_LOGIN_LINK = 'SET_LOGIN_LINK';
export const SET_USERS_LANG = 'SET_USERS_LANG';
export const SET_LOCATION = "SET_LOCATION";
export const SET_SEARCH = "SET_SEARCH";
export const SET_ENTITY_TOKEN = "SET_ENTITY_TOKEN";
export const SET_ENTITY_DATA = "SET_ENTITY_DATA";
export const SET_FOLLOWERS_DATA = "SET_FOLLOWERS_DATA";
export const SET_FEEDS_LOAD = "SET_FEEDS_LOAD";
export const SET_TOAST = "SET_TOAST";
export const SET_FEEDSSHOWING = "SET_FEEDSSHOWING";
export const SET_DEVICEFCM_TOKEN_DATA = 'SET_DEVICEFCM_TOKEN_DATA';
export const SET_ACTIVE_STATE = 'SET_ACTIVE_STATE';
export const SET_FEEDSOWNER_DATA = 'SET_FEEDSOWNER_DATA';
export const SET_POSTLIST_DATA = 'SET_POSTLIST_DATA';
export const SET_FETCHPROCESS_DATA = 'SET_FETCHPROCESS_DATA';
export const SET_FOLLOW_REQUEST_DATA = 'SET_FOLLOW_REQUEST_DATA';
export const SET_IS_NOTIFICATION_ENABLED = 'SET_IS_NOTIFICATION_ENABLED';
export const SET_IS_CONVO_SCREEN_OPEN = 'SET_IS_CONVO_SCREEN_OPEN';
export const SET_LAST_GET_FEED = 'SET_LAST_GET_FEED';
export const SET_ALERT_STATE = 'SET_ALERT_STATE';
export const SET_CONVO_STATE = 'SET_CONVO_STATE';
export const SET_FEEDSSET_STATE = 'SET_FEEDSSET_STATE';
export const SET_USERRESET_STATE = 'SET_USERRESET_STATE';
export const SET_BACKGROUND_MESSAGE = 'SET_BACKGROUND_MESSAGE';
export const SET_FEEDS_SCROLL_UPDATE = 'SET_FEEDS_SCROLL_UPDATE';
export const SET_LAST_UPDATED_POST_DATE = 'SET_LAST_UPDATED_POST_DATE';