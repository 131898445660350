import React, { useState, useEffect, useCallback, useRef } from 'react';
import
    {
        VStack,
        ScrollView,
        HStack,
        Heading,
        Pressable,
        Input,
        Hidden,
        Text,
        Button,
        Divider,
        Box,
        Image,
        Tooltip,
        Radio,
        Stack,
        Center,
        NativeBaseProvider,
        CloseIcon,
        Checkbox,
    } from 'native-base';
import Header from '../../components/header/Header';
import { Dimensions } from 'react-native';
import RightContent from '../../components/body/Right';
import { Person } from '../../AVCore/AVCore';
import Moment from 'moment';
import { SearchIcon } from '../../assets/icons/icon-assets';
import * as AVI from '../../AVCore/Interfaces/AV.Interfaces';
import * as AV from '../../AVCore/AVCore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Store } from '../../Platform/Store';
import MyFollowings from 'components/body/MyFollowings';
import CreateNew from 'components/body/CreateNew';
import AVBody from 'components/common/AVBody';
import location from '../../assets/images/location.svg';
import AvatarWhite from '../../assets/icons/avatar-whitebg.svg';
import footballG from '../../../src/assets/images/football.svg';
import timer from '../../../src/assets/images/clock.svg';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Right from '../../components/body/Right';
import Placeholder from 'components/Ads/Placeholder';
import { name } from '@azure/msal-react/dist/packageMetadata';
import { Loc } from '../../App/Loc';
import { EditIcon, DeleteIcon, AddIcon } from '../../assets/icons/icon-assets';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { MoreHorizontalIcon } from '../../assets/icons/icon-assets';
import { Auth } from '../../Platform/Auth';
import { setUser, setusersLang, setEntitydata, setDeviceFcmToken, setusersprofile, setuserisguardian, setLocation, setSearch, setEntityToken, setFollowersData, setFeedsLoad, setToastShow, setFeedShowing, setisActive, setFollowRequestData, setIsNotificationEnabled } from '../../Redux/Actions/userActions';
import Dropdown from '../../assets/images/dropdown.svg';
import { AVButtonRoundedFull, AVButtonRoundInvert } from 'components/common/AVButtons';
import settings from '../../assets/images/settings.png';
/*import Select, { components } from 'react-select';*/
import Roles from '../../assets/images/Roles.png';
import AVInputtext from '../../components/AVInputtext';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AVlinktext from '../common/AVlinktext';
import { setConvo } from '../../Redux/Actions/convoActions';
import { setFeedOwners, setFeeds, setFeedsUpdate, setFetchProcess, setPostList } from '../../Redux/Actions/feedsAction';
import { setAlerts, setNewAlerts } from '../../Redux/Actions/alertActions';
import CustomToast from '../../Hooks/useCustomToast';
import useClearCache from '../../Hooks/useClearCache';


function Accountsettings ( props : any )
{
    const location : any = useLocation();
    const accountsetting = location?.state?.accountsetting;
    const dispatch = useDispatch();
    const params = useParams();
    const EntityId = params?.entityId;
    const region = params?.region;
    const type = params?.type;
    const [ Entity, setIsEntity ] = React.useState( location?.state?.Entity );
    const navigate = useNavigate();
    const user = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const [ isdelete, setisisdelete ] = React.useState(
        Entity
            ? Entity?.status === 'Deleted'
                ? true
                : false
            : user?.status === 'Deleted'
                ? true
                : false
    );
    const [ isSuspended, setisSuspended ] = React.useState(
        Entity
            ? Entity?.status === 'Suspended'
                ? true
                : false
            : user?.status === 'Suspended'
                ? true
                : false
    );
    const userLang = useSelector( ( state : RootStateOrAny ) => state?.user?.userLang[ 0 ] );
    const [ fixedWidth, setFixedWidth ] = React.useState<any>();
    const rightRef = React.useRef<any>( null );
    const [ input, setInput ] = useState( false );
    const [ verifycode, setverifycode ] = React.useState<any>();
    const [ changeEmail, setChangeEmail ] = useState( false );
    const [ userePhone, setuserePhone ] = React.useState( user?.phone );
    const [ useremail, setuseremail ] = React.useState( user?.email );
    const [ inputNumber, setInputNumber ] = useState( userePhone ? false : true );
    const [ isActive, setIsActive ] = React.useState( false );
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const page = location?.state?.page;
    const [ langType, setlangType ] = useState( [] );
    const [ isActives, setIsActives ] = React.useState( false );
    const [ isActivities, setIsActivities ] = React.useState( false );
    /*    const Entity = location?.state?.Entity;*/
    const IsEdit = location?.state?.isEdit;
    const [ roles, setroles ] = useState( Entity?.roles?.length > 0 ? Entity?.roles[ 0 ]?.name : '' );
    const [ rolesList, setrolesList ] = React.useState( Entity?.roles );
    const [ isHomepage, setisHomepage ] = React.useState(accountsetting === 'HomePage' ? true : false );
    const [ checkmail, setcheckmail ] = React.useState( user?.preferences?.email ? true : false );
    const [ checktext, setchecktext ] = React.useState( user?.preferences?.txt ? true : false );
    const dimension = Dimensions.get( 'window' );
    const [ isnewrole, setisnewrole ] = React.useState( '' );
    const [ newcanedit, setnewcanedit ] = React.useState<any>( true );
    const [ newispuplic, setnewispuplic ] = React.useState<any>( true );
    const dimensions = useWindowDimension();
    const mobile = dimensions?.width < 600 || false;
    const isMobile = dimension?.width <= 500 || false;
    const [ popup, setPopup ] = useState( false );
    const [ isdeletepopup, setisdeletepopup ] = useState( false );
    const [ isdeletepopupLoader, setisdeletepopupLoader ] = useState( false );
    const [ isSuspendedpopup, setisSuspendedpopup ] = useState( false );
    const [ isSuspendedLoader, setisSuspendedLoader ] = useState( false );
    const [ canEdit, setCanEdit ] = useState( false );
    const [ publicProfile, setPublicProfile ] = useState( false );
    const [ Prefernces, setPrefernces ] = React.useState(Entity?.preferences ? Entity?.preferences : user?.preferences );
    const [ currentuserlang, setcurrentuserlang ] = React.useState<any>('');
    const [ roleisLoading, setroleisLoading ] = React.useState( false );
  const [ isLoading, setisLoading ] = React.useState(false);

  const [ visibility, setvisibility ] = React.useState(
    (Entity?.type === "School" || Entity?.subType === "Club" || Entity?.type === "Team") ? 'Follow' :
      (Entity?.subType === "Class" || Entity?.subType === "GradClass" || Entity?.type === "Group" || Entity?.type === "Organization" || Entity?.type === "Neighborhood") ? Loc.currentLang.menu.private :
        Prefernces?.defaultPostVisibility
  );


    const [ mailtype, setmailtype ] = React.useState<any>();
    const [ isAddRole, setIsAddRole ] = React.useState( false );
    const [ showMessage, setShowMessage ] = useState( false );
    const [ isRole, setIsRole ] = useState( IsEdit ? true : false );
    const [ isGeneral, setIsGeneral ] = useState( IsEdit ? false : true );
    const [ message, setMessage ] = useState( 'Please enter 4 digits code We sent you by text' );
    const [ emailalert, setemailalert ] = React.useState( false );
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [ selectedOption, setSelectedOption ] = useState( 'Followers' );
    const [ editrolepopup, setEditrolePopup ] = useState( false );
    const isAllowPlayerPost = (Entity?.type == "Team" || Entity?.type == "Club" || Entity?.type == "School" || Entity?.type == "Group" || Entity?.type == "Community" || Entity?.subType == "GradClass" || Entity == undefined || Entity?.type == "Neighborhood" || Entity?.type === "Person" || Entity?.type == "Organization" ) ? false : true;
    const [ langarrayList, setlangarrayList ] = React.useState<any>( [] )

    React.useLayoutEffect( () =>
    {
        setFixedWidth( rightRef.current?.offsetWidth - 2 );
    }, [] );

  const clearCache = useClearCache();

  const handleClearCache = () => {
    clearCache(true); // Pass true to reload the window
  };

    const handleAddEmail = async ( type ) =>
    {
        if ( type === 'Email' )
            if ( !emailRegex.test( useremail ) )
            {
                setemailalert( true );
                return;
            } else setemailalert( false );
        if ( type ) setmailtype( type );
        if (
            type !== undefined && type === 'Email'
                ? useremail !== user?.email
                : userePhone !== user?.phone
        )
        {
            //TODO: Need to check the flow for below code
            let contact : any = {
                type: type,
                contact: type === 'Phone' ? userePhone : useremail,
            };
            setverifycode( '' );
            setPopup( true );
            let val = await AV.Person.VerifyContact( contact );
        }
    };

    const handlegoBack = async () =>
    {
        navigate( -1 );
    };

    const handleupdatepreference = async () =>
    {
        setisLoading( true );
        Prefernces.defaultPostVisibility = visibility;
        if ( Prefernces.lang != currentuserlang?.key )
        {
            navigate( '/' );
            window.location.href = window.location.origin;
            window.location.reload();
        }
      Prefernces.lang = currentuserlang?.key ? currentuserlang?.key : currentuserlang;
        await AV.AVEntity.updatePreferences(
            Entity ? AV.Person.getLink( Entity ) : AV.Person.getLink( user ),
            Prefernces
        );
        await Person.getSelf().then( ( x ) =>
        {
            dispatch( setUser( x ) );
            setPrefernces( x?.preferences );
            Loc.SetLang( x?.preferences?.lang );
        } );
        setisLoading( false );
        navigate( -1 );
    };

    const handleAddRole = async () =>
    {
        setroleisLoading( true );
        let role : any = {
            name: isnewrole,
            rights: { isPublic: newispuplic, canUpdate: newcanedit }
        };

        // Convert the new role name to lowercase for case-insensitive comparison
        let roleNameLower = isnewrole.toLowerCase();

        // Check if any existing role has the same name (case-insensitive)
        const roleExistsCaseInsensitive = rolesList.some( ( existingRole ) => existingRole.name.toLowerCase() === roleNameLower );

        // Check if any existing role has the exact same name (case-sensitive)
        const roleExistsCaseSensitive = rolesList.some( ( existingRole ) => existingRole.name === isnewrole );

        // If a role with the same name exists (either case-sensitive or case-insensitive), prevent adding the role
        if ( roleExistsCaseInsensitive || roleExistsCaseSensitive )
        {
            let clientError = { message: 'Role already exists.' }
            CustomToast( { clientError } ); // Display toast message
            setroleisLoading( false );
            return;
        }

        let returnval = await AV.OrgBase.addRole( AV.AVEntity.getFk( Entity ), role );
        if ( returnval )
        {
            setrolesList( returnval?.roles );
        }
        setisnewrole( '' );
        setnewcanedit( false );
        setnewispuplic( false );
        setroleisLoading( false );
        setIsAddRole( false );
        setEditrolePopup( false ); // Close the popup
    };

    const handleverfication = async () =>
    {
        setisLoading( true );
        let contact : AV.ITokenProcessRequest = {
            token: verifycode,
            type: mailtype,
        };
        try
        {
            let val = await AV.Person.changeContact( contact );
            console.log( val );
            if ( val )
            {
                dispatch( setUser( val ) );
            }
        } catch ( error )
        {
            console.error( "Error changing contact:", error );
            setMessage( 'Error occurred or token expired. Please send new code and try again...' );
            setShowMessage( true );
            setisLoading( false );
            return;
        } 
        if ( mailtype === 'Email' ) setInput( false );
        if ( mailtype === 'Phone' ) setInputNumber( false );
        setPopup( false );
        setisLoading( false );
    };

    const [ editMode, setEditMode ] = useState( false );
    const [ editedData, setEditedData ] = useState<any>( {} );

    const handleEdit = ( id ) =>
    {
        const item = rolesList.find( ( item ) => item.id === id );
        setEditedData( item );
        setisnewrole( item?.name );

        // Set initial state of checkboxes based on existing values
        setPublicProfile( !!item?.rights?.isPublic );
        setCanEdit( !!item?.rights?.canUpdate );

        setEditMode( true );
    };

    const handleEditroleSave = async () =>
    {
        setroleisLoading( true );

        // Convert the edited role name to lowercase for case-insensitive comparison
        let editedRoleNameLower = isnewrole.toLowerCase();

        // Check if any existing role has the same name (case-insensitive)
        const roleExistsCaseInsensitive = rolesList.some( ( existingRole ) => existingRole.name.toLowerCase() === editedRoleNameLower );

        // Check if any existing role has the exact same name (case-sensitive)
        const roleExistsCaseSensitive = rolesList.some( ( existingRole ) => existingRole.name === isnewrole );

        // If a role with the same name exists (either case-sensitive or case-insensitive), prevent editing the role
        if ( roleExistsCaseInsensitive || roleExistsCaseSensitive )
        {
            let clientError = { message: 'Role already exists.' }
            CustomToast( { clientError } ); // Display toast message
            setroleisLoading( false );
            return;
        }

        // Update the role with the edited data
        editedData.name = isnewrole;
        editedData.rights.isPublic = publicProfile;
        editedData.rights.canUpdate = canEdit;
        let returnEditval = await AV.OrgBase.updateRole( AV.AVEntity.getFk( Entity ), editedData );
        if ( returnEditval )
        {
            setrolesList( returnEditval?.roles );
        }
        setEditMode( false );
        setisnewrole( '' );
        setnewcanedit( false );
        setnewispuplic( false );
        setroleisLoading( false );
        setIsAddRole( false );
        setEditrolePopup( false ); // Close the popup
    };


    const handleRoleDelete = async ( item ) =>
    {
        let returndelval = await AV.OrgBase.removeRole( AV.AVEntity.getFk( Entity ), item );
        if ( returndelval )
        {
            setrolesList( returndelval?.roles );
        }
    };

    const menuRefs = useRef( null );
    useOutsideAlerter( menuRefs );
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setIsActivities( false );
                    setIsActives( false );
                    setIsAddRole( false );
                    setInput( false );
                    setInputNumber( false );
                }
            }
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }
    const handledeleteaccount = async () =>
    {
        setisdeletepopupLoader( true )
        if ( isdelete )
        {
            let undeleEntity : any = await AV.AVEntity.unDelete(
                Entity ? AV.AVBase.getFk( Entity ) : AV.AVBase.getFk( user )
            );
            entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === undeleEntity?.id ) ) ] = undeleEntity;
            dispatch( setEntitydata( entitydatalist ) );
            setisisdelete( false );
        } else
        {
            let deleteEntity : any = await AV.AVEntity.delete(
                Entity ? AV.AVBase.getFk( Entity ) : AV.AVBase.getFk( user )
            );
            if (!Entity )
            {
                let data : any = user ? await AV.AVEntity.hideOrg( AV.AVEntity.getFk( user ) ) : ''
                user.status = 'Deleted';
                dispatch( setUser( user ) )
            }
            else
            {
                let data : any = Entity ? await AV.AVEntity.hideOrg( AV.AVEntity.getFk( Entity ) ) : ''
                Entity.status = 'Deleted';
                entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === Entity?.id ) ) ] = Entity;
                dispatch( setEntitydata( entitydatalist ) );
            }
            setisisdelete( true );
            if ( !Entity )
            {
                Auth.logout();
               
            }
            else
            {
                navigate( -1 )
            }
        }
        setisdeletepopup( false );
        setisdeletepopupLoader( false );
        // await Auth.logout();
    };
    const handleSuspendedaccount = async () =>
    {
        setisSuspendedLoader( true );
        if ( isSuspended )
        {
            let suspendedEntity = await AV.AVEntity.unSuspend(
                Entity ? AV.AVBase.getFk( Entity ) : AV.AVBase.getFk( user )
            );
            setisSuspended( false );
        } else
        {
            let suspendedEntity = await AV.AVEntity.suspend(
                Entity ? AV.AVBase.getFk( Entity ) : AV.AVBase.getFk( user )
            );
            setisSuspended( true );
            
        }
        setisdeletepopup( false );
        setisSuspendedpopup( false )
        setisSuspendedLoader( false );
        //    await Auth.logout();
    };
    React.useEffect( () =>
    {
        async function fetchData ()
        {
            if ( EntityId !== null && Entity == undefined )
            {
                var teamValue = user?.orgs?.filter( ( x ) => x?.id === EntityId );
                if ( teamValue?.length > 0 )
                {
                    let entityval : any = await AV.AVEntity.get( teamValue[ 0 ] );
                    setIsEntity( entityval );
                    setrolesList( entityval?.roles );
                    setPrefernces( entityval?.preferences );
                } else setisHomepage( true );
            }
            if ( !userLang )
            {
                let val = await AV.AVEntity.langList( Entity );
                if ( val )
                {
                    setlangarrayList( val );
                    dispatch( setusersLang( [ val ] ) );
                }
            } else
            {
                setlangarrayList( userLang );
            }
            let loclangList = Loc.currentLang.lang;
            const locLangListValue:any = Object.entries( loclangList ).map( ( [ key, value ] ) => ( { key, value } ) );
            setlangarrayList( locLangListValue || [] );
            let index = locLangListValue.findIndex( item => item.key === Prefernces?.lang );
            setcurrentuserlang( locLangListValue[ index ])
        }
        fetchData();
    }, [] );
    const handleInputChange = async ( e ) =>
    {
        setEditedData( { ...editedData, [ e.target.name ]: e.target.value } );
    };
    const [ showEdit, setIsShowEdit ] = useState( false );
    return (
        <AVBody type='accountsettings' isGeneral={ isGeneral } setIsShowEdit={ setIsShowEdit } setIsGeneral={ setIsGeneral } isRole={ isRole } setIsRole={ setIsRole } Entity={ Entity } setIsEntity={ setIsEntity } isHomepage={ isHomepage } setShowCreateNewPage={ undefined } showCreateNewPage={ undefined } setCreateType={ undefined } createType={ undefined } passEntity={ undefined } setPassEntity={ undefined }>
            <VStack>
                <VStack flex={ [ 1, 1, 1, 3 ] }>
                    { isGeneral && (
                        <Box
                            bgColor='#fff'
                            shadow='1'
                            borderRadius={ 5 }
                            marginBottom={ '30px' }
                            fontSize={ '18px' }
                            height={ '100%' }
                            width={ '100%' }
                        >
                            <VStack px={ 3 } pt={ 2 }>
                                <>
                                    <Text
                                        color=' rgba(0,0,0,0.85)'
                                        fontSize={ '24px' }
                                        fontWeight={ 400 }
                                        marginLeft={ '30px' }
                                        marginTop={ '13px' }
                                    >
                                        { Loc.currentLang.menu.generalSettings }
                                    </Text>
                                    { isHomepage && (
                                        <>
                                            <Divider margin={ '15px' } marginY={ '20px' } width={ '96%' } />
                                            <HStack marginTop={ 'px' } width={ '97%' } marginLeft={ '30px' }>
                                                <Text
                                                    width={ '33%' }
                                                    textAlign={ 'left' }
                                                    fontSize={ '18px' }
                                                    numberOfLines={ 1 }
                                                    ellipsizeMode='tail'
                                                >
                                                    { Loc.currentLang.menu.email }
                                                </Text>
                                                { input ? (
                                                    <Box width={ '33%' } >
                                                        <AVInputtext
                                                            type={ 'text' }
                                                            label={ Loc.currentLang.menu.emailAddress }
                                                            onChange={ ( e ) => setuseremail( e.target.value ) } defaultValue={ undefined } width={ undefined } value={ useremail } height={ undefined } minRows={ undefined } multiline={ undefined } maxLength={ undefined } size={ undefined } placeholder={ undefined } />
                                                    </Box>
                                                ) : (
                                                    <Text width={ '33%' } fontSize={ '18px' } numberOfLines={ 1 }>
                                                        { useremail }
                                                    </Text>
                                                ) }
                                                { emailalert && (
                                                    <Text
                                                        width={ '33%' }
                                                        position={ 'absolute' }
                                                        left={ '34%' }
                                                        top={ '55px' }
                                                        textAlign={ 'center' }
                                                        color={ '#DF2827' }
                                                        fontSize={ '14px' }
                                                    >
                                                        { Loc.currentLang.menu.enterValidEmail }
                                                    </Text>
                                                ) }
                                                { input && (
                                                    <HStack width={ '33%' } alignItems={ 'center' } justifyContent={ 'flex-end' } right={ '5px' }>
                                                        <Pressable
                                                            onPress={ () =>
                                                            {
                                                                handleAddEmail( 'Email' );
                                                                setmailtype( 'Email' );
                                                            } }
                                                        >
                                                            <Text
                                                                color={ '#00A7BC' }
                                                                textAlign={ 'left' }
                                                                fontSize={ '18px' }
                                                                numberOfLines={ 1 }
                                                                ellipsizeMode='tail'
                                                                width={ isMobile ? '80px' : "" }
                                                            >
                                                                { isMobile ? 'Change' : Loc.currentLang.menu.changeEmail }
                                                            </Text>
                                                        </Pressable>
                                                    </HStack>
                                                ) }
                                                { !input && (
                                                    <Pressable
                                                        position={ 'absolute' }
                                                        right={ '5px' }
                                                        onPress={ () => setInput( !input ) }
                                                    >
                                                        <EditIcon size='5' marginRight={ '12px' } />
                                                    </Pressable>
                                                ) }
                                            </HStack>
                                            <Divider
                                                margin={ '15px' }
                                                width={ '97%' }
                                                marginY={ '20px' }
                                                marginTop={ emailalert ? '20px' : '15px' }
                                            />
                                            <HStack width={ '97%' } marginLeft={ '30px' }>
                                                <Text
                                                    width={ '33%' }
                                                    textAlign={ 'left' }
                                                    fontSize={ '18px' }
                                                    numberOfLines={ 1 }
                                                    ellipsizeMode='tail'
                                                    marginTop={ isMobile ? "15px" : '' }
                                                >
                                                    { Loc.currentLang.menu.phone }
                                                </Text>
                                                { inputNumber ? (
                                                    <Box width={ isMobile ? "40%" : '33%' } marginRight={ isMobile ? "115px" : '' }>
                                                        <AVInputtext
                                                            type={ 'text' }
                                                            label={ Loc.currentLang.menu.phoneNumber }
                                                            onChange={ ( e ) => setuserePhone( e.target.value.replace( /[^0-9]/g, '' ) ) } 
                                                            value={ userePhone } defaultValue={ undefined } width={ undefined } height={ undefined } minRows={ undefined } multiline={ undefined } maxLength={ undefined } size={ undefined } placeholder={ undefined } />
                                                        <Text>{ Loc.currentLang.menu.addACountryCodeToAPhoneNumber }</Text>
                                                        </Box>
                                                ) : (
                                                    <Text width={ isMobile ? "40%" : '33%' } fontSize={ '18px' }>
                                                        { userePhone }
                                                    </Text>
                                                ) }

                                                { inputNumber && (
                                                    <Pressable
                                                        position={ 'absolute' }
                                                        right={ '15px' }
                                                        top={ '10px' }
                                                        onPress={ () =>
                                                        {
                                                            handleAddEmail( 'Phone' );
                                                            setmailtype( 'Phone' );
                                                        } }
                                                    >
                                                        <Text
                                                            color={ '#00A7BC' }
                                                            textAlign={ 'right' }
                                                            fontSize={ '18px' }
                                                            numberOfLines={ 1 }
                                                            ellipsizeMode='tail'
                                                        >
                                                            { user?.phone
                                                                ? Loc.currentLang.menu.changePhone
                                                                : Loc.currentLang.menu.addPhone }
                                                        </Text>
                                                    </Pressable>
                                                ) }
                                                { !inputNumber && (
                                                    <Pressable
                                                        position={ 'absolute' }
                                                        right={ 0 }
                                                        onPress={ () => setInputNumber( !inputNumber ) }
                                                    >
                                                        <EditIcon size='5' marginRight={ '12px' } />
                                                    </Pressable>
                                                ) }
                                            </HStack>
                                        </>
                                    ) }
                                    <Box
                                        borderStyle={ 'solid' }
                                        borderWidth={ '1px' }
                                        borderRadius={ '10px' }
                                        width={ isMobile ? '100%' : "96%" }
                                        marginLeft={ isMobile ? "" : '13px' }
                                        marginBottom={ '10px' }
                                        marginTop={ '30px' }
                                        borderColor={ 'gray.400' }
                                    >
                                        { isHomepage && (
                                            <HStack marginTop={ '25px' } width={ '94%' } marginLeft={ '45px' }>
                                                <Text
                                                    width={ '33%' }
                                                    textAlign={ 'left' }
                                                    lineHeight={ '2.5em' }
                                                    fontSize={ '18px' }
                                                    numberOfLines={ 1 }
                                                    ellipsizeMode='tail'
                                                >
                                                    {isMobile ? Loc.currentLang.menu.language : Loc.currentLang.menu.preferredLanguage }
                                                </Text>

                                                <HStack width={ '33%' } marginLeft={ '10px' }>
                                                    <FormControl fullWidth>
                                                        <InputLabel id='demo-simple-select-label'>{ Loc.currentLang.menu.language }</InputLabel>
                                                        <Select
                                                            labelId='demo-simple-select-label'
                                                            id='demo-simple-select'
                                                            value={ currentuserlang }
                                                            label='Languages'
                                                            onChange={ ( e ) => setcurrentuserlang( e.target.value) }
                                                            MenuProps={ {
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 300,  // Adjust the maxHeight as per your requirement
                                                                    },
                                                                },
                                                            } }

                                                        >
                                                            { langarrayList.map( ( option ) => (
                                                                <MenuItem key={ option } value={ option }>
                                                                    { option.value }
                                                                </MenuItem>
                                                            ) ) }
                                                        </Select>
                                                    </FormControl>
                                                </HStack>
                                            </HStack>
                                        ) }
                                        { isHomepage && (
                                            <Divider marginY={ isMobile ? "10px" : '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        ) }
                                        { isHomepage && (
                                            <HStack marginTop={ '20px' } width={ '97%' } marginLeft={ '45px' } zIndex={ -2 }>
                                                <Text
                                                    width={ '33%' }
                                                    textAlign={ 'left' }
                                                    fontSize={ '18px' }
                                                    numberOfLines={ 1 }
                                                    ellipsizeMode='tail'
                                                >
                                                    { isMobile ? Loc.currentLang.menu.contact : Loc.currentLang.menu.contactPreferencies }
                                                </Text>
                                                <Box alignItems='flex-start' zIndex={ -2 } width={ '33%' } marginLeft={ '10px' } >
                                                    <Stack
                                                        direction={ {
                                                            base: 'row',
                                                            md: 'row',
                                                        } }
                                                        space={ '35px' }
                                                        alignItems='flex-start'
                                                        zIndex={ -2 }
                                                        flexWrap={ 'wrap' }
                                                    >
                                                        <Checkbox
                                                            value={ Prefernces?.email }
                                                            size='sm'
                                                            zIndex={ -2 }
                                                            isChecked={ Prefernces?.email }
                                                            onChange={ () =>
                                                                setPrefernces( ( prefernces ) =>
                                                                {
                                                                    return {
                                                                        ...prefernces,
                                                                        email: Prefernces?.email ? false : true,
                                                                    };
                                                                } )
                                                            }
                                                        >
                                                            { Loc.currentLang.menu.email }
                                                        </Checkbox>
                                                        <Checkbox
                                                            value={ Prefernces?.txt }
                                                            size='sm'
                                                            zIndex={ -2 }
                                                            isChecked={ Prefernces?.txt }
                                                            onChange={ () =>
                                                                setPrefernces( ( prefernces ) =>
                                                                {
                                                                    return { ...prefernces, txt: Prefernces?.txt ? false : true };
                                                                } )
                                                            }
                                                        >
                                                            { Loc.currentLang.menu.phone }
                                                        </Checkbox>
                                                    </Stack>
                                                </Box>
                                            </HStack>
                                        ) }
                                        { isHomepage && (
                                            <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        ) }
                                        <Text
                                            color=' rgba(0,0,0,0.85)'
                                            fontSize={ '24px' }
                                            fontWeight={ 400 }
                                            marginTop={ !isHomepage ? '30px' : '' }
                                            marginLeft={ '24px' }
                                        >
                                            { Loc.currentLang.menu.followSettings }
                                        </Text>
                                        <HStack
                                            marginTop={ '20px' }
                                            width={ isMobile ? '91%' : '70%' }
                                            marginLeft={ isMobile ? '26px' : '45px' }
                                            space={ '70px' }
                                            zIndex={ -2 }
                                        >
                                            <Text
                                                width={ isMobile ? '41%' : '33%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                numberOfLines={ 2 }
                                                ellipsizeMode='tail'
                                            >
                                                { Loc.currentLang.menu.isPrivate }
                                            </Text>
                                            <Radio.Group
                                                name='exampleGroup'
                                                defaultValue={ Prefernces?.isPrivate ? 'true' : 'false' }
                                                accessibilityLabel='pick a size'
                                                onChange={ () =>
                                                    setPrefernces( ( prefernces ) =>
                                                    {
                                                        return {
                                                            ...prefernces,
                                                            isPrivate: Prefernces?.isPrivate ? false : true,
                                                        };
                                                    } )
                                                }
                                            >
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    alignItems={ {
                                                        base: 'flex-wrap',
                                                    } }
                                                    space={ isMobile ? '30px' : '65px' }
                                                    w='75%'
                                                    maxW='300px'
                                                                                                    >
                                                    <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.yes }
                                                    </Radio>
                                                    <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.no }
                                                    </Radio>
                                                </Stack>
                                            </Radio.Group>
                                        </HStack>
                                        <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        <HStack
                                            marginTop={ '20px' }
                                            width={ isMobile ? '91%' : '70%' }
                                            marginLeft={ isMobile ? '26px' : '45px' }
                                            space={ '70px' }
                                            zIndex={ -2 }
                                        >
                                            <Text
                                                width={ isMobile ? '41%' : '33%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                numberOfLines={ 2 }
                                                ellipsizeMode='tail'
                                            >
                                                { Loc.currentLang.menu.autoApprove }
                                            </Text>
                                            <Radio.Group
                                                name='exampleGroup'
                                                defaultValue={ Prefernces?.autoFollow ? 'true' : 'false' }
                                                accessibilityLabel='pick a size'
                                                onChange={ () =>
                                                    setPrefernces( ( prefernces ) =>
                                                    {
                                                        return {
                                                            ...prefernces,
                                                            autoFollow: Prefernces?.autoFollow ? false : true,
                                                        };
                                                    } )
                                                }
                                            >
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    alignItems={ {
                                                        base: 'flex-wrap',
                                                        md: 'center',
                                                    } }
                                                    space={ isMobile ? '30px' : '65px' }
                                                    w='75%'
                                                    maxW='300px'
                                                >
                                                    <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.yes }
                                                    </Radio>
                                                    <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.no }
                                                    </Radio>
                                                </Stack>
                                            </Radio.Group>
                                        </HStack>
                                        <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        <HStack
                                            marginTop={ '20px' }
                                            width={ isMobile ? '91%' : '70%' }
                                            marginLeft={ isMobile ? '26px' : '45px' }
                                            space={ '70px' }
                                            zIndex={ -2 }
                                        >
                                            <Text
                                                width={ isMobile ? '41%' : '33%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                numberOfLines={ 2 }
                                                ellipsizeMode='tail'
                                            >
                                                { Loc.currentLang.menu.allowFollowers }
                                            </Text>
                                            <Radio.Group
                                                name='exampleGroup'
                                                defaultValue={ Prefernces?.allowFollowersPosts ? 'true' : 'false' }
                                                accessibilityLabel='pick a size'
                                                onChange={ () =>
                                                    setPrefernces( ( prefernces ) =>
                                                    {
                                                        return {
                                                            ...prefernces,
                                                            allowFollowersPosts: Prefernces?.allowFollowersPosts ? false : true,
                                                        };
                                                    } )
                                                }
                                            >
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    alignItems={ {
                                                        base: 'flex-wrap',
                                                    } }
                                                    space={ isMobile ? '30px' : '65px' }
                                                    w='75%'
                                                    maxW='300px'
                                                >
                                                    <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.yes }
                                                    </Radio>
                                                    <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.no }
                                                    </Radio>
                                                </Stack>
                                            </Radio.Group>
                                        </HStack>
                                        <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        
                                        <HStack
                                            marginTop={  '20px' }
                                            width={ isMobile ? '91%' : '70%' }
                                            marginLeft={ isMobile ? '26px' : '45px' }
                                            space={ '70px' }
                                            zIndex={ -2 }
                                        >
                                            <Text
                                                width={ isMobile ? '41%' : '33%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                numberOfLines={ 2 }
                                                ellipsizeMode='tail'
                                            >
                                                { Entity?.type == "School" ? Loc.currentLang.menu.allowStaffToPost : Entity?.type == "Neighborhood" ? Loc.currentLang.menu.allowAdministrationToPost : Entity?.subType == "Class" ? Loc.currentLang.menu.allowStudentToPost : Loc.currentLang.menu.allowMembersToPosts }
                                            </Text>
                                            <Radio.Group
                                                name='exampleGroup'
                                                defaultValue={ Prefernces?.allowMembersToPosts ? 'true' : 'false' }
                                                accessibilityLabel='pick a size'
                                                onChange={ () =>
                                                    setPrefernces( ( prefernces ) =>
                                                    {
                                                        return {
                                                            ...prefernces,
                                                            allowMembersToPosts: Prefernces?.allowMembersToPosts ? false : true,
                                                        };
                                                    } )
                                                }
                                            >
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    alignItems={ {
                                                        base: 'flex-wrap',
                                                    } }
                                                    space={ isMobile ? '30px' : '65px' }
                                                    w='75%'
                                                    maxW='300px'
                                                >
                                                    <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.yes }
                                                    </Radio>
                                                    <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.no }
                                                    </Radio>
                                                </Stack>
                                            </Radio.Group>
                                        </HStack>
                                        <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        {isAllowPlayerPost  && (<HStack
                                            marginTop={ '20px' }
                                            width={ isMobile ? '91%' : '70%' }
                                            marginLeft={ isMobile ? '26px' : '45px' }
                                            space={ '70px' }
                                            zIndex={ -2 }
                                        >
                                            <Text
                                                width={ isMobile ? '41%' : '33%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                numberOfLines={ 2 }
                                                ellipsizeMode='tail'
                                            >
                                                {( Entity?.subType == "Class" || Entity?.type == "LeagueTeam" ) ? Loc.currentLang.menu.allowParentToPost : Loc.currentLang.menu.allowPlayersToPosts }
                                            </Text>
                                            <Radio.Group
                                                name='exampleGroup'
                                                defaultValue={ Prefernces?.allowPlayersToPosts ? 'true' : 'false' }
                                                accessibilityLabel='pick a size'
                                                onChange={ () =>
                                                    setPrefernces( ( prefernces ) =>
                                                    {
                                                        return {
                                                            ...prefernces,
                                                            allowPlayersToPosts: Prefernces?.allowPlayersToPosts ? false : true,
                                                        };
                                                    } )
                                                }
                                            >
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    alignItems={ {
                                                        base: 'flex-wrap',
                                                    } }
                                                    space={ isMobile ? '30px' : '65px' }
                                                    w='75%'
                                                    maxW='300px'
                                                >
                                                    <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.yes }
                                                    </Radio>
                                                    <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.no }
                                                    </Radio>
                                                </Stack>
                                            </Radio.Group>
                                        </HStack>)}
                                        {isAllowPlayerPost && (<Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />)}
                                       
                                        <HStack
                                            marginTop={ '20px' }
                                            width={ isMobile ? '91%' : '70%' }
                                            marginLeft={ isMobile ? '26px' : '45px' }
                                            space={ '70px' }
                                            zIndex={ -2 }
                                        >
                                            <Text
                                                width={ isMobile ? '41%' : '33%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                numberOfLines={ 2 }
                                                ellipsizeMode='tail'
                                            >
                                                { Loc.currentLang.menu.allowReplyToPost }
                                            </Text>
                                            <Radio.Group
                                                name='exampleGroup'
                                                defaultValue={ Prefernces?.allowReplyPosts ? 'true' : 'false' }
                                                accessibilityLabel='pick a size'
                                                onChange={ () =>
                                                    setPrefernces( ( prefernces ) =>
                                                    {
                                                        return {
                                                            ...prefernces,
                                                            allowReplyPosts: Prefernces?.allowReplyPosts ? false : true,
                                                        };
                                                    } )
                                                }
                                            >
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    alignItems={ {
                                                        base: 'flex-wrap',
                                                    } }
                                                    space={ isMobile ? '30px' : '65px' }
                                                    w='75%'
                                                    maxW='300px'
                                                >
                                                    <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.yes }
                                                    </Radio>
                                                    <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.no }
                                                    </Radio>
                                                </Stack>
                                            </Radio.Group>
                                        </HStack>
                                        <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        <HStack
                                            marginTop={ '20px' }
                                            width={ isMobile ? '91%' : '70%' }
                                            marginLeft={ isMobile ? '26px' : '45px' }
                                            space={ '70px' }
                                            zIndex={ -2 }
                                        >
                                            <Text
                                                width={ isMobile ? '41%' : '33%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                numberOfLines={ 2 }
                                                ellipsizeMode='tail'
                                            >
                                                {Loc.currentLang.menu.allowalltopost }
                                            </Text>
                                            <Radio.Group
                                                name='exampleGroup'
                                                defaultValue={ Prefernces?.allowAllToPost ? 'true' : 'false' }
                                                accessibilityLabel='pick a size'
                                                onChange={ () =>
                                                    setPrefernces( ( prefernces ) =>
                                                    {
                                                        return {
                                                            ...prefernces,
                                                            allowAllToPost: Prefernces?.allowAllToPost ? false : true,
                                                        };
                                                    } )
                                                }
                                            >
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    alignItems={ {
                                                        base: 'flex-wrap',
                                                    } }
                                                    space={ isMobile ? '30px' : '65px' }
                                                    w='75%'
                                                    maxW='300px'
                                                >
                                                    <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.yes }
                                                    </Radio>
                                                    <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                                        { Loc.currentLang.menu.no }
                                                    </Radio>
                                                </Stack>
                                            </Radio.Group>
                                        </HStack>
                                        <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        <HStack>
                                            <Text
                                                width={ isMobile ? '41%' : '30%' }
                                                textAlign={ 'left' }
                                                fontSize={ '18px' }
                                                marginLeft={ isMobile ? '22px' :'45px' }
                                                numberOfLines={ 1 }
                                                ellipsizeMode='tail'
                                            >
                                                { Loc.currentLang.menu.defaultVisibility }
                                            </Text>
                                            <HStack width={ '33%' } marginLeft={isMobile ? '35px' : '5px' } >
                                                <FormControl fullWidth>
                                                    <InputLabel id='demo-simple-select-label'>
                                                        { Loc.currentLang.menu.visibility }
                                                    </InputLabel>
                                                    <Select
                                                        labelId='demo-simple-select-label'
                                                        id='demo-simple-select'
                                                        value={ visibility }
                                                        label={ Loc.currentLang.menu.visibility }
                                                        onChange={ ( e ) => setvisibility( e.target.value ) }
                                                    >
                                                        <MenuItem value='Private'>{ Loc.currentLang.menu.private }</MenuItem>
                                                        <MenuItem value='Public'>{ Loc.currentLang.menu.public }</MenuItem>
                                                      <MenuItem value='Follow'>
                                                            { Loc.currentLang.menu.followers }
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </HStack>
                                        </HStack>

                                        <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                                        <HStack justifyContent={ 'center' } pr={ 3 } mb={ 4 } space={ 3 } zIndex={ -3 }>
                                            <>
                                                <AVButtonRoundInvert
                                                    btnText={ Loc.currentLang.menu.cancelCaps }
                                                    callback={ handlegoBack } px={ undefined } height={ undefined } isLoadingText={ undefined } isDisabled={ undefined } isLoading={ undefined } loading={ undefined } />
                                                <AVButtonRoundedFull
                                                    btnText={ Loc.currentLang.menu.updateCaps }
                                                    callback={ handleupdatepreference }
                                                    loading={ isLoading } px={ undefined } height={ undefined } isLoadingText={ undefined } isDisabled={ undefined } isLoading={ undefined } />
                                            </>
                                        </HStack>
                                    </Box>
                                </>
                            </VStack>
                            { ( Entity?.type !== "Event" ) && ( <VStack marginTop={ '13px' } marginLeft={ isMobile ? '36px' : '30px' }>
                                <HStack>
                                    <Text
                                        fontWeight={ 'medium' }
                                        fontSize={ '22px' }
                                        numberOfLines={ 1 }
                                        ellipsizeMode='tail'
                                    >
                                        { Loc.currentLang.menu.deleteOrSuspend }
                                    </Text>
                                </HStack>
                                <HStack marginTop={ '10px' }>
                                    <Text fontWeight={ 500 }>
                                        <AVlinktext text={ Loc.currentLang.menu.deletedAccountDescription } />
                                    </Text>
                                </HStack>
                                <VStack justifyContent={ 'flex-start' } alignItems={ 'center' } space={ isMobile ? "40px" : '20px' } marginTop={ '20px' } marginLeft={ isMobile ? "-31px" : "" } >
                                    <Button
                                        width={isMobile ?'':'30%' }
                                        rounded='full'
                                        backgroundColor={ '#EEEEEE' }
                                        paddingX={ '20px' }
                                        onPress={ () =>
                                        {
                                            setisdeletepopup( true );
                                        } }
                                    >
                                        <Text fontSize={ '14px' } color={ 'rgba(0,0,0,0.5)' } fontWeight={ 'bold' }>
                                            { Loc.currentLang.menu.deleteAccount }
                                        </Text>
                                    </Button>

                                    <Button
                                        width={ isMobile ? '' : '30%' }
                                        rounded='full'
                                        backgroundColor={ '#EEEEEE' }
                                        paddingX={ '20px' }
                                        onPress={ () =>
                                        {
                                            setisSuspendedpopup( true );
                                        } }
                                    >
                                        <Text fontSize={ '14px' } color={ 'rgba(0,0,0,0.5)' } fontWeight={ 'bold' }>
                                            { isSuspended ? Loc.currentLang.menu.unsuspendThisAccount : Loc.currentLang.menu.suspendAccount }
                                        </Text>
                                    </Button>

                                </VStack>
                                <Divider marginY={ '10px' } marginLeft={ '8px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                             
                                <VStack justifyContent={ 'flex-start' } alignItems={ 'center' } top={'-6px' }>
                                    <HStack marginTop={ '10px' }>
                                        <Text fontWeight={ 500 }>
                                            <AVlinktext text={ Loc.currentLang.menu.clearCacheDescription } />
                                        </Text>
                                    </HStack>
                                    <Button
                                        top={'10px' }
                                        width={ isMobile ? '' : '30%' }
                                        right={isMobile ?'20px' :'' }
                                        rounded='full'
                                        backgroundColor={ '#EEEEEE' }
                                        paddingX={ '20px' }
                                        onPress={ () =>
                                        {
                                          handleClearCache();
                                        } }
                                    >
                                        <Text fontSize={ '14px' } color={ 'rgba(0,0,0,0.5)' } fontWeight={ 'bold' }>
                                            {Loc.currentLang.menu.clearCache }
                                        </Text>
                                        </Button>
                                </VStack>
                            </VStack> ) }
                        </Box>
                    ) }
                    { !isHomepage && isRole && (
                        <Box
                            bgColor='#fff'
                            shadow='1'
                            borderRadius={ 5 }
                            marginBottom={ '50px' }
                            paddingBottom={ '15px' }
                            fontSize={ '18px' }
                            minH={ 0 }
                            maxHeight={ '500px' }
                        >
                            <VStack>
                                <VStack>
                                    <Box>
                                        <HStack>
                                            <Text
                                                fontSize={ '24px' }
                                                fontWeight={ 400 }
                                                marginX={ '26px' }
                                                marginBottom={ '11px' }
                                                marginTop={ '26px' }
                                            >
                                                { Loc.currentLang.menu.roles }
                                            </Text>
                                            <Pressable
  
                                                padding={ 2 }
                                                rounded={ 'full' }
                                                onPress={ () => navigate( -1 ) }
                                                _hover={ { bgColor: 'AVColor.grey'} }
                                                height={ '28px' }
                                                alignSelf={ 'center' }
                                                position={ 'absolute' }
                                                right={ '30px' }
                                            >
                                                <CloseIcon size={ '12px' } />
                                            </Pressable>
                                        </HStack>
                                        <Divider width={ '94%' } marginLeft={ '25px' } marginBottom={ '20px' } />
                                        <VStack minH={ 0 } maxH={ '235px' } overflowY={ 'scroll' } overflowX={ 'hidden' }>
                                            { rolesList?.map( ( item ) => (
                                                <VStack>
                                                    <HStack
                                                        key={ item?.id }
                                                        justifyContent={ 'space-between' }
                                                        marginX={ '25px' }
                                                    >
                                                        <Text fontSize={ '18px' } text-overflow={ 'ellipsis' }>
                                                            { item.name }
                                                        </Text>
                                                        <HStack marginLeft={ '30px' } space={ '26px' }>
                                                            <Pressable
                                                                onPress={ () =>
                                                                {
                                                                    handleEdit( item.id );
                                                                    setIsAddRole( true );
                                                                    setEditrolePopup( true );
                                                                } }
                                                            >
                                                                <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.edit }</Text>
                                                            </Pressable>
                                                            <Pressable onPress={ () => handleRoleDelete( item ) }>
                                                                <Text color={ 'gray.500' }>{ Loc.currentLang.menu.delete }</Text>
                                                            </Pressable>
                                                        </HStack>
                                                    </HStack>
                                                    <Divider width={ '94%' } marginY={ '10px' } marginX={ '25px' } />
                                                </VStack>
                                            ) ) }
                                        </VStack>
                                    </Box>
                                    { !isAddRole && (
                                        <HStack marginLeft={ '25px' } marginTop={ '15px' }>
                                            <Pressable
                                                marginLeft={ '25px' }
                                                marginTop={ '15px' }
                                                display={ 'contents' }
                                                onPress={ () =>
                                                {
                                                    setIsAddRole( true );
                                                    setnewcanedit( true );
                                                    setnewispuplic( true );
                                                    setEditrolePopup(true)
                                                } }
                                            >
                                                <AddIcon color={ '#00A7BC' } size={ '18px' } />
                                                <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.addRole }</Text>
                                            </Pressable>
                                        </HStack>
                                    ) }
                                </VStack>
                            </VStack>
                        </Box>
                    ) }
                </VStack>
            </VStack>
            <Hidden only={ [ 'base', 'sm', 'md' ] }>
                <VStack width={ '21%' } zIndex={ -1 } alignItems={ 'flex-end' } ref={ rightRef }>
                    {/*<VStack*/ }
                    {/*    position={ 'fixed' }*/ }
                    {/*    space={ 3 }*/ }
                    {/*    alignItems={ 'flex-end' }*/ }
                    {/*    maxWidth={ '21%' }*/ }
                    {/*    width={ fixedWidth }*/ }
                    {/*>*/ }
                    {/*    <MoreHorizontalIcon size='30px' color={ 'AVColor.black' } />*/ }
                    {/*</VStack>*/ }
                </VStack>
            </Hidden>
            { popup && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        className={ `create_post_popup ${ mobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: mobile ? dimensions.width - 15 : '632px',
                            height: mobile ? '650px' : '354px',
                            marginTop: '12px',
                        } }
                    >
                        <VStack>
                            <Text position={ 'absolute' } left={ '42px' } top={ '31px' } fontSize={ [ 25, 30 ] }>
                                { Loc.currentLang.menu.verifyText }
                            </Text>
                            <Text
                                position={ 'absolute' }
                                left={ '42px' }
                                top={ '95px' }
                                fontSize={ [ 14, 18 ] }
                                color={ showMessage ? '#DF2827' : '' }
                            >
                                { message }
                            </Text>
                        </VStack>
                        <HStack justifyContent={ 'center' } top={ '47%' }>
                            <Input
                                height={ '52px' }
                                width={ '235px' }
                                fontSize={ '25px' }
                                defaultValue={ verifycode }
                                onChangeText={ ( e ) => setverifycode( e ) }
                            />
                        </HStack>
                        <HStack justifyContent={ 'center' } top={ '65%' } space={ '24px' }>
                            <Button
                                width={ '31%' }
                                height={ '40px' }
                                borderRadius={ '19px' }
                                backgroundColor={ '#e5e5e5' }
                                onPress={ () => handleAddEmail( mailtype ) }
                            >
                                <Text color={ 'rgba(0,0,0,0.5)' } fontSize={ [ 12, 16 ] } fontWeight={ 500 }>
                                    { Loc.currentLang.menu.sendNewCode }
                                </Text>
                            </Button>
                            <Button
                                width={ '20%' }
                                height={ '40px' }
                                borderRadius={ '19px' }
                                backgroundColor={ '#e5e5e5' }
                                onPress={ () => setPopup( false ) }
                            >
                                <Text color={ 'rgba(0,0,0,0.5)' } fontWeight={ 500 } fontSize={ [ 14, 16 ] }>
                                    { Loc.currentLang.menu.cancelCaps }
                                </Text>
                            </Button>
                            <Button
                                width={ '22%' }
                                isLoading={ isLoading }
                                isDisabled={ verifycode?.trim()?.length !== 4 }
                                height={ '40px' }
                                borderRadius={ '19px' }
                                backgroundColor={ 'AVColor.primary' }
                            >
                                <Text
                                    color={ 'white' }
                                    fontWeight={ 500 }
                                    onPress={ () => handleverfication() }
                                    fontSize={ [ 14, 16 ] }
                                >
                                    { Loc.currentLang.menu.verify }
                                </Text>
                            </Button>
                        </HStack>
                    </div>
                </Box>
            ) }
            {isdeletepopup && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        className={ `create_post_popup ${ mobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: mobile ? dimensions.width - 15 : '600px',
                            height: mobile ? '200px' : '200px',
                            marginTop: '12px',
                        } }
                    >
                        <VStack>
                            <Text position={ 'absolute' } left={ '180px' } top={ '31px' } fontSize={ [ 25, 30 ] }>
                                { Loc.currentLang.menu.deleteAccoun }
                            </Text>
                        </VStack>
                        <HStack justifyContent={ 'center' } top={ '18%' }>
                            <Text position={ 'absolute' } left={ '60px' } top={ '31px' } fontSize={ [ 18, 20 ] }>
                  { Entity?.type == "School" ? "Please confirm that you would like to delete this School" : Entity?.subType === "Club" ? "Please confirm that you would like to delete this Club" : Entity?.type === "Team" ? "Please confirm that you would like to delete this Team" : Entity?.type === "Group" ? "Please confirm that you would like to delete this Group" : Entity?.type === "Community" ? "Please confirm that you would like to delete this Community" : Entity?.type === "GradClass" ? "Please confirm that you would like to delete this GradClass" : Entity?.type == "Neighborhood" ? "Please confirm that you would like to delete this Neighborhood" : Entity?.type === "Organization" ? "Please confirm that you would like to delete this Organization" : Entity?.subType == "Class" ? "Please confirm that you would like to delete this Class" : Entity?.type === "Club" ? "Please confirm that you would like to delete this Elite Club" : Loc.currentLang.menu.deleteConfirmText }
                            </Text>
                        </HStack>
                        <HStack justifyContent={ 'center' } top={ '65%' } space={ '24px' }>
                            <Button
                                width={ '20%' }
                                height={ '40px' }
                                borderRadius={ '19px' }
                                backgroundColor={ 'AVColor.primary' }
                                onPress={ () => handledeleteaccount() }
                                isLoading={ isdeletepopupLoader }
                            >
                                <Text color={ 'white' } fontWeight={ 500 } fontSize={ [ 14, 16 ] }>
                                    { Loc.currentLang.menu.yes }
                                </Text>
                            </Button>
                            <Button
                                width={ '22%' }
                                isLoading={ isLoading }
                                isLoadingText={ 'Verfied..' }
                                onPress={ () => setisdeletepopup( false ) }
                                height={ '40px' }
                                borderRadius={ '19px' }
                                backgroundColor={'#e5e5e5'}
                            >
                                <Text
                                    color={ 'rgba(0,0,0,0.5) ' }
                                    fontWeight={ 500 }
                                    onPress={ () => setisdeletepopup( false ) }
                                    fontSize={ [ 14, 16 ] }
                                >
                                    No
                                </Text>
                            </Button>
                        </HStack>
                    </div>
                </Box>
            ) }
            { isSuspendedpopup && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        className={ `create_post_popup ${ mobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: mobile ? dimensions.width - 15 : '600px',
                            height: mobile ? '200px' : '200px',
                            marginTop: '12px',
                        } }
                    >
                        <VStack>
                            <Text position={ 'absolute' } left={ '30px' } top={ '31px' } fontSize={ [ 25, 30 ] }  fontWeight={'Bold'}>
                                { isSuspended ? Loc.currentLang.menu.unsuspendRequest : Loc.currentLang.menu.suspendRequest }
                            </Text>
                        </VStack>
                        <HStack justifyContent={ 'center' } top={ '22%' }>
                            <Text position={ 'absolute' } left={ '30px' } top={ '35px' } fontSize={ [ 18, 20 ] }>
                                { isSuspended ? Loc.currentLang.menu.UnsuspendAccountText : Loc.currentLang.menu.suspendAccountText }{ ' ' }
                            </Text>
                        </HStack>
                        <HStack justifyContent={ 'center' } top={ '65%' } space={ '24px' }>
                            <Button
                                width={ '22%' }
                                isLoading={ isLoading }
                                isLoadingText={ 'Verfied..' }
                                onPress={ () => setisSuspendedpopup( false ) }
                                height={ '40px' }
                                borderRadius={ '19px' }
                                backgroundColor={ '#e5e5e5' }
                            >
                                <Text
                                    color={ 'rgba(0,0,0,0.5)' }
                                    fontWeight={ 500 }
                                    onPress={ () => setisSuspendedpopup( false ) }
                                    fontSize={ [ 14, 16 ] }
                                >
                                    { Loc.currentLang.menu.cancel }
                                </Text>
                            </Button>

                            <Button
                                width={ '60%' }
                                height={ '40px' }
                                borderRadius={ '19px' }
                                backgroundColor={ 'AVColor.primary' }
                                onPress={ () => handleSuspendedaccount() }
                                isLoading={ isSuspendedLoader }
                            >
                                <Text color={ 'white' } fontWeight={ 500 } fontSize={ [ 14, 16 ] }>
                                    { isSuspended ? Loc.currentLang.menu.UnsuspendButtonText : Loc.currentLang.menu.suspendButtonText }
                                </Text>
                            </Button>
                        </HStack>
                    </div>
                </Box>
            ) }

            { editrolepopup && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        className={ `create_post_popup ${ mobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: mobile ? dimensions.width - 15 : '600px',
                            height: mobile ? '200px' : '300px',
                            marginTop: '12px',
                        } }
                    >
                        { isAddRole && (
                            <VStack margin={ '24px' } width={'auto'}>
                                <Text
                                    fontSize={ '24px' }
                                    fontWeight={ 400 }
                                >
                                    { editMode
                                        ? Loc.currentLang.menu.updateRole
                                        : Loc.currentLang.menu.addRole }
                                </Text>
                                <VStack display={ 'flex' } marginTop={ '15px' } space={ 3 } flexWrap={ isMobile ? 'wrap' : 'nowrap' } >
                                        {/*<Input placeholder={'Role Title'} mx="4" marginY={'5px'} paddingRight={'40px'} borderRadius={'5px'} fontSize={'18px'} value={isnewrole} defaultValue={isnewrole} onChangeText={(e) => setisnewrole(e)} />*/ }
                                        <Box width={ '60%' }>
                                            <AVInputtext
                                                type={ 'text' }
                                                label={ Loc.currentLang.menu.roleTitle }
                                                onChange={ ( e ) => setisnewrole( e?.target.value ) }
                                                value={ isnewrole }
                                                height={ '15px' } defaultValue={ undefined } width={ undefined } minRows={ undefined } multiline={ undefined } maxLength={ undefined } size={ undefined } placeholder={ undefined } />
                                        </Box>
                                        <HStack space={ '60px' } marginTop={ '8px' }>
                                            <Box alignItems='center'>
                                                <Stack
                                                    direction={ {
                                                        base: 'row',
                                                        md: 'row',
                                                    } }
                                                    space={ '60px' }
                                                    alignItems='flex-start'
                                                >
                                                    <Checkbox
                                                        size='sm'
                                                        value={ newispuplic }
                                                        defaultIsChecked={ newispuplic ? true : false }
                                                        onChange={ () => setnewispuplic( newispuplic ? false : true ) }
                                                    >
                                                        { Loc.currentLang.menu.showToPublic }
                                                    </Checkbox>
                                                    <Checkbox
                                                        size='sm'
                                                        value={ newcanedit }
                                                        defaultIsChecked={ newcanedit ? true : false }
                                                        onChange={ () => setnewcanedit( newcanedit ? false : true ) }
                                                    >
                                                        { Loc.currentLang.menu.allowEdit }
                                                    </Checkbox>
                                                </Stack>
                                            </Box>
                                        </HStack>
                                    </VStack>
                                <HStack
                                    marginTop={ isMobile ? "4%" : "10%" }
                                    space={ 5 }
                                    justifyContent={ isMobile ? 'flex-start' : 'center' }
                                    alignSelf="center"
                                    flexWrap="wrap"
                                    maxWidth="100%"
                                >
                                    <Button
                                        isLoading={ roleisLoading }
                                        isDisabled={ isnewrole?.trim()?.length === 0 }
                                        isLoadingText={
                                            editMode
                                                ? Loc.currentLang.menu.updating
                                                : Loc.currentLang.menu.adding
                                        }
                                        rounded="full"
                                        width="140px"
                                        height="33px"
                                        paddingTop="5px"
                                        paddingBottom="5px"
                                        backgroundColor="AVColor.primary"
                                        onPress={ () =>
                                        {
                                            editMode ? handleEditroleSave() : handleAddRole();
                                        } }
                                    >
                                        <Text fontSize="16px" color="white">
                                            { editMode ? Loc.currentLang.menu.updateRole : Loc.currentLang.menu.addRole }
                                        </Text>
                                    </Button>
                                    <Button
                                        width="140px"
                                        rounded="full"
                                        height="33px"
                                        paddingTop="5px"
                                        paddingBottom="5px"
                                        backgroundColor="gray.300"
                                        onPress={ () =>
                                        {
                                            setIsAddRole( false );
                                            setEditMode( false );
                                            setisnewrole( '' );
                                            setEditrolePopup( false );
                                        } }
                                    >
                                        <Text color="black">{ Loc.currentLang.menu.cancel }</Text>
                                    </Button>
                                </HStack>

                                
                            </VStack>
                        ) }

                    </div>
                </Box>
            ) }
        </AVBody>
    );
}


export default React.memo( Accountsettings );
