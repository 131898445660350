import React, { useEffect, useState } from 'react';
import { VStack, HStack, Input, Text, Box, Pressable, Divider, Image } from 'native-base';
import { Dimensions } from 'react-native';
import { SearchIcon } from '../../assets/icons/icon-assets';
import { Person } from '../../AVCore/AVCore';
import Moment from 'moment';
import AVBody from 'components/common/AVBody';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Loc } from '../../App/Loc';
import Filter from '../../components/common/AVFilter';
import { OrgCardLoader } from '../../components/loaders';
import RenderEvents from '../Events/RenderEvents';
import Dropdown from '../../assets/images/dropdown.svg';
import _ from 'lodash';

const MyTeams = () =>
{
    const user = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const followersdatalist = user?.follows;
    const [ showCreateNewPage, setShowCreateNewPage ] = useState<any>( false );
    const [ createType, setCreateType ] = useState<any>( "" );

    useEffect( () =>
    {
    }, [ user?.orgs ] );
    const [ showEdit, setIsShowEdit ] = useState( false );
    return (
        <AVBody isGeneral={ undefined } setIsShowEdit={ setIsShowEdit } setIsGeneral={ undefined } isRole={ undefined } setIsRole={ undefined } Entity={ undefined } setIsEntity={ undefined } type={ undefined } isHomepage={ undefined } setShowCreateNewPage={ setShowCreateNewPage } showCreateNewPage={ showCreateNewPage } setCreateType={ setCreateType } createType={ createType } passEntity={ undefined } setPassEntity={ undefined }>
            <TeamsContent user={ user } followersdatalist={ followersdatalist } />
        </AVBody>
    );
};

const TeamsContent = ( { user, followersdatalist } ) =>
{
    const [ isLoading, setIsLoading ] = useState( true );
    const [ teamType, SetTeamType ] = useState( Loc.currentLang.menu.all );
    const [ data, setTeamsData ] = React.useState<any>( [] );
    const [ teamsList, setTeamsList ] = React.useState<any>();
    const dimensions = Dimensions.get( 'window' );
    const isMobile = dimensions?.width <= 500 || false;

    const [ isDropDown, setIsDropDown ] = React.useState( false );

    const setAll = () =>
    {
        SetTeamType( Loc.currentLang.menu.all );
        const filteredOrgs = user?.orgs?.filter( x => x?.type !== 'Person' );
        // Reverse the order of filtered organizations
        const reversedFilteredOrgs = filteredOrgs?.reverse();

        // Combine reversed filtered organizations with followersdatalist
        const finaldata = [ ...reversedFilteredOrgs, ...( followersdatalist || [] ) ];
        setTeamsData( finaldata );
        setTeamsList( finaldata );
        setIsLoading( false );
    };

    const setMyTeams = () =>
    {
        SetTeamType( Loc.currentLang.menu.filterTeams );
        const filteredTeams = user?.orgs?.filter( ( x ) => ( x.type === 'LeagueTeam' || x.type === 'Team' ) && x?.subType === undefined ) || [];
        const reversedTeams = filteredTeams?.reverse();
        setTeamsData( reversedTeams );
        setTeamsList( reversedTeams );
        setIsLoading( false );
    };

    const setClub = () =>
    {
        SetTeamType( Loc.currentLang.menu.clubs );
        const filteredClubs = user?.orgs?.filter( ( x ) => x.subType === 'Club' ) || [];
        const reversedFilteredClubs = filteredClubs?.reverse();
        setTeamsData( reversedFilteredClubs );
        setTeamsList( reversedFilteredClubs );
        setIsLoading( false );
    };

    const setElite = () =>
    {
        SetTeamType( Loc.currentLang.menu.eliteClubs );
        const filteredEliteClubs = user?.orgs?.filter( ( x ) => x?.type === "Club" ) || [];
        const reversedEliteClubs = filteredEliteClubs?.reverse();
        setTeamsData( reversedEliteClubs );
        setTeamsList( reversedEliteClubs );
        setIsLoading( false );
    };

    const setSchools = () =>
    {
        SetTeamType( Loc.currentLang.menu.schools );
        const filteredSchools = user?.orgs?.filter( ( x ) => x.type === 'School' ) || [];
        const reversedSchools = filteredSchools?.reverse();
        setTeamsData( reversedSchools );
        setTeamsList( reversedSchools );
        setIsLoading( false );
    };

    const setGroup = () =>
    {
        SetTeamType( Loc.currentLang.menu.groups );
        const filteredGroups = user?.orgs?.filter( ( x ) => x.type === 'Group' && x?.subType === undefined ) || [];
        const reversedGroups = filteredGroups?.reverse();
        setTeamsData( reversedGroups );
        setTeamsList( reversedGroups );
        setIsDropDown( false );
        setIsLoading( false );
    };

    const setClass = () =>
    {
        SetTeamType( Loc.currentLang.menu.classes );
        const filteredClasses = user?.orgs?.filter( ( x ) => x?.type === 'LeagueTeam' && x?.subType === "Class" ) || [];
        const reversedClasses = filteredClasses?.reverse();
        setTeamsData( reversedClasses );
        setTeamsList( reversedClasses );
        setIsDropDown( false );
        setIsLoading( false );
    };

    const setCommunity = () =>
    {
        SetTeamType( Loc.currentLang.menu.community );
        const filteredCommunity = user?.orgs?.filter( ( x ) => x.type === 'Community' ) || [];
        const reversedCommunity = filteredCommunity?.reverse();
        setTeamsData( reversedCommunity );
        setTeamsList( reversedCommunity );
        setIsDropDown( false );
        setIsLoading( false );
    };

    const setGradClass = () =>
    {
        SetTeamType( Loc.currentLang.menu.gradClass );
        const filteredGradClasses = user?.orgs?.filter( ( x ) => x?.type === 'Group' && x?.subType === "GradClass" ) || [];
        const reversedGradClasses = filteredGradClasses?.reverse();
        setTeamsData( reversedGradClasses );
        setTeamsList( reversedGradClasses );
        setIsDropDown( false );
    };

    const setNeighbors = () =>
    {
        SetTeamType( Loc.currentLang.menu.neighborHood );
        const filteredNeighborhoods = user?.orgs?.filter( ( x ) => x.type === 'Neighborhood' ) || [];
        const reversedNeighborhoods = filteredNeighborhoods?.reverse();
        setTeamsData( reversedNeighborhoods );
        setTeamsList( reversedNeighborhoods );
        setIsDropDown( false );
        setIsLoading( false );
    };

    const setOrgs = () =>
    {
        SetTeamType( Loc.currentLang.menu.orgs );
        const filteredOrgs = user?.orgs?.filter( ( x ) => x.type === 'Organization' ) || [];
        const reversedOrgs = filteredOrgs?.reverse();
        setTeamsData( reversedOrgs );
        setTeamsList( reversedOrgs );
        setIsDropDown( false );
        setIsLoading( false );
    };

    const setTerms = () =>
    {
        SetTeamType( Loc.currentLang.menu.terms );
        const filteredOrgs = user?.orgs?.filter( ( x ) => x?.subType === "Term" ) || [];
        const reversedOrgs = filteredOrgs?.reverse();
        setTeamsData( reversedOrgs );
        setTeamsList( reversedOrgs );
        setIsDropDown( false );
        setIsLoading( false );
    };

    const setFollowers = () =>
    {
        SetTeamType( Loc.currentLang.menu.followings );
        setTeamsData( followersdatalist );
        setTeamsList( followersdatalist );
        setIsDropDown( false );
        setIsLoading( false );
    };

    const GetTeams = () =>
    {
        const filteredOrgs = user?.orgs?.filter( x => x?.type !== 'Person' );
        // Reverse the order of filtered organizations
        const reversedFilteredOrgs = filteredOrgs?.reverse();
        const combinedData = [ ...reversedFilteredOrgs, ...( followersdatalist || [] ) ];
        setTeamsData( combinedData );
        setTeamsList( combinedData );
        //followersList();
        setIsLoading( false );
    };

    const handleSearch = ( item ) =>
    {
        if ( item )
        {
            setTeamsData( teamsList?.filter(
                ( x : any ) =>
                    ( x?.data !== undefined && x?.data?.toLocaleLowerCase().includes( item.toLocaleLowerCase() ) ) ||
                    ( x?.title !== undefined &&
                        x?.title?.toLocaleLowerCase().includes( item.toLocaleLowerCase() ) ) ) );
        }
        else
        {
            setTeamsData( teamsList );
        }
    };

    const MenuFilter = [
        ...( ( user?.orgs?.length > 0 || followersdatalist?.length > 0 ) ? [ { id: 1, name: Loc.currentLang.menu.all, flag: data, fn: setAll } ] : [] ),
        ...( followersdatalist?.length > 0 ? [ { id: 11, name: Loc.currentLang.menu.followings, flag: data, fn: setFollowers } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => ( x.type === 'LeagueTeam' || x.type === 'Team' ) && x?.hidden !== true && x?.subType == undefined )?.length > 0 ? [ { id: 2, name: Loc.currentLang.menu.filterTeams, flag: data, fn: setMyTeams } ] : [] ),
        ...( user?.orgs?.some( x => x.subType === 'Club' || x.subType === 'Term' )
            ? [ { id: 3, name: Loc.currentLang.menu.clubs, flag: data, fn: setClub } ]
            : []
        ),
        ...( user?.orgs?.filter( ( x ) => x?.type === "Club" )?.length > 0 ? [ { id: 4, name: Loc.currentLang.menu.eliteClubs, flag: data, fn: setElite } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => x.type === 'School' )?.length > 0 ? [ { id: 5, name: Loc.currentLang.menu.schools, flag: data, fn: setSchools } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => x.type === 'Group' && x?.subType === undefined )?.length > 0 ? [ { id: 6, name: Loc.currentLang.menu.groups, flag: data, fn: setGroup } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => x?.type === 'LeagueTeam' && x?.subType === "Class" )?.length > 0 ? [ { id: 7, name: Loc.currentLang.menu.classes, flag: data, fn: setClass } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => x.type === 'Community' )?.length > 0 ? [ { id: 8, name: Loc.currentLang.menu.community, flag: data, fn: setCommunity } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => x?.type === 'Group' && x?.subType === "GradClass" )?.length > 0 ? [ { id: 9, name: Loc.currentLang.menu.gradClass, flag: data, fn: setGradClass } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => x.type === 'Neighborhood' )?.length > 0 ? [ { id: 10, name: Loc.currentLang.menu.neighborHood, flag: data, fn: setNeighbors } ] : [] ),
        ...( user?.orgs?.filter( ( x ) => x.type === 'Organization' )?.length > 0 ? [ { id: 11, name: Loc.currentLang.menu.orgs, flag: data, fn: setOrgs } ] : [] ),
    ];

    useEffect( () =>
    {
        GetTeams();
    }, [ user?.orgs ] );

    const menuRefs = React.useRef( null );

    useOutsideAlerter( menuRefs );
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setIsDropDown( false )
                }
            }
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    return (
        <>
            <Box bgColor='#fff' shadow='1' borderRadius={ 5 }>
                <VStack px={ 3 } pt={ 2 }>
                    <HStack alignItems={ 'center' } space={ 3 } flex={ 1 } justifyContent={ 'space-between' }>
                        { !isMobile && ( <Text fontSize={ '20px' } fontWeight={ 'bold' } width={ isMobile ? '25%' : '40%' } text-overflow={ 'ellipsis' } numberOfLines={ 1 }>
                            { Loc.currentLang.menu.hello },
                            <Text color={ 'AVColor.primary' } width={ '100px' } text-overflow={ 'ellipsis' } fontSize={ '17px' } numberOfLines={ 1 }>{ Person.getLink( Person.self() )?.title }!</Text>
                        </Text> ) }
                        <HStack alignItems={ 'center' } justifyContent={ isMobile ? 'flex-start' : 'flex-end' } space={ 2 } width={ isMobile ? '100%' : 'auto' }>
                            { !isMobile && ( <Text fontSize={ [ 12, 14 ] } color='AVColor.textLite'>
                                { Moment().format( 'MMMM D, Y' ) }
                            </Text> ) }
                            {/*Search*/ }
                            <Input
                                borderWidth={ 0 }
                                width={ isMobile ? "97%" : "251px" }
                                placeholder={ Loc.currentLang.menu.searchMyNetwork }
                                variant='AVSearchInput'
                                placeholderTextColor='AVColor.textLite'
                                backgroundColor={ '#F5F5F5' }
                                borderRadius={ '22px' }
                                fontSize={ '16px' }
                                InputLeftElement={
                                    <SearchIcon ml='2' size='24px' color='#000' left={ '10px' } p={ '7px' } />
                                }
                                onChangeText={ ( e ) => handleSearch( e ) }
                            />
                        </HStack>
                    </HStack>
                    {/*TeamFilter*/ }
                    <div className="custom-scrollbar">
                        <HStack width={ '100%' } space={ 5 }>
                            <VStack width={ isMobile ? "100%" : "" }>
                                <HStack marginY={ '24px' } backgroundColor={ 'rgba(0,0,0,0.05)' } borderRadius={ '18px' } width={ isMobile ? '100%' : 'auto' } overflowX={ isMobile ? 'auto' : undefined } height={ '36px' }>
                                    { MenuFilter?.slice( 0, 6 )?.map( ( item, index ) => (
                                        <Filter key={ item.id } Type={ teamType } SetType={ item?.fn } Name={ item?.name } />
                                    ) ) }
                                    { MenuFilter?.slice( 6 )?.length > 0 && (
                                        <Pressable marginLeft={ '-5px' } marginTop={ '4px' } onPress={ () => setIsDropDown( !isDropDown ) }>
                                            <Image source={ Dropdown } alt='Actavivo' size='30px' />
                                        </Pressable>
                                    ) }
                                </HStack>
                                { isDropDown && ( <VStack
                                    ref={ menuRefs }
                                    width={ '128px' }
                                    height='auto'
                                    shadow={ 2 }
                                    background={ '#FFFFFF' }
                                    position={ 'absolute' }
                                    top={ '66px' }
                                    right={ 0 }
                                    zIndex={ 999 }
                                    borderRadius={ '6px' }
                                >
                                    { MenuFilter?.slice( 6, MenuFilter?.length )?.map( ( item, index ) => (
                                        <>
                                            <Pressable
                                                ref={ menuRefs }
                                                key={ item.id + index }
                                                onPress={ item.fn }
                                                width={ '100%' }
                                                height={ '36px' }
                                                alignItems={ 'flex-start' }
                                                paddingX={ '10px' }
                                                _hover={ {
                                                    backgroundColor: 'gray.100'
                                                } }
                                                justifyContent={ 'center' }
                                            >
                                                <Text
                                                    fontSize={ '14px' }
                                                    fontFamily={ 'Roboto' }
                                                >
                                                    { item.name }
                                                </Text>
                                            </Pressable>
                                            <Divider />
                                        </>
                                    ) ) }

                                </VStack> ) }
                            </VStack>

                        </HStack>
                    </div>
                </VStack>
            </Box>
            <VStack my={ 3 } zIndex={ isDropDown ? -3 : '' }>
                {/*RenderCard*/ }
                <Text fontSize={ '24px' } color={ 'AVColor.primary' }>
                    { Loc.currentLang.menu.myNetwork }
                </Text>
                <HStack
                    backgroundColor={ 'AVColor.white' }
                    space={ 1 }
                    flexWrap={ 'wrap' }
                    mt={ 2 }
                    mb={ isMobile ? "30px" : "" }
                    height={ 'auto' }
                    borderRadius={ 5 }
                    justifyContent={ 'center' }
                    p={ 2 }
                    width={ '100%' }
                >
                    { isLoading &&
                        <HStack width={ '100%' } flexWrap={ 'wrap' } justifyContent={ 'space-around' }>
                            { [ ...Array( 9 ) ].map( ( i, index ) => (
                                <Box key={ index } width={ '1/3' }>
                                    <OrgCardLoader />
                                </Box>
                            ) ) }
                        </HStack>
                    }

                    { data?.length !== 0 &&
                        ( <RenderEvents data={ data } /> ) }
                    { data?.length === 0 &&
                        ( <VStack >
                            <HStack padding={ '10px' } alignItems={ 'center' } >
                                <Text>No Network Found</Text>
                            </HStack>
                        </VStack> ) }
                </HStack>
            </VStack>
        </>
    );
};

export default React.memo( MyTeams );
