import * as React from 'react';
import * as AV from '../AVCore/AVCore';
import { useEffect, useState } from 'react';
import { setIsConvoScreenOpen, setLoginLink, setUser, setuserisguardian, setLastFeedUpdate } from '../Redux/Actions/userActions';
import { setAlerts } from '../Redux/Actions/alertActions';
import { setConvo } from 'Redux/Actions/convoActions';
import { Router, routes } from '../Platform/Routes';
import { PageLoader } from 'components/loaders';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Config } from 'App/Config';
import { Alert } from 'AVCore/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import useInitSSE from 'Hooks/useInitSSE';
import _ from 'lodash';
import ErrorHandlerPopup from '../components/common/ErrorHandlerPopup';
import { setEntityToken } from '../Redux/Actions/userActions';
function Main () {
    console.log("Main.tsx rendering...")
    const { CloseSSE } = useInitSSE();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ Unauthor, setUnauthor ] = React.useState(false);
    const useralertList = useSelector((state : RootStateOrAny) => state?.alerts.alert[ 0 ]);
    const entityToken = useSelector((state : RootStateOrAny) => state?.user.entityToken);
    const userisguardian = useSelector((state : RootStateOrAny) => state?.user.userisguardian);
    const convoval = useSelector((state : RootStateOrAny) => state.convo.convo[ 0 ]);
    const deviceFCMToken = useSelector((state : RootStateOrAny) => state.user.devicefcmtoken);
    const lastUpdateFeed = useSelector((state : RootStateOrAny) => state.user.lastUpdateFeed);
    const [ isShowfollow, setisShowfollow ] = React.useState(true);
    const [ isLandingPage, setIsLandingPage ] = React.useState(false);
    const [ entityLink, setentityLink ] = React.useState<any>('');
    const [ lastfeed, setlastfeed ] = useState<any>([]);

    async function init () {
        location.state = '';
        await Config.init();
        CloseSSE();
        let setConvoScreenOpen = { isOpen: false, convoId: undefined }
        dispatch(setIsConvoScreenOpen(setConvoScreenOpen));

        if (new URLSearchParams(window.location.search) !== null) {
            if (window.location.pathname === '/Invite' || window.location.pathname === 'Activate') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id') || undefined;
                    let region = new URLSearchParams(window.location.search).get('region');
                    let token = new URLSearchParams(window.location.search).get('token') || undefined;
                    let type = new URLSearchParams(window.location.search).get('type') || undefined;
                    let fk : AV.IFK = {
                        id: id,
                        pk: id?.substr(-4),
                        region: region,
                        type: 'Person',
                    };
                    let tokenreq : AV.ITokenRequest = {
                        token: token, fk: fk, type: type
                    }
                    let actaivateperson : any = await AV.AVEntity.processToken(tokenreq);
                    if (actaivateperson?.id) {
                        navigate(`/profile/${ actaivateperson?.id }/${ actaivateperson?.region }`, {
                            state: {
                                user: {
                                    id: actaivateperson?.id,
                                    pk: actaivateperson?.pk,
                                    region: actaivateperson?.region,
                                    type: actaivateperson?.type,
                                    name: actaivateperson?.name,
                                },
                            },
                        });
                    }
                    else if (actaivateperson?.number === -2146233088) {
                        setUnauthor(true);
                    }
                    navigate('/');
                }
            } else if (window.location.pathname === '/Event') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id');
                    let region = new URLSearchParams(window.location.search).get('region');
                    let fk : AV.IFK = { id: id, type: 'Event', region: region, pk: id?.substr(-4) };
                    navigate(`/Events/${ fk?.id }/${ fk?.region }`, { state: { eventdata: fk } });
                }
            } else if (window.location.pathname === '/Post') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id');
                    let pk = new URLSearchParams(window.location.search).get('pk');
                    let region = new URLSearchParams(window.location.search).get('region');
                    let fk : AV.IFK = { id: id, pk: pk, region: region, type: 'Post' };
                    navigate('/', { state: { previewdata: fk } });
                }
            } else if (window.location.pathname === '/Convo') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id');
                    let pk = new URLSearchParams(window.location.search).get('pk');
                    let region = new URLSearchParams(window.location.search).get('region');
                    let fk : AV.IFK = { id: id, pk: pk, region: region };
                    navigate('/', { state: { convovalue: fk } });
                }
            }
            else if (window.location.pathname?.startsWith('/Follow')) {
                let token = window.location.pathname.replace( /^\/Follow\//, '' );
                let val : any = await AV.AVEntity.getTokenRequest( token, "Follow" );
                let returnvalue = await val.json();
                setisShowfollow( true );
                setIsLandingPage( true );
                if ( returnvalue?.id )
                {
                    setentityLink( returnvalue );
                    dispatch( setEntityToken( returnvalue ) );
                }
                if ( token )
                {
                    let tokenreq : AV.ITokenProcessRequest = {
                        token: token, entity: returnvalue, type: "Follow"
                    }
                    let actaivateperson : any = await AV.AVEntity.processToken( tokenreq );
                    if ( actaivateperson?.id )
                    {
                        dispatch( setUser( actaivateperson ) );
                        handleNavigate( returnvalue );
                        /*navigate('/');*/
                    }
                    else if ( actaivateperson?.number === -2146233088 )
                    {
                        setUnauthor( true );
                    }
                }
              else
              {
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Follow');
                let returnvalue = await val.json();
                setisShowfollow(true);
                //setIsLandingPage(true);
                if (returnvalue) {
                  setentityLink(returnvalue);
                  dispatch(setEntityToken(returnvalue));
                }
              }
            }
            else if (window.location.pathname?.startsWith('/Join')) {
                let token = window.location.pathname.replace(/^\/Join\//, '');
              if (entityToken && entityToken?.length != 0) {
                let tokenreq : AV.ITokenProcessRequest = {
                  token: token, entity: entityToken, type: "Join"
                }
                let actaivateperson : any = await AV.AVEntity.processToken(tokenreq);
                if (actaivateperson?.id) {
                  dispatch(setUser(actaivateperson));
                  handleNavigate(entityToken);
                  /*navigate('/');*/
                }
                else if (actaivateperson?.number === -2146233088) {
                  setUnauthor(true);
                }
              }
              else
              {
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Join');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                  setentityLink(returnvalue);
                  dispatch(setEntityToken(returnvalue));
                }
              }
            }
            else if (window.location.pathname?.startsWith('/Invite')) {
                let token = window.location.pathname.replace(/^\/Invite\//, '');
              if (entityToken && entityToken?.length != 0) {
                let tokenreq : AV.ITokenProcessRequest = {
                  token: token, entity: entityToken, type: "Invite"
                }
                let actaivateperson : any = await AV.AVEntity.processToken(tokenreq);
                if (actaivateperson?.id) {
                  dispatch(setUser(actaivateperson));
                  handleNavigate(entityToken);
                  /*navigate('/');*/
                }
                else if (actaivateperson?.number === -2146233088) {
                  setUnauthor(true);
                }
              }
              else
              {
                let val : any = await AV.AVEntity.getTokenRequest(token, 'Invite');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                  setentityLink(returnvalue);
                  dispatch(setEntityToken(returnvalue));
                }
              }
            }
            else if (window.location.pathname?.startsWith('/AddPlayer')) {
                let token = window.location.pathname.replace(/^\/AddPlayer\//, '');
              if (entityToken && entityToken?.length != 0) {
                let tokenreq : AV.ITokenProcessRequest = {
                  token: token, entity: entityToken, type: "AddPlayer"
                }
                let actaivateperson : any = await AV.AVEntity.processToken(tokenreq);
                if (actaivateperson?.id) {
                  dispatch(setUser(actaivateperson));
                  handleNavigate(entityToken);
                  /*navigate('/');*/
                }
                else if (actaivateperson?.number === -2146233088) {
                  setUnauthor(true);
                }
              }
              else
              {
                let val : any = await AV.AVEntity.getTokenRequest(token, 'AddPlayer');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                  setentityLink(returnvalue);
                  dispatch(setEntityToken(returnvalue));
                }
              }
            }
            else if (window.location.pathname?.startsWith('/AddStaff')) {
                let token = window.location.pathname.replace(/^\/AddStaff\//, '');
              if (entityToken && entityToken?.length != 0) {
                let tokenreq : AV.ITokenProcessRequest = {
                  token: token, entity: entityToken, type: "AddStaff"
                }
                let actaivateperson : any = await AV.AVEntity.processToken(tokenreq);
                if (actaivateperson?.id) {
                  dispatch(setUser(actaivateperson));
                  handleNavigate(entityToken);
                  /*navigate('/');*/
                }
                else if (actaivateperson?.number === -2146233088) {
                  setUnauthor(true);
                }
              }
              else
              {
                let val : any = await AV.AVEntity.getTokenRequest(token, 'AddStaff');
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                  setentityLink(returnvalue);
                  dispatch(setEntityToken(returnvalue));
                }
              }
            }
            else if (window.location.pathname?.startsWith('/Add')) {
              let token = window.location.pathname.replace(/^\/Add\//, '');
              let val : any = await AV.AVEntity.getTokenRequest(token, "Add");
              let returnvalue = await val.json();
              setisShowfollow(true);
              setIsLandingPage(true);
              if (returnvalue?.id) {
                setentityLink(returnvalue);
                dispatch(setEntityToken(returnvalue));
              }
                if (token) {
                    let tokenreq : AV.ITokenProcessRequest = {
                      token: token, entity: returnvalue, type: "Add"
                    }
                    let actaivateperson : any = await AV.AVEntity.processToken(tokenreq);
                    if (actaivateperson?.id) {
                      dispatch(setUser(actaivateperson));
                      handleNavigate(returnvalue);
                        /*navigate('/');*/
                    }
                    else if (actaivateperson?.number === -2146233088) {
                        setUnauthor(true);
                    }
                  }
                else
                {
                  let val : any = await AV.AVEntity.getTokenRequest(token, 'Add');
                  let returnvalue = await val.json();
                  setisShowfollow(true);
                  //setIsLandingPage(true);
                  if (returnvalue?.id) {
                    setentityLink(returnvalue);
                    dispatch(setEntityToken(returnvalue));
                  }
                }
            }
            else if (window.location.pathname?.startsWith('/Activate')) {
                let token = window.location.pathname.replace(/^\/Activate\//, '');
                if (entityToken && entityToken?.length != 0) {
                    let activateType = userisguardian ? "Guardian" : "Player";
                    let tokenreq : AV.ITokenRequest = {
                        token: token, type: activateType
                    }
                    let actaivateperson = await AV.Person.activate(tokenreq);
                    dispatch(setuserisguardian(false));
                    if (actaivateperson?.id) {
                        navigate(`/profile/${ actaivateperson?.id }/${ actaivateperson?.region }`, {
                            state: {
                                user: {
                                    id: actaivateperson?.id,
                                    pk: actaivateperson?.pk,
                                    region: actaivateperson?.region,
                                    type: actaivateperson?.type,
                                    name: actaivateperson?.name,
                                },
                            },
                        });
                    }
                    else {
                        setUnauthor(true);
                    }
                    navigate('/');
                }
            }
            else if (window.toString().startsWith("/api") || window?.location?.pathname?.startsWith('/api')) {
                navigate('/');
            }
        }
        let person = await AV.Person.getSelf();
        if (person.fcmTokens?.filter(x => x === deviceFCMToken).length === 0 && deviceFCMToken !== '' && deviceFCMToken != undefined) {
            AV.Person.addFCMToken(deviceFCMToken);
        }
        if (person?.wards !== undefined && person?.wards?.length > 0) dispatch(setuserisguardian(true));
        let alertsval = useralertList;
        let lastAlertDate = Alert._startDate == undefined ? new Date(0) : Alert._startDate;
        var alertList : any = await Alert.List(lastAlertDate);
        alertList = alertList?.alerts;
        if (alertsval?.length > 0) {
            for (let i = 0; i < alertList?.length; i++) {
                let excitingalerts = alertsval?.filter((x) => x?.id === alertList[ i ]?.id);
                if (excitingalerts?.length === 0) alertsval.unshift(alertList[ i ]);
            }
        }
        alertsval = _.uniqBy(alertsval?.length > 0 ? alertsval : alertList, 'id');
        alertsval?.sort(function (a, b) {
            let c : any = new Date(b.date);
            let d : any = new Date(a.date);
            return c - d;
        });
        dispatch(setAlerts(alertsval));
        dispatch(setLoginLink(""));
        if (convoval?.length == 0 || convoval == undefined)
            await getConvo();
        if (person?.status === 'NewUser' || person?.status === 'Deleted' || person?.status === "Suspended") {
            navigate(`/profile/${ person?.id }/${ person?.region }`, {
                state: {
                    user: {
                        id: person?.id,
                        pk: person?.pk,
                        region: person?.region,
                        type: person?.type,
                        name: person?.name,
                    },
                },
            });
        }


        dispatch(setUser(person));

        setIsLoading(false);
    }

    const handleNavigate = async (item : any) => {
        {
            item?.type == 'Event'
                ? navigate(`/Events/${ item?.id }/${ item?.region }`, { state: { team: item } })
                : item?.type == 'LeagueTeam'
                    ? navigate(`/season/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } }) :
                    item?.type == 'Team' ?
                        navigate(`/teams/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } }) :
                        (item?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa72' || item?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa71' || item?.id === 'b495d4e0-bf52-4690-8dc0-677db954aa7f') ?
                            navigate(`/teams/${ item?.id }/${ item?.region }`, {
                                state: { isCreate: false, team: item, type: 'Feedback' },
                            }) :
                            item?.type == 'Person' ?
                                navigate(`/profile/${ item.id }/${ item.region }`, {
                                    state: {
                                        user: {
                                            id: item?.id,
                                            pk: item?.pk,
                                            region: item?.region,
                                            type: item?.type,
                                            name: item?.title,
                                        },
                                    },
                                }) :
                                navigate(`/organizations/${ item?.id }/${ item?.region }/${ item?.type }`, {
                                    state: { isCreate: false, orgType: item?.type, team: item },
                                })
        }
    }

    const getConvo = async () => {
        let convovalue : AV.IConversation[] = [];
        let r = 0;
        let person : AV.IPerson = await AV.Person.getSelf();
        let ConvovalList = convoval;
        if (person.convos !== undefined) {
            for (let i = 0; i < person.convos.length; i++) {
                let param : AV.IFeedParam = {
                    feed: person?.convos[ i ], checkArchive: false,
                    startDate: new Date(ConvovalList?.length > 0 ? ConvovalList[ 0 ]?.lastUpdated : 0),
                    forward: true
                };

                // Create a function to handle the delay
                const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

                // eslint-disable-next-line no-loop-func
                let data : any = {
                    Ifeed: param,
                    lasttime: new Date()
                };
                lastfeed?.push(data)
                dispatch(setLastFeedUpdate(lastfeed));
                setlastfeed(lastUpdateFeed)
                await AV.Feed.getFeed(param).then((response : any) => {

                    let PostList = AV.Feed.getPostList(response);
                    if (PostList?.length > 0) {
                        AV.Feed.getPosts(AV.AVEntity.getFk(response), PostList?.slice(0, 10)).then((x : any) => {
                            if (x?.length > 0) {
                                x.sort((a : any, b : any) => {
                                    const createDateA = new Date(a.createDate) as any;
                                    const createDateB = new Date(b.createDate) as any;
                                    return createDateB - createDateA;
                                });
                                response.posts = x;
                                if (response?.posts !== undefined && response?.posts?.length > 0) convovalue.push(response);
                                if (person?.convos !== undefined && r === person?.convos?.length) {
                                    Convoupdate(convovalue, ConvovalList);
                                }
                            }
                        });
                    }
                    r++;
                });

                // Add 10 seconds delay
                await delay(100);
            }
        }
    };

    const Convoupdate = (convovalue, ConvovalList) => {
        if (ConvovalList?.length === 0 || ConvovalList === undefined || ConvovalList === null) ConvovalList = [];
        for (let i = 0; i < convovalue?.length; i++) {
            let excitingconvo = ConvovalList?.filter((x) => x?.id == convovalue[ i ]?.id);
            if (excitingconvo?.length > 0) {
                for (let j = 0; j < convovalue[ i ]?.posts?.length; j++) {
                    let excitingconvopost = excitingconvo[ 0 ]?.posts?.filter(
                        (x) => x?.id === convovalue[ i ]?.posts[ j ]?.id
                    );
                    if (excitingconvopost?.length === 0) {
                        excitingconvo[ 0 ]?.posts.push(convovalue[ i ]?.posts[ j ]);
                    }
                }
            } else ConvovalList.unshift(convovalue[ i ]);
        }
        ConvovalList = _.uniqBy(ConvovalList, 'id');
        dispatch(setConvo(ConvovalList));
    };

    useEffect(() => {
        setIsLoading(true);
        async function fetchData () {
            await init();
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData () {
            dispatch(setLastFeedUpdate(lastUpdateFeed));
        }
        fetchData();
    }, [ lastUpdateFeed ]);

    return <>{ isLoading ? <PageLoader /> : Unauthor ? <ErrorHandlerPopup /> : <Router routes={ routes } /> }</>;
}

export default React.memo( Main );
