import React, { useState, useEffect, useCallback } from 'react';
import { VStack, HStack, Button, Text, Box, Radio, Stack, Divider, Pressable, Image, Checkbox, View} from 'native-base';
import { useNavigate,useLocation } from 'react-router-dom';
import { Loc } from '../../App/Loc';
import AVInputtext from '../../components/AVInputtext';
import AVDropdown from '../../components/AVDropdown';
import { components } from 'react-select';
import Dropdown from '../../assets/images/dropdown.svg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as AV from '../../AVCore/AVCore';
import * as AVI from '../../AVCore/Interfaces/AV.Interfaces';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { setEntitydata, setUser, setisActive } from '../../Redux/Actions/userActions';
import { HelpIcon, ColorPickerIcon } from '../../assets/icons/icon-assets';
import AVMascot from './AVMascot';
import { GithubPicker } from 'react-color';
import AVLocationBox from './AVLocationBox';
import { Dimensions } from 'react-native';
import AVDatePicker from '../../components/common/AVDatePicker';
import AVTimePicker from '../../components/common/AVTimePicker';
import dayjs from 'dayjs';
import Moment from 'moment';
import moment from 'moment';
import AVFieldMap from '../../components/common/AVFieldMap';
import CustomToast from '../../Hooks/useCustomToast';
import AVConfirmationModal from '../../components/common/AVConfirmationModal';
import AddandEditLinks from './AddandEditLinks';

const AVCreateTeam = ( { isCreate, setIsCreate, Entity, setEntity, isShowEdit, setIsShowEdit, teamType, profile, handleUpload, setProfile, type, EntityDataFinding, setgooglemap, GetWeatherIcon, setShowCreateNewPage, setCreateType, setPassEntity, passEntity }) => {
    const navigate = useNavigate();
    const currentUser = useSelector((state:RootStateOrAny) => state?.user?.user[0]);
    const location: any = useLocation();
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 600 || false;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const orgFK = passEntity;
    const teamValue = teamType;
    const user = useSelector((state: RootStateOrAny) => state?.user?.user[0]);
    const [name, setName] = useState<any>('');
    const [description, setDescription] = useState<any>('');
    const [activity, setActivity] = useState<any>('');
    const [seasonName, setSeasonName] = useState<any>('');
    const [gender, setGender] = useState<any>('');
    const [Links, setLinks] = useState<any>('');
    const [gendertypelist, setgendertypelist] = React.useState<any>();
    const [activitytypelist, setactivitytypelist] = React.useState<any>();
    const entitydatalist = useSelector((state: RootStateOrAny) => state.user.entitydatalist);
    const [primaryColor, setPrimaryColor] = useState(Entity?.primaryColor || 'black');
    const [secondaryColor, setSecondaryColor] = useState(Entity?.secondaryColor || '#E7875E');
    const [isPrimary, setIsPrimary] = useState(false);
    const [isSecondary, setIsSecondary] = useState(false);
    const [locationaddress, setlocationaddress] = useState<any>([]);
    const [mascotImage, setMascotImage] = useState<any>(Entity?.mascot ? AV.Media.getURL(Entity?.mascot):null);
    const [ eventStartDate, setEventStartDate ] = useState(Entity?.start ? Moment(Entity?.start).format('YYYY-MM-DD') : '');
    const [ eventTeamArrivalDate, setTeamArrivalDate ] = useState( Entity?.playerStart ? Moment( Entity?.playerStart ).format( 'YYYY-MM-DD' ) : '' );
    const [eventEndDate, setEventEndDate] = useState(Entity?.start ? Moment(Entity?.end).format('YYYY-MM-DD') : '');
    const [eventStartTime, setEventStartTime] = useState(Entity?.start ? Entity?.start : '');
    const [eventEndTime, setEventEndTime] = useState<any>(Entity?.end ? Entity?.end : '');
    const [ startingTime, SetStartingTime ] = useState( '' );
    const [ endingTime, setEndingTime ] = useState( '' );
    const [ Player, setPlayer ] = useState( '' );
    const [deleteLoader, SetDeleteLoader] = useState(false);
    const [eventLocation, setEventLocation] = useState<any>('');
    const [ refreshKey, setRefreshKey ] = useState( 0 ); // Key prop for forcing re-render
    const [ refreshTimeKey, setRefreshTimeKey ] = useState( 0 ); // Key prop for forcing re-render
    const [ Playerstart, setPlayerStart ] = useState<any>( Entity?.playerStart ? Entity?.playerStart  : '');
    const [ isActives, setIsActives ] = useState( false );
    const [ FieldValue, setFieldValue ] = useState( '' );
    const [ mapField, setIsMapField ] = useState( Entity?.location?.field?.name ? Entity?.location?.field?.name :'');
    const [ locationEntity, setLocationEntity ] = useState<any>();
    const [ isFieldsName, setIsFieldsName ] = useState();
    const urlRegex = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
    const [ message, setMessage ] = useState( Loc.currentLang.helpText.invalidUrlMessage )
    const [ isConfirmDelete, setisConfirmDelete ] = useState( false );
    const [ Confirmationtext, setConfirmationtext ] = React.useState( "Are you sure ?" );
    const [ selectType, setselectType ] = React.useState<any>();
    const [ itemSelect, setItemSelect ] = React.useState<any>( '' );
    const [ isLoader, setLoader ] = useState( false );
    const [ addLinkPopup, setAddLinkPopup ] = useState( false );
    const [ linkList, setLinksList ] = useState<any>( [] )
    const CurrentDateTime = new Date();
    CurrentDateTime.setMinutes( 0 );
    const year = CurrentDateTime.getFullYear();
    const month = ( CurrentDateTime.getMonth() + 1 ).toString().padStart( 2, '0' );
    const day = CurrentDateTime.getDate().toString().padStart( 2, '0' );
    const DefaultEventDate = `${ year }-${ month }-${ day }`;
    const [ addLink, setAddLink ] = useState( false );
    const [ url, setUrl ] = useState( '' );
    const adjustedEventTime = CurrentDateTime.toLocaleTimeString( [], { hour: 'numeric', minute: 'numeric', hour12: true } );
    const [ triggerEndDate, setTriggerEndDate ] = useState( false );
    const [ triggerStartTime, setTriggerStartTime ] = useState( false );
    const formatTime = ( timeString ) =>
    {
        if ( !timeString ) return ''; // Return empty string if timeString is empty or undefined
        const time = new Date( timeString ); // Create a Date object from the timeString
        let hours = time.getHours();
        const minutes = time.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine whether it's AM or PM
        hours %= 12; // Convert hours to 12-hour format
        hours = hours || 12; // Handle 0 as 12
        const formattedTime = `${ hours }:${ minutes < 10 ? '0' : '' }${ minutes } ${ ampm }`; // Format the time
        return formattedTime;
    };

    const startTime = formatTime( eventStartTime );
    const endTime = formatTime( eventEndTime );
    const [ editEventStartingTime, setEditEventStartingTime ] = useState( startTime );
    const [ editEventEndingTime, setEditEventEndingTime ] = useState( endTime );
    const [ isValidUrl, setIsValidUrl ] = useState( true ); // Initialize as true assuming the URL is initially valid
    //Url
    const handleEventUrl = ( e ) =>
    {
        e.preventDefault();
        setUrl( e.target.value );
        const inputUrl = e.target.value;
        const isValidLink = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
        setIsValidUrl( isValidLink.test( inputUrl ) );
        setUrl( inputUrl ); // Assuming you're updating the URL state here
    };

  

    let reference = type === 'Organization' ?  {
        autoFollow: false,
        allowFollowersPosts: false,
    } : {
        autoFollow: false,
        allowFollowersPosts: false,
        allowReplyPosts: false ,
        allowAllToPost:false,
    } 
    const [ Prefernces, setPrefernces ] = React.useState<any>(reference);
    const [ GroupType, setGroupType ] = useState( {
        autoapprove: false,
        allowfollwerstopost: false,
        autoreplytopost: false,
        allowalltopost: false,
        allowmemberstopost: true,
        selectedPreference: 'Normal Group'  // Initial or default value
    } );
    const [ error, setError ] = useState( '' )
    const [ isValidateDate, setIsValidDate ] = useState( true )
    const [ isValidateEndDate, setIsValidEndDate ] = useState(true)
    const [ isValidStartTime, setIsValidStartTime ] = useState(true)
    const [ isValidEndTime, setIsValidEndTime ] = useState(true)

/*    console.log( "avcreateeventmodal" );*/
    const dropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src={Dropdown} alt='Dropdown' style={{ width: '25px' }} />
            </components.DropdownIndicator>
        );
    };
    const handleBannerColor = (color) => {
        if (isPrimary) {
            setPrimaryColor(color.hex);
            setIsPrimary(false);
        }
        else {
            setSecondaryColor(color.hex);
            setIsSecondary(false);
        }
    };

    const customS = {
        maxHeight: '53px', // Set your desired height here
    };
    const handleIntroText = (e) => {
        e.preventDefault();
        setDescription(e?.target?.value);
        setProfile((profile) => ({ ...profile, text: e.target.value }));
    };
  //StartDate
  const handleEventStartDate = (e) => {
    if (e != null) {
      const inputDate = new Date(e);

      // Check if the entered date is a valid date
      if (isNaN(inputDate.getTime())) {
        //setError( "Invalid date entered" );
        setIsValidDate(false);
        setEventStartDate("");
        // You can set an error state or display an error message here
        return;
      }

      const date = new Date(e);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const formattedDate = date.toISOString().split('T')[ 0 ];
      setEventStartDate(formattedDate);
      setError('');
      setIsValidDate(true)
      if (formattedDate > eventEndDate) {
        setEventEndDate(formattedDate);
        setTriggerEndDate(!triggerEndDate);
      }
    }
  };

  //EndDate
  const handleEventEndDate = (e) => {
    if (e != null) {
      const inputDate = new Date(e);

      // Check if the entered date is a valid date
      if (isNaN(inputDate.getTime())) {
        // setError( "Invalid date entered" );
        setIsValidEndDate(false);
        setEventEndDate("");
        // You can set an error state or display an error message here
        return;
      }
      const date : any = new Date(e);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const formattedDate = date.toISOString().split('T')[ 0 ];
      setEventEndDate(formattedDate);
      setError('');
      setIsValidEndDate(true)
    }
  };

  const handleupdatelocationfield = (e, siteField) =>
    {
      setIsMapField(e?.name)

    setIsFieldsName(siteField?.find((x:any)=> x?.name == e));
    };
        let concatfieldmap = {
        field: isFieldsName,
        siteMap: locationEntity?.siteMap,
    };
    let locationoffield = {
        ...locationaddress,
        ...concatfieldmap,
    };

    const correctTimeFormat = ( timeString : any ) =>
    {
        const time = timeString.toLowerCase();
        const timeRegex = /^(\d{1,2}):(\d{2}) ([ap]m)$/;
        const match = time.match( timeRegex );
        if ( match )
        {
            let hour = parseInt( match[ 1 ], 10 );
            const minute = match[ 2 ];
            const period = match[ 3 ].toUpperCase(); // Convert "am/pm" to uppercase      
            if ( period === 'PM' && hour !== 12 )
            {
                hour += 12;
            } else if ( period === 'AM' && hour === 12 )
            {
                hour = 0;
            }
            const formattedTime = `${ hour.toString().padStart( 2, '0' ) }:${ minute }`;
            return formattedTime;
        }
        return timeString;
    };

  //StartTime
  const handleEventStartTime = async (e) => {
    handlechange(e)
    const myDate : any = new Date(e);
    if (myDate.toString() === "Invalid Date" || isNaN(myDate) || e == null) {
      setIsValidStartTime(false);
      setEventStartTime("");
      return;
    }
    const timeString = myDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    let val = await correctTimeFormat(timeString);
    const [ hours, minutes ] = val.split(':').map(Number);
    let newdate = new Date(eventStartDate);
    newdate.setHours(hours);
    newdate.setMinutes(minutes);
    setEventStartTime(newdate);
    setEditEventStartingTime(timeString);
    setIsValidStartTime(true);
    if (eventStartDate === eventEndDate) {
      const newdatePlusOneHourClone = new Date(eventStartDate);
      newdatePlusOneHourClone.setHours(hours);
      newdatePlusOneHourClone.setMinutes(minutes);
      newdatePlusOneHourClone.setHours(newdatePlusOneHourClone.getHours() + 1);
      setEventEndTime(newdatePlusOneHourClone);
      if (newdate >= new Date(eventEndTime)) {
        setTriggerStartTime(!triggerStartTime)
      }

      handlechangeend(newdatePlusOneHourClone);
    }
  };
  const handlechange = (e) => {
    const myDate : any = new Date(e);
    if (myDate.toString() === "Invalid Date" || isNaN(myDate) || e == null) {
      setIsValidStartTime(false);
      SetStartingTime("");
      return;
    }
    const currentHour = myDate.getHours();
    const newDate = new Date(myDate);
    newDate.setHours(currentHour);
    const timeString = newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    SetStartingTime(timeString);
  }
  //EndTime
  const handleEventEndTime = async (e) => {
    const myDate : any = new Date(e);
    if (myDate.toString() === "Invalid Date" || isNaN(myDate) || e == null) {
      setIsValidEndTime(false);
      setEventEndTime("");
      setEndingTime("");
      return;
    }
    handlechangeend(e)
    const timeString = myDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    let val = await correctTimeFormat(timeString);
    const [ hours, minutes ] = val.split(':').map(Number);
    let newdate = new Date(eventEndDate);
    newdate.setHours(hours);
    newdate.setMinutes(minutes);
    setEventEndTime(newdate);
    setEditEventEndingTime(timeString);
  };

  const handlechangeend = (e) => {
    const myDate : any = new Date(e);
    if (myDate.toString() === "Invalid Date" || isNaN(myDate) || e == null) {
      setIsValidEndTime(false);
      setEventEndTime("");
      setEndingTime("");
      return;
    }
    setIsValidEndTime(true);
    const currentHour = myDate.getHours();
    const newDate = new Date(myDate);
    newDate.setHours(currentHour);
    const timeString = newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    setEndingTime(timeString);
  }

    const handleEventTeamArrival = (e) =>
    {
        if ( e !== null )
{
            const inputDate = new Date( e );
            // Check if the entered date is a valid date
            if ( isNaN( inputDate.getTime() ) )
            {
                let clientError = { message: "Invalid date entered" }
                //CustomToast( { clientError } );
                setIsValidDate( false )
                // You can set an error state or display an error message here
                return;
            }
            const date = new Date( e );
            date.setMinutes( date.getMinutes() - date.getTimezoneOffset() );
            const formattedDate = date.toISOString().split( 'T' )[ 0 ];
            setTeamArrivalDate( formattedDate );
            setIsValidDate( true );
        }
        else
        {
            setTeamArrivalDate('')
        }
    }
    const handleEventTeamArival = ( e ) =>
    {
        if ( e !== null )
        {
            const myDate = new Date( e );
            const timeString = myDate.toLocaleTimeString( [], { hour: '2-digit', minute: '2-digit' } );
            setPlayerStart( timeString );
            setPlayer( timeString );
        }
        else
        {
            setPlayer( '' );
        }

    };
    const HandleName = (e) => {
        setName(e?.target?.value)
    };
    const HandleLocationMap = async ( e : any ) =>
    {
        let map : any = await AV.Location.updateMap(locationaddress);
    };
    const HandleSeasonName = (e) => {
        setSeasonName(e?.target?.value)
    };
    const HandleActivity = (e) => {
        setActivity(e?.value)
    };
    const HandleGender = (e) => {
        setGender(e?.target?.value)
    };
    const HandleLinks = (e) => {
        setLinks(e?.target?.value)
    };


    const handleDeleteEvent  = () =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( 'Are You Sure? You Want to Delete this Event' );
        setselectType( 'DeleteEvent' )
    }

    const DeleteAccount = async () =>
    {
        setLoader( true )
        await AV.Event.cancelEvent( AV.AVEntity.getFk( Entity ) )
        Entity.status = "Canceled";
      entitydatalist[ entitydatalist.indexOf(entitydatalist?.find(x => x?.id === Entity?.id)) ] = Entity;

        let data : any = currentUser.events?.filter( x => x?.id == Entity?.id )
        if ( data )
        {
            data[ 0 ].status = "Canceled";
            data[ 0 ].data = "Canceled";
            let Index : any = currentUser.events?.findIndex( x => x?.id == data[ 0 ]?.id )
            if ( Index !== -1 )
            {
                currentUser.events[ Index ] = data[ 0 ];
                dispatch( setUser( currentUser ) );
            }
        }
     dispatch( setEntitydata( entitydatalist ) );
        setLoader( false );
        setIsShowEdit( false )
    }

    const handleSaveTeam = async (e) => {
        e.preventDefault();
        /*if ( Links?.length > 0 )
        {
            if ( !urlRegex.test( Links ) )
            {
                let clientError = { message: Loc.currentLang.menu.invalidExternalLink }
                CustomToast( { clientError } );
                setError(Loc.currentLang.helpText.invalidUrlMessage)
                return;
            }
        }*/
        if (isCreate) {
            //Create Team
            setIsLoading( true );
            setError('')
            let TeamType = 'Team';
            if (teamType && orgFK) TeamType = teamType;
            var team : any = {
                type: TeamType,
                name: name,
                profile: profile,
                activity: activity,
                gender: gender,
                links: linkList,
            };
            dispatch( setisActive( true ) );
            team = await AV.AVEntity.save( team );
            //Create season
            if (TeamType === 'Team') {
                var Season : any = {
                    gender: gender,
                    type: 'LeagueTeam',
                    name: name,
                    links: linkList,
                    season: seasonName,
                    profile: profile,
                    team: AV.AVEntity.getLink( team ),
                    Parent:AV.AVEntity.getLink( team ),
                };
                dispatch( setisActive( true ) );
                Season = await AV.AVEntity.save( Season );            }
            if (orgFK) {
                await AV.Club.addTeam(AV.AVEntity.getFk(orgFK), AV.AVEntity.getFk(team));
                user?.autoFollows?.unshift(AV.AVEntity.getLink(team));
                user.orgs.unshift(AV.AVEntity.getLink(team)); dispatch(setUser(user));
                entitydatalist.push(team); dispatch(setEntitydata(entitydatalist));
            }
            setIsLoading(false);
            //setIsCreate(false);
            //if (team != null) {
            //    setEntity(team);
            //    setName(team?.name);
            //    setDescription(team?.profile?.text);
            //    setActivity(team?.activity);
            //}
            if ( team && Season )
            {
                team?.childOrgs?.push( AV.AVEntity.getLink( Season ))
                entitydatalist?.push(team); entitydatalist.push(Season); dispatch(setEntitydata(entitydatalist));
                user?.autoFollows?.unshift(AV.AVEntity.getLink(team)); user?.autoFollows?.unshift(AV.AVEntity.getLink(Season));
                user.orgs.unshift( AV.AVEntity.getLink( Season ) );
                user.orgs.unshift( AV.AVEntity.getLink( team ) ); dispatch( setUser( user ) );
                //setEntity(team);
            }            
            setIsLoading( false );
            setShowCreateNewPage( false );
            if ( orgFK )
            {
                setPassEntity( '' )
            }
            navigate(`/teams/${ team?.id }/${ team?.region }`, { state: { isCreate: false, team: team, newEntity:true } });
        }
        else {
            //Update League Team
            setIsLoading(true);
            var leagueteam : AVI.ILeagueTeam = Entity;
            leagueteam.name = name;
            leagueteam.profile = Entity?.profile ? Entity?.profile : profile ? profile : profile ;
            leagueteam.activity = activity;
            leagueteam.season = seasonName;
            leagueteam.links = linkList;
            leagueteam.gender = gender;
            leagueteam.primaryColor = primaryColor;
            leagueteam.secondaryColor = secondaryColor;
            leagueteam.mascot = leagueteam.mascot?.id ? leagueteam.mascot : mascotImage?.id ? mascotImage : undefined;
            dispatch( setisActive( true ) );
            leagueteam = await AV.AVEntity.save( leagueteam );
            if (leagueteam) {
                AV.AVEntity.checkLinks(user, leagueteam).then((x) => {
                    dispatch(setUser(x));
                    AV.Person.checkIfSelf(x);
                });
                let oldvalue = user.orgs.filter((a) => a.id == leagueteam.id);
                user.orgs[ user.orgs.indexOf(oldvalue[ 0 ]) ] = leagueteam;
                dispatch(setUser(user));
                entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === leagueteam?.id ) ) ] = leagueteam;
                dispatch( setEntitydata( entitydatalist ) );
                setIsLoading(false);
                setIsShowEdit(false);
                navigate(`/teams/${ leagueteam?.id }/${ leagueteam?.region }`, {
                  state: { isCreate: false, team: leagueteam, newEntity :true},
                });
            }
        }
    };
    const handleSaveOrg = async (e) => {
        e.preventDefault();
        //if ( Links?.trim()?.length > 0 )
        //{
        //    if ( !urlRegex.test( Links ) )
        //    {
        //        let clientError = { message: Loc.currentLang.menu.invalidExternalLink }
        //        CustomToast( { clientError } );
        //        setError( Loc.currentLang.helpText.invalidUrlMessage )
        //        return;
        //    }
        //}
        setIsLoading( true );
        setError( '' );
        if (isCreate) {
            setIsLoading( true );
          
            //Create Club/School
            var org : any = {
                type: (type == "Group" || type == "GradClass" ) ? "Group" : type == "Club" ? "Team" : type == "EliteClub" ? "Club" : type == "Class" ? "LeagueTeam" : type,
                name: name,
                profile: profile,
                location: ( type == "Class" || type == "GradClass" || type == "Group" ) ? null : locationoffield ? locationoffield : null,
                links: linkList,
            };
            if (type == "Class" || type == "GradClass" || type == "Club") {
                org.subType = type == "Club" ? "Club" : type
            }
            if (type == 'Organization') {
                org.preferences = Prefernces;
            }
            if (type == "Group" )
            {
                org.preferences = GroupType;
            }
            dispatch( setisActive( true ) );
            org = await AV.AVEntity.save( org );
            if (locationaddress?.length > 0 )
            {
                HandleLocationMap( locationaddress )
            }
            /*setEntity(org);*/
            if (org.subType == 'Club') {
                var Season : any = {
                    gender: gender,
                    type: 'LeagueTeam',
                    name: name,
                    links: linkList,
                    subType: 'Term',
                    season: seasonName,
                    profile: profile,
                    team: AV.AVEntity.getLink( org ),
                    Parent: AV.AVEntity.getLink(org)
                };
                dispatch( setisActive( true ) );
                Season = await AV.AVEntity.save( Season );
            }
          CheckReturnValue(org);
            if (orgFK) {
                 await AV.Club.addTeam(AV.AVEntity.getFk(orgFK), AV.AVEntity.getFk(org));
                user?.autoFollows?.unshift(AV.AVEntity.getLink(org));
                user.orgs.unshift(AV.AVEntity.getLink(org)); dispatch(setUser(user));
                entitydatalist.push(org); dispatch(setEntitydata(entitydatalist));
            }
            if ( org && Season )
            {
                org?.childOrgs?.push( AV.AVEntity.getLink( Season ) )
                 entitydatalist.push( Season ); dispatch( setEntitydata( entitydatalist ) );
                user?.autoFollows?.unshift( AV.AVEntity.getLink( Season ) );
                user.orgs.unshift( AV.AVEntity.getLink( Season ) );
                dispatch( setUser( user ) );
                //setEntity(team);
            }      
            if ( !user.orgs?.find( ( x : any ) => x?.id == org?.id ) && org?.id )
            {
                if ( org )
                {
                    entitydatalist.push( org ); dispatch( setEntitydata( entitydatalist ) );
                    user?.autoFollows?.unshift( AV.AVEntity.getLink( org ) );
                    user.orgs.unshift( org )
                    dispatch( setUser( user ) );
                    //setEntity(org);
                    //EntityDataFinding(org);
                }
            }
            setShowCreateNewPage( false );
            if ( orgFK )
            {
                setPassEntity( '' );
            }
          navigate(`/teams/${ org?.id }/${ org?.region }`, { state: { isCreate: false, team: org, newEntity: true } });

        //    navigate(`/organizations/${ org?.id }/${ org?.region }/${ org?.type }`);
            //setIsCreate(false);
            setIsLoading( false );
            
        }
        else {
            //Update Club/School
            setIsLoading(true);
            var org = Entity;
            org.name = name;
            org.profile = profile ? profile : profile;
            org.mascot = org.mascot?.id ? org.mascot : mascotImage?.id ? mascotImage : undefined;
            org.location = locationoffield ? locationoffield : null;
            org.links = linkList;
            dispatch( setisActive( true ) );
            org = await AV.AVEntity.save( org );
            if (org) {
                AV.AVEntity.checkLinks(user, org).then((x) => {
                    dispatch(setUser(x));
                    AV.Person.checkIfSelf(x);
                });
                let oldvalue = user.orgs.filter((a) => a.id == org.id);
                user.orgs[ user.orgs.indexOf(oldvalue[ 0 ]) ] = org;
                dispatch(setUser(user));
            }
            const loc : any = await AV.Media.getMap(locationaddress);
            setgooglemap(loc);
            await AV.Person.getSelf(true).then((x) => {
                dispatch(setUser(x));
            });
            setIsShowEdit(false);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const CheckReturnValue = ( returnvalue : any ) =>
    {
        let oldorg = user.orgs?.find( ( x : any ) => x?.id == returnvalue?.id );
        if ( oldorg || ( returnvalue?.creator?.id !== user?.id ) )
        {
          let clientError = { message: Loc.currentLang.menu.orgAlreadyExists }
          CustomToast({ clientError });
          //  setError();
            
        }
    };

    const handlePreferenceChange = ( value ) =>
    {
        let updatedPreferences = {
            autoapprove: false,
            allowfollwerstopost: false,
            autoreplytopost: false,
            allowalltopost: false,
            allowmemberstopost: false
        };

        switch ( value )
        {
            case 'Normal Group':
                updatedPreferences = {
                    ...updatedPreferences,
                    allowmemberstopost: true
                };
                break;
            case 'Read Only Group':
                // 'updatedPreferences' remains unchanged
                break;
            case 'Public Group':
                updatedPreferences = {
                    ...updatedPreferences,
                    autoreplytopost: true,
                    allowmemberstopost: true
                };
                break;
            default:
                break;
        }

        setGroupType( {
            ...GroupType,
            ...updatedPreferences,
            selectedPreference: value  // Update selected preference
        } );
    };

    const handleUpdateEvent = async () =>
    {
        const isValidLink = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
        if ( url ) 
       { 
        if ( !isValidLink?.test( url ) )
        {
            let clientError = { message: 'Invalid link format.' }
            CustomToast( { clientError } ); // Display error message using CustomToast
            return; // Exit the function early if the link format is invalid
        }
        }
      if (eventStartDate < DefaultEventDate || eventEndDate < DefaultEventDate) {
        let clientError = { message: Loc.currentLang.menu.invalidDate }
        CustomToast({ clientError });
        return;
      }
      if (eventStartDate > eventEndDate || eventEndDate < eventStartDate) {
        let clientError = { message: Loc.currentLang.menu.invalidDate }
        CustomToast({ clientError });
        return;
      }

      //Time condtion starts
      if (eventStartDate === eventEndDate && eventStartDate === DefaultEventDate) {
        const currentDateTimes = new Date();
        const eventStartTimeOnly = Moment(eventStartTime).format('HH:mm:ss');
        const eventStartDateTimes = eventStartTime;
        if (eventStartDateTimes < currentDateTimes) {
          const clientError = { message: Loc.currentLang.menu.invalidStartTime };
          CustomToast({ clientError });
          return;
        }

        // Check if event end date is in the past
        const eventEndTimeOnly = Moment(eventEndTime).format('HH:mm:ss');
        const eventEndDateTimes = eventEndTime;
        if (eventEndDateTimes < currentDateTimes) {
          const clientError = { message: Loc.currentLang.menu.invalidEndTime };
          CustomToast({ clientError });
          return;
        }


        // Check if event start time is after event end time
        if (eventStartTimeOnly > eventEndTimeOnly) {
          const clientError = { message: Loc.currentLang.menu.startTimeAfterEnd };
          CustomToast({ clientError });
          return;
        }

        if (eventStartTimeOnly === eventEndTimeOnly) {
          const clientError = {
            message:
              Loc.currentLang.menu.sameStartAndEndTime
          };
          CustomToast({ clientError });
          return;
        }

        if (Entity?.eventType == 'Game') {
          if (Player >= editEventEndingTime) {
            const clientError = {
              message: Loc.currentLang.menu.invalidTeamArrival
            };
            CustomToast({ clientError });
            return;
          }
        }

      }

      if (eventStartDate === DefaultEventDate && eventEndDate > eventStartDate) {
        const currentDateTimes = new Date();
        const eventStartDateTimes = eventStartTime;
        if (eventStartDateTimes < currentDateTimes) {
          const clientError = { message: Loc.currentLang.menu.startInThePast };
          CustomToast({ clientError });
          return;
        }
      }

      if (eventStartDate === eventEndDate && eventStartTime > eventEndTime) {
        const clientError = {
          message: "Invalid start time. Event cannot start after the end time."
        };
        CustomToast({ clientError });
        return;
      }

      if (Entity?.eventType == 'Game') {
        if (eventTeamArrivalDate != '' && (eventTeamArrivalDate > eventStartDate || eventTeamArrivalDate < eventStartDate)) {
          const clientError = {
            message: "Team arrival date must be same as event start date"
          };
          CustomToast({ clientError });
          return;
        }
        const currentDateTime = CurrentDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        if (Playerstart > eventStartTime) {
          const clientError = {
            message: "Team arrival time should before the start time"
          };
          CustomToast({ clientError });
          return;
        }
        if (Playerstart !== '' && Playerstart <= currentDateTime) {
          const clientError = {
            message: "Team arrival time should not in the past"
          };
          CustomToast({ clientError });
          return;
        }
      }


        if ( eventStartDate === DefaultEventDate && eventEndDate > eventStartDate )
        {
            const currentDateTimes = new Date();
            const eventStartTimeOnly = Moment( eventStartTime ).format( 'HH:mm:ss' );
            const eventStartDateTimes = Moment( eventStartDate + ' ' + eventStartTimeOnly ).toDate();
            if ( eventStartDateTimes < currentDateTimes )
            {
                const clientError = { message: Loc.currentLang.menu.StartInThePast };
                CustomToast( { clientError } );
                return;
            }
        }

        const playerNew = moment( Player, 'h:mm A' ).format( 'h:mm A' );
        if ( Entity?.eventType == 'Game' && playerNew !== "Invalid date" && playerNew !== null && playerNew !== "")
        {
            if ( playerNew >= editEventEndingTime )
            {
                const clientError = {
                    message:
                        Loc.currentLang.menu.invalidTeamArrival
                };
                CustomToast( { clientError } );
                return;
            }
        }
        setIsLoading( true );
        setError('')
        const formattedTeam = moment( Player, 'h:mm A' ).format( 'HH:mm' );
        var eventStartDateTime = Moment( eventStartDate + ' ' + editEventStartingTime ).toDate();
        var eventEndDateTime = Moment( eventEndDate + ' ' + editEventEndingTime ).toDate();
        var teamarival = Moment( eventTeamArrivalDate + ' ' + formattedTeam ).toDate();
        Entity.name = name;
      //   Entity.location = locationoffield?.id ? locationoffield :null;
      Entity.profile = profile;
        Entity.start = eventStartDateTime;
        Entity.end = eventEndDateTime;
        Entity.locURL = url;
        Entity.location = addLink ? null : locationoffield;
        Entity.links = linkList;

        console.log( Links );
        Entity.playerStart = teamarival;
        dispatch( setisActive( true ) );
        let val = await AV.AVEntity.save( Entity );
        if (val) {
            setEntity(val);
            let oldvalue = currentUser.events.filter(a => a.id == val?.id);
            if (oldvalue[ 0 ]?.status) val.status = oldvalue[ 0 ]?.status
            else val.status = 'RSVP';
            currentUser.events[ currentUser.events.indexOf(oldvalue[ 0 ]) ] = val
            if (currentUser)
                dispatch(setUser(currentUser));
            GetWeatherIcon(val);
            AV.AVEntity.checkLinks(currentUser, val);
        }
        const loc = await AV.Media.getMap(locationaddress);
        setgooglemap(loc);
        setIsShowEdit(false);
        setIsLoading(false);
    };

    React.useEffect( () =>
    {
        setRefreshTimeKey( prevKey => prevKey + 1 );
    }, [ triggerEndDate ]);

  React.useEffect(() => {
    setProfile(Entity?.profile);
  }, [ Entity?.profile ]);

    useEffect(() => {
        setRefreshKey(prevKey => prevKey + 1);
    }, [ triggerStartTime ]);
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let gendertype = Loc.currentLang.gender;
            setName('');
            setDescription('');
            setActivity('');
            setSeasonName('');
            setGender('');
            setLinks('');
            setlocationaddress([]);
            setEventLocation('');
            if (gendertype) {
                const objectArray: any = Object.entries(gendertype).map(([key, value]) => ({ key, value }));
                setgendertypelist(objectArray);
                setGender(objectArray[2].value);
            }
            let activitytype = Loc.currentLang.activity;
            if (activitytype) {
                const objectToArray = Object.entries(activitytype).map(([key, value]) => ({ key, value }));
                setactivitytypelist(objectToArray);
            }
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };
    }, [isCreate, type])
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setName( Entity?.name ? Entity?.name : '' )
            setDescription( Entity?.profile?.text ? Entity?.profile?.text : '' )
            setActivity( Entity?.activity ? Entity?.activity : '' )
            setSeasonName( Entity?.season ? Entity?.season : '' )
            setGender( Entity?.gender ? Entity?.gender : 'Any' )
            setLinks( Entity?.links ? Entity?.links : '' );
            setLinksList( Entity?.links ? Entity?.links : [] );
            setEventStartDate( Entity?.start ? Moment( Entity?.start ).format( 'YYYY-MM-DD' ) : '' );
            setEventEndDate( Entity?.end ? Moment( Entity?.end ).format( 'YYYY-MM-DD' ) : '' )
            setEventStartTime( Entity?.start ? Entity?.start : '' );
            setEventEndTime( Entity?.end ? Entity?.end : '' );
            SetStartingTime( Entity?.start ? moment( Entity?.start ).format( 'hh:mm A' ) : '' )
            setPlayer( Entity?.playerStart ? moment( Entity?.playerStart ).format( 'hh:mm A' ) : '' )
            setEndingTime( Entity?.end ? moment( Entity?.end ).format( 'hh:mm A' ) : '' )
            setlocationaddress( Entity?.location ? Entity?.location : '' );
            setEventLocation( Entity?.location?.normalized ? Entity?.location?.normalized : '' )
            setIsFieldsName( Entity?.location?.field );
            setFieldValue( Entity?.location?.field?.name ? Entity?.location?.field?.name : Loc.currentLang.menu.fields )
            setIsMapField( Entity?.location?.field?.name ? Entity?.location?.field?.name : '' )
            setUrl( Entity?.locURL ? Entity?.locURL : '' )
            setAddLink( Entity?.locURL ? true : false );

        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };
    }, [ isShowEdit ])

    return (
        <>
            <VStack width={ '100%' } top={ Entity?.type === 'Event' ? '93px' : '24px' }
                borderRadius={ '10px' } zIndex={ -3 } height={ 'auto' }
                marginBottom={ Entity?.type === 'Event' ? '120px' : '40px' }>
            {/*Create and Cancel Button*/}
                <HStack justifyContent={ isMobile ? 'flex-end' : 'flex-end' } space={ '12px' } marginLeft={ isMobile ? '12px' : '' } marginRight={ isMobile ? '12px' : '24px' } width={ 'auto' } zIndex={-3}>
                    <Button
                        background={ '#EEEEEE' }
                        width={ isMobile ? '100px' : '120px' }
                        height={ '40px' }
                        rounded='full'
                        onPress={ () =>
                        {
                            isMobile
                                ? navigate( -1 )
                                : ( isShowEdit ? setIsShowEdit( false ) : setShowCreateNewPage( false ) );
                        } }
                    >
                        <Text color={ 'rgba(0, 0, 0, 0.50)' } fontWeight={ 500 }>{ Loc.currentLang.menu.cancel }</Text>
                    </Button>
                    {Entity?.type == 'Event' && (<Button
                        background={'#EEEEEE'}
                        width={isMobile ? '100px' : '120px'}
                        height={'40px'}
                        rounded='full'
                        isLoading={deleteLoader}
                        onPress={handleDeleteEvent }
                    >
                        <Text color={ 'rgba(0, 0, 0, 0.50)' } fontWeight={ 500 }>{ Loc.currentLang.alertType.deleteEvent}</Text>
                    </Button>)}
                    <Button
                        bgColor={'#32AE78'}
                        width={isMobile ? '100px':'120px'}
                        height={'40px'}
                        rounded='full'
                        isLoading={isLoading}
              isDisabled={ type === 'Team' && Entity?.subType !== 'Club' && !isShowEdit ? !name || !seasonName : type === 'Team' && Entity?.subType !== 'Club' && isShowEdit ? !name : type === 'Club' && !isShowEdit ? !name || !seasonName : Entity?.subType == 'Club' && isShowEdit ? !name : (Entity?.type === 'Club' || type === 'EliteClub') ? !name : type === 'School' ? !name : type === 'Class' ? !name : type === 'Group' ? !name : type === 'Organization' ? !name : type === 'GradClass' ? !name : type === 'Neighborhood' ? !name : Entity?.type == 'Event' ?
                // Provided condition for 'Event' type.
                (!addLink ? (!name || !eventLocation || !isValidateDate || !isValidateEndDate || !isValidStartTime || !isValidEndTime) :
                  (addLink ? (!name || url?.trim()?.length === 0) :
                    (!isValidateDate || !isValidateEndDate || !isValidStartTime || !isValidEndTime))) :  type === 'Community' ? !name  : undefined}
                        onPress={ ( ( type === 'Team' && Entity?.subType !== 'Club') || type === 'LeagueTeam') ? handleSaveTeam : Entity?.type == 'Event' ? handleUpdateEvent : handleSaveOrg }
            >
                        <Text color={ '#ffffff' } fontWeight={ 500 }>{ isShowEdit ? Loc.currentLang.menu.save : Loc.currentLang.menu.create}</Text>
                    </Button>
                </HStack>
                {/*Help Text*/}
                { Entity?.type !== 'Event' && !isShowEdit && ( <HStack marginLeft={ '24px' } marginRight={ '24px' } zIndex={ -3 } marginY={'30px'} width={'auto'} space={3}>
                    <HStack>
                        <HelpIcon height={'24'} width={'24'} color={'black'} />
                    </HStack>
                    <HStack width={'95%'}>
                        {( type === 'Team' || teamValue == 'Team') && (<Text>{Loc.currentLang.helpText.createNew.team}</Text>)}
                        { ( type === 'Club' || teamValue == 'Club') && (<Text>{Loc.currentLang.helpText.createNew.club}</Text>)}
                        { ( type === 'EliteClub' || teamValue == 'EliteClub')  && ( <Text>{ Loc.currentLang.helpText.createNew.eliteClub}</Text>)}
                        { ( type === 'School' || teamValue == 'School')  && (<Text>{Loc.currentLang.helpText.createNew.school}</Text>)}
                        { ( type === 'Group' || teamValue == 'Group')  && (<Text>{Loc.currentLang.helpText.createNew.group}</Text>)}
                        { ( type === 'Class' || teamValue == 'Class')  && (<Text>{Loc.currentLang.helpText.createNew.class}</Text>)}
                        { ( type === 'GradClass' || teamValue == 'GradClass')  && (<Text>{Loc.currentLang.helpText.createNew.gradClass}</Text>)}
                        { ( type === 'Organization' || teamValue == 'Organization')  && (<Text>{Loc.currentLang.helpText.createNew.organization}</Text>)}
                        { ( type === 'Neighborhood' || teamValue == 'Neighborhood')  && (<Text>{ Loc.currentLang.helpText.createNew.neighborHood }</Text>)}
                        { ( type === 'Community' || teamValue == 'Community')  && (<Text>{Loc.currentLang.helpText.createNew.community}</Text>)}
                    </HStack>

                </HStack>)}
                <VStack marginX={ '24px' } space={ '34px' } marginTop={ ( Entity?.type == 'Event' || isShowEdit ) ? '24px' : '' } zIndex={-3 }>
                {/*Team Name input*/}
                    <HStack width={ '100%' } space={ '4%' } flexWrap={ 'wrap' }>
                        <Box width={ ( isCreate && !isMobile && ( type === 'Team' || type === 'Club' ) && Entity?.subType == undefined || isShowEdit && type === 'LeagueTeam' && Entity?.subType !== 'Class' ) ? '48%' : isCreate && isMobile ? '100%' : '100%' } marginBottom={ isMobile ? '24px' : '' }>
                        <AVInputtext
                            type={'text'}
                                label={ Loc.currentLang.menu.nameReq}
                            maxLength={50}
                            onChange={HandleName}
                            defaultValue={undefined}
                            width={undefined} value={name}
                            height={undefined}
                            minRows={undefined}
                            multiline={undefined}
                  size={ undefined }
                  placeholder={ undefined }
                        />
                        </Box>
                        {/*Season Input*/}
                        { ( isCreate && ( type === 'Team' || type === 'Club' ) && Entity?.subType == undefined || isShowEdit && type === 'LeagueTeam' && Entity?.subType !=='Class' ) && ( <Box width={ isCreate && isMobile ? '100%' : '48%' }>		
                            <AVInputtext
                                type={'text'}
                                label={ ( type === 'LeagueTeam' || teamValue == 'Team' ) && Entity?.subType !== 'Term' ? Loc.currentLang.menu.seasonReq : Loc.currentLang.menu.termName}
                                maxLength={50}
                                onChange={HandleSeasonName}
                                defaultValue={undefined}
                                width={undefined} value={seasonName}
                                height={undefined}
                                minRows={undefined}
                                multiline={undefined}
                  size={ undefined }
                  placeholder={ undefined }
                            />
                        </Box>)}
                    </HStack>
                    {Entity?.type == 'Event' && (<HStack space={2} width={'100%'}>
                        <Box flex={1}>
                            <Box
                                width={'100%'}
                                marginTop={'-8px'}
                            >
                                <AVDatePicker
                                    key={`end-date-picker-${refreshKey}`}
                                    label={ Entity?.eventType === 'Meeting' || Entity?.eventType === 'Meeting' ? "Reoccurance start date *" : Loc.currentLang.menu.startDate }
                                    onChange={handleEventStartDate}
                                    defaultValue={dayjs(eventStartDate)}
                                />
                            </Box>
                        </Box>
                        <Box flex={1}>
                            <Box
                                width={'100%'}
                                marginTop={'-8px'}
                            >

                                <AVTimePicker
                                    key={ `start-time-picker-${ refreshTimeKey }` }
                                    label={Loc.currentLang.menu.startTime}
                                    onChange={handleEventStartTime}
                                    defaultValue={dayjs(eventStartTime)}
                                />
                            </Box>

                        </Box>
                    </HStack> ) }
                    { Entity?.type == 'Event' && Entity?.eventType == 'Game' &&(<HStack>
                        { ( Entity?.type == 'Event' ) &&
                            < Box
                                width={ '100%' }
                                marginTop={ '-8px' }
                            >
                                <AVTimePicker
                                    label={ Loc.currentLang.menu.teamArivalTime }
                                    onChange={ handleEventTeamArival }
                                    defaultValue={ Playerstart? dayjs( Playerstart ) : null }
                                />
                            </Box> }
                    </HStack> ) }
                    { Entity?.type == 'Event' && Entity?.eventType == 'Game'  &&( <HStack>
                        { (Entity?.type == 'Event' ) &&
                            <Box width={ '100%' }>
                                <AVDatePicker
                                    label={ Loc.currentLang.menu.teamArrivalDate }
                                    onChange={ handleEventTeamArrival }
                                    defaultValue={ eventTeamArrivalDate ? dayjs( eventTeamArrivalDate ) : null }
                                />
                            </Box> }
                    </HStack> ) }
                    {Entity?.type == 'Event' && (<HStack space={2} width={'100%'}>
                        <Box flex={1}>
                            <Box
                                width={'100%'}
                                marginTop={'-8px'}
                            >
                                <AVDatePicker
                                    key={`end-date-picker-${refreshKey}`}
                                    label={ Entity?.eventType === 'Meeting' || Entity?.eventType === 'Meeting' ? "Reoccurance end date *" : Loc.currentLang.menu.endDate }
                                    onChange={handleEventEndDate}
                                    defaultValue={dayjs(eventEndDate)}
                                />
                            </Box>
                        </Box>
                        <Box flex={1}>
                            <Box
                                width={'100%'}
                                marginTop={'-8px'}
                            >
                                <AVTimePicker
                                    key={ `start-time-picker-${ refreshTimeKey }` }
                                    label={Loc.currentLang.menu.endTime}
                                    onChange={handleEventEndTime}
                                    defaultValue={dayjs(eventEndTime)}
                                />
                            </Box>
                        </Box>
                    </HStack> ) }


                   
                {/*Type and Gender*/}
                    { ( type === 'Team' && Entity?.subType == undefined || type === 'LeagueTeam' && Entity?.subType !== 'Class' && Entity?.subType !== 'Term' ) && ( <HStack width={ '100%' } flexWrap={ "wrap" } space={ '4%' } zIndex={addLinkPopup ? '': 999}  >
                        { type === 'Team' && ( <HStack zIndex={ addLinkPopup ? '' : 999 } width={ isMobile && isCreate ? '100%' : '48%' } marginBottom={ isMobile ? '24px' : '' } space={ 3 }>
                            {/*Activity type*/ }
                            <AVDropdown
                                defaultValue={
                                    activity ? { value: activity, label: activity } : null
                                }
                                value={ activity ? { value: activity, label: activity } : null }
                                options={ activitytypelist?.map( ( option ) => ( {
                                    value: option?.value,
                                    label: option?.value,
                                } ) ) }
                                isSearchable={ true }
                                noOptionsMessage={ () => null }
                                components={ { dropdownIndicator } }
                                onChange={ HandleActivity }
                            />
                        </HStack> ) }
                        { ( type === 'Team' && Entity?.subType == undefined || type === 'LeagueTeam' ) && ( <HStack width={ type === 'LeagueTeam' || type === 'Team' && isMobile && isCreate ? '100%' : '48%' } zIndex={ -3 } top={ isMobile && isCreate ? '10px' : '' } >
                            <FormControl fullWidth>
                                <InputLabel id='demo-simple-select-label'>
                                    { Loc.currentLang.menu.gender}
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={gender}
                                    label={ Loc.currentLang.menu.gender}
                                    onChange={HandleGender}
                                >
                                    {gendertypelist?.map((option) => (
                                        <MenuItem key={option?.value} value={option?.value}>
                                            {option?.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </HStack>)}
                    </HStack>)}
                    {/*custom optional input*/ }
                    <HStack zIndex={-3}>
                        <Box width={'100%'}>
                            <AVInputtext
                                type={'text'}
                                minRows='5'
                                size='medium'
                                multiline={true}
                                maxLength={500}
                                onChange={handleIntroText}
                                defaultValue={undefined}
                                width={undefined} value={description}
                                height={'60px'}
                                label={ Loc.currentLang.placeholderText.personalDescription } 
                  placeholder={ undefined }
                            />
                        </Box>
                    </HStack>

                    { Entity?.type === 'Event' && (
                        /*CheckBox*/
                        <Box>
                            <Stack
                                direction={ {
                                    base: 'row',
                                    md: 'row',
                                } }
                                space={ '35px' }
                                alignItems='flex-start'
                                zIndex={ -2 }
                            >
                                <Checkbox
                                    size='sm'
                                    zIndex={ -2 }
                                    isChecked={ addLink }
                                    onChange={ () =>
                                    {
                                        setAddLink( !addLink ); setEventLocation( '' );
                                        setlocationaddress( [] );
                                        setlocationaddress( [] );
                                        setUrl('')
                                    } }

                                    value={ '' }
                                >
                                    { Loc.currentLang.menu.onlineEvent }
                                </Checkbox>
                            </Stack>
                        </Box>
                    ) }

                    {/*UrlInput*/ }
                    { addLink && (
                        <Box width={ '100%' }>
                            <AVInputtext
                                type={ 'text' }
                                label={ Loc.currentLang.menu.eventUrl }
                                onChange={ handleEventUrl }
                                value={ url }
                                maxLength={ 500 }
                                defaultValue={ undefined }
                                width={ undefined }
                                height={ undefined }
                                minRows={ undefined }
                                multiline={ undefined }
                                size={ undefined }
                                placeholder={ undefined } />
                        </Box>
                    ) }

                    { ( addLink && !isValidUrl ) && (
                        <View top='-8'>
                            <Text pt="3" bold>
                                Example: http://www.example.com or http://example.com or https://example.com
                            </Text>
                        </View>
                    ) }
               
                    {/*Location Box*/}
                    { ( addLink ) || ( ( type !== 'Team' || Entity?.subType == 'Term' ) && type !== 'LeagueTeam' && type !== 'Group' && type !== 'Club' && type !== 'Class' && type !== 'GradClass' && Entity?.subType != 'Club' ) && ( <VStack space={'24px'}>
                        <AVLocationBox locationaddress={locationaddress} setlocationaddress={setlocationaddress} setSiteField={undefined} isCreate={isCreate} setEventLocation={setEventLocation} eventLocation={eventLocation} isShowEdit={isShowEdit} Entity={Entity} handleupdatelocationfield={handleupdatelocationfield} isGame={undefined} isPractice={undefined} setLocationEntity={setLocationEntity} locationEntity={locationEntity} mapField={mapField} setIsMapField={setIsMapField} isCreateevent={undefined} />
                    </VStack> ) }
                   
                    {((type == 'Group' || type == 'Organization')&& !isShowEdit) &&
                        ( <VStack zIndex={ -2 }>
                        {((type == 'Organization')&& !isShowEdit) && (<VStack>
                            <HStack
                                marginTop={ '20px' }
                                width={ isMobile ? '91%' : '70%' }
                                marginLeft={ isMobile ? '26px' : '45px' }
                                space={ '70px' }
                                zIndex={ -2 }
                            >
                                <Text
                                    width={ isMobile ? '41%' : '33%' }
                                    textAlign={ 'left' }
                                    fontSize={ '18px' }
                                    numberOfLines={ 2 }
                                    ellipsizeMode='tail'
                                >
                                    { Loc.currentLang.menu.autoApprove }
                                </Text>
                                <Radio.Group
                                    name='exampleGroup'
                                    defaultValue={ Prefernces?.autoFollow ? 'true' : 'false' }
                                    accessibilityLabel='pick a size'
                                    onChange={ () =>
                                        setPrefernces((prefernces ) =>
                                        {
                                            return {
                                                ...prefernces,
                                                autoFollow: Prefernces?.autoFollow ? false : true,
                                            };
                                        } )
                                    }
                                >
                                    <Stack
                                        direction={ {
                                            base: 'column',
                                            md: 'row',
                                        } }
                                        alignItems={ {
                                            base: 'flex-start',
                                            md: 'center',
                                        } }
                                        space={ '65px' }
                                        w='75%'
                                        maxW='300px'
                                    >
                                        <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                            { Loc.currentLang.menu.yes }
                                        </Radio>
                                        <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                            { Loc.currentLang.menu.no }
                                        </Radio>
                                    </Stack>
                                </Radio.Group>
                            </HStack>
                            <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                            <HStack
                                marginTop={ '20px' }
                                width={ isMobile ? '91%' : '70%' }
                                marginLeft={ isMobile ? '26px' : '45px' }
                                space={ '70px' }
                                zIndex={ -2 }
                            >
                                <Text
                                    width={ isMobile ? '41%' : '33%' }
                                    textAlign={ 'left' }
                                    fontSize={ '18px' }
                                    numberOfLines={ 2 }
                                    ellipsizeMode='tail'
                                >
                                    { Loc.currentLang.menu.allowFollowers }
                                </Text>
                                <Radio.Group
                                    name='exampleGroup'
                                    defaultValue={ Prefernces?.allowFollowersPosts ? 'true' : 'false' }
                                    accessibilityLabel='pick a size'
                                    onChange={ () =>
                                        setPrefernces( ( prefernces ) =>
                                        {
                                            return {
                                                ...prefernces,
                                                allowFollowersPosts: Prefernces?.allowFollowersPosts ? false : true,
                                            };
                                        } )
                                    }
                                >
                                    <Stack
                                        direction={ {
                                            base: 'column',
                                            md: 'row',
                                        } }
                                        alignItems={ {
                                            base: 'flex-start',
                                        } }
                                        space={ '65px' }
                                        w='75%'
                                        maxW='300px'
                                    >
                                        <Radio value={ 'true' } size='sm' my={ 1 } fontSize={ '18px' }>
                                            { Loc.currentLang.menu.yes }
                                        </Radio>
                                        <Radio value={ 'false' } size='sm' my={ 1 } fontSize={ '18px' }>
                                            { Loc.currentLang.menu.no }
                                        </Radio>
                                    </Stack>
                                </Radio.Group>
                            </HStack>
                            <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                        </VStack> ) }
                        {(type !== 'Organization' && type == 'Group' && !isShowEdit ) && ( <VStack>
                    <HStack
                        marginTop={ '20px' }
                        width={ isMobile ? '91%' : '70%' }
                        marginLeft={ isMobile ? '26px' : '45px' }
                        space={ '70px' }
                        zIndex={ -2 }
                        >
                                <Radio.Group
                                    name='exampleGroup'
                                    defaultValue={ GroupType.selectedPreference }
                                    accessibilityLabel='pick a size'
                                    onChange={ ( value ) => handlePreferenceChange( value ) }
                                >
                                    <Stack
                                        direction={ {
                                            base: 'column',
                                            md: 'row',
                                        } }
                                        alignItems={ {
                                            base: 'flex-start',
                                        } }
                                        space={ '65px' }
                                        w='75%'
                                        maxW='300px'
                                    >
                                        <Radio value='Normal Group' size='sm' my={ 1 } fontSize={ '18px' }>
                                            { Loc.currentLang.menu.normalGroup }
                                        </Radio>
                                        <Radio value='Read Only Group' size='sm' my={ 1 } fontSize={ '18px' }>
                                            { Loc.currentLang.menu.readOnlyGroup }
                                        </Radio>
                                        <Radio value='Public Group' size='sm' my={ 1 } fontSize={ '18px' }>
                                            { Loc.currentLang.menu.publicGroup }
                                        </Radio>
                                    </Stack>
                                </Radio.Group>
                    </HStack>
                    <Divider marginY={ '20px' } marginLeft={ '28px' } width={ isMobile ? '85%' : '93%' } zIndex={ -1 } />
                        </VStack>)}
                        </VStack> ) }
                    {/*Team Url*/ }
                    <Pressable
                        width={ '100%' }
                        zIndex={-3}
                        onPress={ () => { setAddLinkPopup( true ) } }
                    >
                        <HStack>
                            <Text color={ '#00A7BC' } fontWeight={ 500 }>+</Text>
                            <Text color={ '#00A7BC' } fontWeight={ 500 } >
                                { Loc.currentLang.menu.addLinks }</Text>
                        </HStack>
                    </Pressable>
                    { addLinkPopup && ( <AddandEditLinks setAddLinkPopup={ setAddLinkPopup } setLinksList={ setLinksList } linkList={ linkList } isCreateevent={undefined} /> ) }
                    {/*Color picker*/}
                    { ( ( isShowEdit && Entity?.subType !== 'Term' && type === 'Team' && Entity?.subType == undefined ) || type === 'LeagueTeam' ) && Entity?.subType !== "Class" && Entity?.subType !== 'Term' &&( <HStack space={ '15%' } zIndex={ -3 } flexWrap={ isMobile ? 'wrap' : null}  >
                        <HStack space={ '15px' } zIndex={ isPrimary ? -3 : '' } marginBottom={ isMobile ? '10px':'' }>
                            <Button
                                backgroundColor={primaryColor}
                                height={'30px'}
                                width={'30px'}
                                rounded={'full'}
                                onPress={() => setIsPrimary(!isPrimary)}
                            >
                                <ColorPickerIcon height={'15'} width={'15'} color={'white'} />
                            </Button>
                            <Text color={ '#808080' } fontSize={ '16px' } fontWeight={ 400 }>{ Loc.currentLang.menu.primaryColors }</Text>
                        </HStack>

                        <HStack space={'15px'} zIndex={isPrimary ? -3 : ''}>
                            <Button
                                backgroundColor={secondaryColor}
                                height={'30px'}
                                width={'30px'}
                                rounded={'full'}
                                onPress={() => setIsSecondary(!isSecondary)}
                            >
                                <ColorPickerIcon height={'15'} width={'15'} color={'white'} />
                            </Button>
                            <Text color={ '#808080' } fontSize={ '16px' } fontWeight={ 400 }>{ Loc.currentLang.menu.secondryColors}</Text>
                        </HStack>
                    </HStack>)}
                    {(isPrimary || isSecondary) && (<Box zIndex={999} left={isSecondary ? '35%':''} bottom={'15px'}>
                        <GithubPicker
                            color={primaryColor}
                            //  triangle='right'
                            width={'400px'}
                            onChangeComplete={handleBannerColor}
                        />
                    </Box>)}
                    {/*Mascot*/}
                    { isShowEdit && Entity?.subType !== 'Term' &&  Entity?.type !== 'Event' && Entity?.subType !== "Class" && Entity?.subType !== "Club" && (Entity?.type == 'Team' || Entity?.type == 'LeagueTeam') && ( <VStack zIndex={-3}>
                        <AVMascot Entity={Entity} setEntity={setEntity} mascotImage={mascotImage} setMascotImage={setMascotImage} isShowEdit={isShowEdit} />
                    </VStack> ) }
                   
                </VStack>
            </VStack>
            { isConfirmDelete &&
                /*Confirmation popup*/
                <AVConfirmationModal showconfirmationPopup={ isConfirmDelete } setshowconfirmationPopup={ setisConfirmDelete }
                Confirmationtext={ Confirmationtext } type={ selectType } item={ itemSelect } function={ DeleteAccount } isLoader={ isLoader } /> }
        </>
    )
}

export default AVCreateTeam;