import React, { useState, useEffect, useRef } from 'react';
import { VStack, Text, Pressable, Image, HStack, Button, Tooltip } from 'native-base';
import { Dimensions } from 'react-native';
import Moment from 'moment';
import { Loc } from '../../App/Loc';
import timer from '../../assets/images/clock.svg';
import { TimeClock, TimeIcon } from '@mui/x-date-pickers';
import { LocationIcon, TimerIcon } from '../../assets/icons/icon-assets';
import * as AV from '../../AVCore/AVCore';
import { useNavigate } from 'react-router-dom';
import { useSelector, RootStateOrAny } from 'react-redux';
import Offline from "react-offline";
import AVDescription from './AVDescription'



const AVHeaderTitle = ( { Entity, EntityType, googlemap, weatherURL, weather, setIsImagePopup }) => {
    console.log("avheadertitle")
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions.width <= 600 || false;
    const [ fieldname, setFieldName ] = useState( '' )
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const navigate = useNavigate();
    const menuRef = useRef( null );
    useOutsideAlerter( menuRef );

    const menuRefs = useRef( null );
    useOutsideAlerter( menuRefs );
    
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            // Alert if clicked on outside of element

            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                  
                }
            }
            // Bind the event listener
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                // Unbind the event listener on clean up
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setFieldName(Entity?.location?.field?.name)
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };

    }, [Entity])

    const TeamTitle = ({ Entity }) => {
        return (
            <>
                <VStack height={'auto'} width={'90%'} left={isMobile ? '24px' : '48px'}>
                    <VStack>
                        <Text fontSize={'36px'} numberOfLines={1} width={'65%'}>{Entity?.name}</Text>
                        <Text fontSize={ '18px' } fontWeight={ 500 } numberOfLines={ 1 } width={ '65%' }><AVDescription text={ Entity?.profile?.text } /></Text>
                        <HStack space={1}>
                            { Entity?.links && Entity?.links?.map( ( item, index ) => (
                                <Pressable
                                    key={ index } // make sure to provide a unique key for each element in a list
                                    onPress={ () => window.open( item?.url ) }
                                    marginTop={ '12px' }>
                                    <img src={ item?.icon?.trim()?.length > 0 ? item?.icon : "https://cdn-icons-png.flaticon.com/512/2985/2985013.png" } alt={ `Icon ${ index }` } height={ '24px' } width={ '24px' } />
                                </Pressable>

                            ) ) }
                        </HStack>
                        {Entity?.parent && ( <Pressable
                            onPress={ () =>
                            {
                                Entity?.parent?.type == 'LeagueTeam'
                                    ? navigate( `/season/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } )
                                    : Entity?.parent?.type == 'Team' ? navigate( `/teams/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } ) :
                                        navigate( `/season/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } );
                            } }
                            marginTop={ '12px' }>
                            <Text fontSize={ '16px' } fontWeight={ 400 } color='#00A7BC' underline>{Entity?.parent?.title}</Text>
                        </Pressable> ) }
                    </VStack>
                </VStack>
            </>
        )
    }

    const SeasonTitle = ({ Entity }) => {
        return (
            <>
                <VStack height={'auto'} width={'100%'} left={isMobile ? '24px' : '48px'}>
                    <VStack>
                        <Text fontSize={'24px'} numberOfLines={1} width={'65%'}>{Entity?.name}</Text>
                        <Text fontSize={'36px'} numberOfLines={1} width={'65%'}>{Entity?.season}</Text>
                        <Text fontSize={ '18px' } fontWeight={ 500 } numberOfLines={ 1 } width={ '65%' }><AVDescription text={ Entity?.profile?.text } /></Text>
                        <HStack space={ 1 }>
                            {Entity?.links && Entity?.links?.map( ( item, index ) => (
                                <Pressable
                                    key={ index } // make sure to provide a unique key for each element in a list
                                    onPress={ () => window.open( item?.url ) }
                                    marginTop={ '12px' }>
                                    <img src={ item?.icon?.trim()?.length > 0 ? item?.icon : "https://cdn-icons-png.flaticon.com/512/2985/2985013.png" } alt={ `Icon ${ index }` } height={ '24px' } width={ '24px' } />
                                </Pressable>

                            ) ) }
                        </HStack>
                        { Entity?.parent && ( <Pressable
                            onPress={ () =>
                            {
                                Entity?.parent?.type == 'LeagueTeam'
                                    ? navigate( `/season/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } )
                                    : Entity?.parent?.type == 'Team' ? navigate( `/teams/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } ) :
                                        navigate( `/season/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } );
                            } }
                            marginTop={ '12px' }>
                            <Text fontSize={ '16px' } fontWeight={ 400 } color='#00A7BC' underline>{ Entity?.parent?.data ? Entity?.parent?.data : Entity?.parent?.title }</Text>
                        </Pressable> ) }
                    </VStack>
                </VStack>
            </>
        )
    }
    const ClubSchoolTitle = ( { Entity } ) =>
    {

        
        return (
            <>
                <VStack height={'auto'} width={'90%'} left={isMobile ? '24px':'48px'} zIndex={-3}>
                    <VStack>
                        <Text fontSize={'36px'} numberOfLines={1} width={'65%'}>{Entity?.name}</Text>
                        <Text fontSize={ '18px' } fontWeight={ 500 } numberOfLines={ ( Entity?.id === "b495d4e0-bf52-4690-8dc0-677db954aa7f" || "b495d4e0-bf52-4690-8dc0-677db954aa71" || "b495d4e0-bf52-4690-8dc0-677db954aa72" ) ? 3 : 1 } width={ ( Entity?.id === "b495d4e0-bf52-4690-8dc0-677db954aa7f" || "b495d4e0-bf52-4690-8dc0-677db954aa71" || "b495d4e0-bf52-4690-8dc0-677db954aa72" ) ? '100%' : '65%' }><AVDescription text={ Entity?.profile?.text } /></Text>
                        <Text fontSize={'16px'} numberOfLines={1} color={'rgba(0,0,0,0.5)'} width={'65%'}>{Entity?.location?.normalized}</Text>
                        <HStack justifyContent={ 'space-between' }>
                            <VStack>
                                <HStack space={ 1 }>
                                    { Entity?.links && Entity?.links?.map( ( item, index ) => (
                                        <Pressable
                                            key={ index } // make sure to provide a unique key for each element in a list
                                            onPress={ () => window.open( item?.url ) }
                                            marginTop={ '12px' }>
                                            <img src={ item?.icon?.trim()?.length > 0 ? item?.icon : "https://cdn-icons-png.flaticon.com/512/2985/2985013.png" } alt={ `Icon ${ index }` } height={ '24px' } width={ '24px' } />
                                        </Pressable>

                                    ) ) }
                                </HStack>
                                <HStack space={ 3 } marginTop={ '24px' } marginBottom={ '10px' } ref={menuRef }>
                                    { fieldname && ( <HStack space={ 3 }>
                                        <Text fontSize={ "16px" } color={ 'rgba(0, 0, 0, 0.50)' } numberOfLines={ 1 }>
                                            { Loc.currentLang.menu.fieldOrRoomText }
                                        </Text>
                                        <Tooltip label={ fieldname } openDelay={500 }>
                                            <Text fontSize={ 16 } fontWeight={ 500 } color={ "black" } numberOfLines={ 1 } textTransform={ 'capitalize' }>{ fieldname }</Text>
                                        </Tooltip>
                                    </HStack> ) }
                                    { Entity?.location?.siteMap && <HStack>
                                        <Pressable
                                            onPress={ () => setIsImagePopup( true ) }
                                        >
                                            <Text fontSize={ '15px' } color={ '#00A7BC' } fontWeight={ '500' }>{ fieldname ? Loc.currentLang.menu.viewFieldOrRoom : "" }</Text>
                                        </Pressable>
                                    </HStack> }
                                </HStack>
                                { Entity?.parent?.id !== '78b5b063-fd3e-4925-92bd-2a1888c6a46a' && (<Pressable
                                    onPress={ () =>
                                    {
                                        Entity?.parent?.type == 'LeagueTeam'
                                            ? navigate( `/season/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } )
                                            : Entity?.parent?.type == 'Team' ? navigate( `/teams/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } ) :
                                                navigate( `/season/${ Entity?.parent?.id }/${ Entity?.parent?.region }`, { state: { isCreate: false, team: Entity?.parent } } );
                                    } }
                                    marginTop={ '12px' }>
                                    <Text fontSize={ '16px' } fontWeight={ 400 } color='#00A7BC' underline>{ Entity?.parent?.title }</Text>
                                </Pressable> ) }
                                { Entity?.parent?.id == '78b5b063-fd3e-4925-92bd-2a1888c6a46a' && ( <Pressable
                                    onPress={ () =>
                                    {
                                        navigate(-1)
                                    } }
                                    marginTop={ '12px' }>
                                    <Text fontSize={ '16px' } fontWeight={ 400 } color='#00A7BC' underline>{ Entity?.parent?.title }</Text>
                                </Pressable> ) }
                            </VStack>
                            { ( Entity?.location?.normalized?.trim()?.length > 0 && Entity?.location?.normalized?.trim()?.length !== undefined) && (<Pressable
                                height={'95px'}
                                width={'150px'}
                                borderColor={'gray.400'}
                                borderWidth={'1px'}
                                borderRadius={'10px'}
                                onPress={() =>
                                    window.open(
                                        'https://www.google.com/maps/search/?api=1&query=' +
                                        Entity?.location?.normalized +
                                        '&query_place_id=' +
                                        Entity?.location?.placeId,
                                        '_blank',
                                        'noreferrer'
                                    )
                                }
                            >
                                <Image
                                    source={{ uri: googlemap }}
                                    style={{ height: '100%', width: '100%', borderRadius: 10, margin: 'auto' }}
                                    alt='googlemap'
                                />
                            </Pressable>)}
                        </HStack>
                    </VStack>
                </VStack>
            </>
        )
    }
    const Profile = ({ Entity }) => {
        return (
            <>
                <VStack height={'auto'} width={'90%'} left={isMobile ? '24px' : '48px'} zIndex={-3}>
                    <VStack>
                        <Text fontSize={'36px'} numberOfLines={1} width={'65%'}>{Entity?.name}</Text>
                        <Text fontSize={ '18px' } fontWeight={ 500 } numberOfLines={ 1 } width={ '65%' }><AVDescription text={Entity?.profile?.text } /></Text>
                        <Text fontSize={'16px'} color={'rgba(0,0,0,0.5)'} width={'65%'}>{Entity?.address?.normalized}</Text>
                        <HStack justifyContent={'space-between'}>
                            <HStack space={ 1 }>
                                { Entity?.links && Entity?.links?.map( ( item, index ) => (
                                    <Pressable
                                        key={ index } // make sure to provide a unique key for each element in a list
                                        onPress={ () => window.open( item?.url ) }
                                        marginTop={ '12px' }>
                                        <img src={ item?.icon?.trim()?.length > 0 ? item?.icon : "https://cdn-icons-png.flaticon.com/512/2985/2985013.png" } alt={ `Icon ${ index }` } height={ '24px' } width={ '24px' } />
                                    </Pressable>

                                ) ) }
                            </HStack>
                        </HStack>
                    </VStack>
                </VStack>
            </>
        )
    }

    const EventTitle = ({ Entity }) => {
        return (
            <>
                <VStack height={'auto'} width={'90%'} left={isMobile ? '15px' : '48px'} zIndex={-3} >
                    <VStack top={'39px'} marginBottom={'45px'}>
                        {/* <Text fontSize={'36px'} numberOfLines={1} width={'65%'}>{Entity?.name}</Text>*/}
                        <HStack >
                            <Text fontSize={ isMobile ? '16px':'28px'} width={'50%'}>
                                {Moment(Entity?.start).format('dddd, MMMM DD')}
                            </Text>
                            <HStack space={ 2 } width={ isMobile ? '' : '50%'} justifyContent={ 'flex-end' } marginTop={ '-12px' }>
                                <VStack >
                                    <Text fontSize={ '16px' } color={ 'gray.500' } fontWeight={ 400 }>{ Loc.currentLang.menu.eventStartTime }</Text>
                                <HStack space={3}>
                                    <HStack alignItems={'center'}>
                                        <TimerIcon width={'28'} height={'30'} color={'#32AE78'} />
                                 </HStack>
                                        <Text fontSize={ isMobile ? '16px' : '28px' }>
                                        {Moment(Entity?.start).format('LT')} -{' '}
                                    </Text>
                                    </HStack>
                                </VStack>
                                { !Entity?.playerStart ? <VStack>
                                    <Text fontSize={ '16px' } color={ 'gray.500' } fontWeight={ 400 }>{ Loc.currentLang.menu.eventEndTime}</Text>
                                    <HStack space={ 3 }>
                                        <HStack alignItems={ 'center' }>
                                            <TimerIcon width={ '28' } height={ '30' } color={ '#32AE78' } />
                                        </HStack>
                                        <Text fontSize={ isMobile ? '16px' : '28px' }>
                                            { Moment( Entity?.end ).format( 'LT' ) }
                                        </Text>
                                    </HStack>
                                </VStack> :
                                    <VStack>
                                        <Text fontSize={ '16px' } color={ 'gray.500' } fontWeight={ 400 }>{ Loc.currentLang.menu.teamArrival }</Text>
                                        <HStack space={ 3 }>
                                            <HStack alignItems={ 'center' }>
                                                <TimerIcon width={ '28' } height={ '30' } color={ '#32AE78' } />
                                            </HStack>
                                            <Text fontSize={ isMobile ? '16px' : '28px' }>
                                                { Moment( Entity?.playerStart ).format( 'LT' ) }
                                            </Text>
                                        </HStack>
                                    </VStack> }
                            </HStack>
                        </HStack>
                        <HStack marginTop={'20px'}>
                  <Text fontSize={ '18px' } fontWeight={ 500 } numberOfLines={ 1 } width={ '100%' }><AVDescription text={ Entity?.profile?.text } /></Text>
                        </HStack>
                        {Entity?.location && (<HStack justifyContent={'space-between'} marginTop={'20px'}>
                            <HStack space={ '10px' } width={isMobile ? '40%': '65%' }>
                            <HStack>
                                    <LocationIcon width={ '28' } height={ '30' } color={ '#32AE78' } />
                                </HStack>
                                <VStack width={ '100%' }>
                                    <Text fontSize={18} fontWeight={500} color={"black"} numberOfLines={1}  >
                                        {Entity?.location?.name ? Entity?.location?.name : Entity?.location?.title}
                                    </Text>
                                    <Text fontSize={"16px"} color={'rgba(0, 0, 0, 0.50)'} numberOfLines={1} >
                                        {Entity?.location?.address ? Entity?.location?.address : Entity?.location?.normalized}
                                    </Text>
                                    <HStack space={'60px'}>
                                        <Pressable
                                            top={'12px'}
                                            onPress={() => window.open("https://www.google.com/maps/dir/?api=1&destination=" + Entity?.location?.normalized + "&destination_place_id=" + Entity?.location?.placeId, '_blank', 'noreferrer')}
                                        >
                                            <Text fontSize={'16px'} color={'#00A7BC'} fontWeight={400}>
                                                {Loc.currentLang.menu.directions}
                                            </Text>
                                        </Pressable>
                                        {weather && (
                                            <Pressable onPress={() => window.open(weatherURL)}>
                                                <HStack space={5}>
                                                    <HStack textAlign={'center'} alignSelf={'center'}>
                                                        <Text fontSize={"40px"} >{weather?.temperature}</Text></HStack>
                                                    <HStack>
                                                    <Image
                                                        source={{
                                                            uri: weather?.icon,
                                                        }}
                                                        alt='rain'
                                                        size='70px'
                                                        textAlign={'center'}

                                                        />
                                                    </HStack>
                                                </HStack>
                                                </Pressable>
                                        )}
                                    </HStack>
                                </VStack>
                            </HStack>
                            { ( Entity?.location?.normalized?.trim()?.length > 0 && Entity?.location?.normalized?.trim()?.length !== undefined ) &&(
                                <Pressable
                                    height={'100px'}
                                    width={ isMobile ? '150px':'180px'}
                                    borderColor={'gray.400'}
                                    borderWidth={'1px'}
                                    borderRadius={'10px'}
                                    onPress={() =>
                                        window.open(
                                            'https://www.google.com/maps/search/?api=1&query=' +
                                            Entity?.location?.normalized +
                                            '&query_place_id=' +
                                            Entity?.location?.placeId,
                                            '_blank',
                                            'noreferrer'
                                        )
                                    }
                                >
                                    <Image
                                        source={{ uri: googlemap }}
                                        style={{ height: '100%', width: '100%', borderRadius: 10, margin: 'auto' }}
                                        alt='googlemap'
                                    />
                                </Pressable>)}
                        </HStack> ) }

                        <HStack space={ 1 }>
                            { Entity?.links && Entity?.links?.map( ( item, index ) => (
                                <Pressable
                                    key={ index } // make sure to provide a unique key for each element in a list
                                    onPress={ () => window.open( item?.url ) }
                                    marginTop={ '12px' }>
                                    <img src={ item?.icon?.trim()?.length > 0 ? item?.icon : "https://cdn-icons-png.flaticon.com/512/2985/2985013.png" } alt={ `Icon ${ index }` } height={ '24px' } width={ '24px' } />
                                </Pressable>

                            ) ) }
                        </HStack>
                        { Entity?.locURL && <HStack>
                            <Pressable
                                onPress={ () => window.open( Entity?.locURL ) }
                            >
                                <Text fontSize={ '15px' } color={ '#00A7BC' } fontWeight={ '500' } underline>Event Link</Text>
                            </Pressable>
                        </HStack> }
                        <HStack space={ 3 } marginTop={ '24px' } marginBottom={ '10px' } ref={ menuRef }>
                            {fieldname && (<HStack space={3}>
                                <Text  fontSize={"16px"} color={'rgba(0, 0, 0, 0.50)'} >
                                    Field/room -
                                </Text>
                                <Tooltip label={ fieldname } openDelay={ 500 }>
                                    <Text fontSize={ 16 } width={ '75%' } fontWeight={ 500 } color={ "black" } numberOfLines={ 1 } textTransform={ 'capitalize' }>{ fieldname }</Text>
                                </Tooltip>
                            </HStack>)}
                            {Entity?.location?.siteMap && <HStack>
                                <Pressable
                                    onPress={() => setIsImagePopup(true)}
                                >
                                    <Text fontSize={'15px'} color={'#00A7BC'} fontWeight={'500'}>{fieldname ? "View" : ""}</Text>
                                </Pressable>
                            </HStack>}
                        </HStack>
                        <VStack>
                        </VStack>
                    </VStack>
                </VStack>
            </>
        )
    }
    return (
        <>
            { (EntityType?.type === 'Team' && Entity?.subType !== 'Club') &&
                <TeamTitle Entity={Entity} />}
            {EntityType?.type === 'LeagueTeam' &&
                <SeasonTitle Entity={Entity} />}
            { ( EntityType?.type === 'Club' || Entity?.subType === 'Club' || EntityType?.type === 'School' || EntityType?.type === "Community" || EntityType?.type === "Neighborhood" || EntityType?.type === "Organization" || EntityType?.type === "Group") &&
                <ClubSchoolTitle Entity={Entity} />}
            {EntityType?.type === 'Person' &&
                <Profile Entity={Entity} />}
             { EntityType?.type === 'Event' && (entitydatalist?.filter( x => x?.id === Entity?.id )?.length > 0 ) &&
              <EventTitle Entity={ Entity } /> }
        </>
    )
}

export default AVHeaderTitle;
