import * as AV from '../AVCore/AVCore';
import * as React from 'react';
import { Dimensions } from 'react-native';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setSearch } from '../Redux/Actions/userActions';
import _ from 'lodash';

const useQuickSearch = () => {
    const dimensions = Dimensions.get('window'); //Dimension of the platform
    const dispatch = useDispatch();
  const [showSearchinput, setshowSearchinput] = React.useState(false); //Show/Hide input box for mobile
  const [searchInput, setSearchInput] = React.useState(''); //Search input value
  const [searchAdvInput, setSearchAdvInput] = React.useState(''); // Advanced Search input value
  const [showSearch, setShowSearch] = React.useState(false); //Show/Hide Search list
  const [filteredResult, setFilteredResults] = React.useState<AV.ILink[]>(); //  Search result
  const [advFilteredResults, setAdvFilteredResults] = React.useState<AV.ILink[]>(); // Advanced Search result
  const [showAdvSearch, setShowAdvSearch] = React.useState(true); //Show/Hide Advanced Search list
    const [ searchValue, setSearchValue ] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [resultData, setIsResultData] = React.useState(false);
    const [ toResult, setToResult ] = React.useState( false );
  const localUserSearch = useSelector((state : RootStateOrAny) => state.user.userSearch);
  const [value, setValue] = React.useState("");
  //Local and advanced search
    const searchItems = async ( searchValue, type ) =>
    {
    setValue(searchValue);
    setToResult( false );
        setIsResultData( false );
    type === 'Advanced' ? setSearchAdvInput(searchValue) : setSearchInput(searchValue);
    if (searchValue !== '') {
      var searchdata =
       type === 'Advanced' ? await AV.Person.search(searchValue) : await AV.Person.localSearch(searchValue);
        let locSearch = localUserSearch;
        if (locSearch == undefined) locSearch = [];
        locSearch = locSearch.concat(searchdata);
        // Flatten the array and remove duplicate IDs
        locSearch = _.uniqBy(locSearch, 'id');
        /*searchdata = _.uniqBy(searchdata, 'id');*/
            locSearch = locSearch?.filter( ( x ) => ( x?.title !== undefined &&  x?.data !== "Deleted" && x?.data !== "Suspended" && x?.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))); 
    
        type === 'Advanced' ? setAdvFilteredResults(locSearch) : setFilteredResults(locSearch);
      type === 'Advanced' ? setShowAdvSearch(true) : setShowSearch(true);
      //  type === " " ? setShowSearch(false) : setShowSearch(true)
    } else {
      type === 'Advanced' ? setShowAdvSearch(false) : setShowAdvSearch(true);
      type === ' ' ? setShowSearch(false) : setShowSearch(true);
    }
      if (searchValue?.length === 0) {
          setShowSearch(false)
      };
    // searchValue('');
  };

  const searchTeams = async (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== '') {
      const searchdata = await AV.AVEntity.search(searchValue, 'Org');
      setFilteredResults(searchdata);
      setShowSearch(true);
    }
      searchValue('');
      setIsUpdate(true);
      setFilteredResults([]);

  };

    const advSearch = async ( value, type ) =>
    {
        if ( !toResult && value?.trim()?.length > 0)
        {
            const result = await AV.Person.search( value, type );
            if ( result?.length == 0 )
            {
                setIsResultData( true );
            }
            setAdvFilteredResults( result );
            setFilteredResults( result?.filter( x => x?.data !== "Deleted" && x?.data !== "Suspended" ) );
            let locSearch = localUserSearch;
            if ( locSearch == undefined ) locSearch = [];
            locSearch = locSearch.concat( result );
            // Flatten the array and remove duplicate IDs
            locSearch = _.uniqBy( locSearch, 'id' );
            dispatch( setSearch( locSearch ) );
            //setToResult( true );
        }
  };
  const searchLocal = async (value) => {
    const data = await AV.Person.localSearch(value);
    setFilteredResults(data);
  };
  return {
    dimensions,
    showSearchinput,
    setshowSearchinput,
    searchInput,
    setSearchInput,
    showSearch,
    setShowSearch,
    filteredResult,
      searchItems,
      setIsUpdate,
    showAdvSearch,
    setShowAdvSearch,
    searchAdvInput,
    setSearchAdvInput,
    searchTeams,
    advFilteredResults,
    advSearch,
    setAdvFilteredResults,
    setFilteredResults,
    value,
      setValue,
      resultData, setIsResultData,
  };
};
export default useQuickSearch;
