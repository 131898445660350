import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import
    {
        Image,
        Divider,
        HStack,
        Pressable,
        Hidden,
        Box,
        Modal,
        Badge,
        VStack,
        Text,
        Button,
        Input,
        TextArea,
        CloseIcon,
        AddIcon,
        ScrollView,
        FlatList,
        Tooltip,
        HamburgerIcon,
        Center,
        View,
    } from 'native-base';
import useGetFeeds from '../../Hooks/useGetFeeds';
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native';
//import  FilePicker  from '../../Platform/FilePicker';
//@ts-ignore
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import Dropdown from '../../assets/images/dropdown.svg';
import { Auth } from '../../Platform/Auth';
import { useLocation } from 'react-router-dom';
import sad from '../../assets/images/sad.svg';
import dislike from '../../assets/images/dislike.svg';
import startruck from '../../assets/images/startruck.svg';
import stars from '../../assets/images/stars.svg';
import smile from '../../assets/images/smile.svg';
import sads from '../../assets/images/sads.svg';
import fan from '../../assets/images/fan.svg';
import like from '../../assets/images/like.svg';
import
    {
        NotificationIcon,
        MessageIcon,
        MenuIcon,
        SearchIcon,
        CreateMessageIcon,
        MoreHorizontalIcon,
        PaperPlaneIcon,
        AttachIcon,
        LikeIcon,
        GifIcon,
        HappyIcon,
        AddPhotoIcon,
        TickIcon,
        FollowingIcon,
        CrossIcon,
        ReplyIcon
    } from '../../assets/icons/icon-assets';
import Menu from './Menu';
import ProfilePic from '../common/ProfilePicture';
import { Person } from '../../AVCore/Person';
import AVGradientBox from '../../Platform/AVGradientBox';
import useConvoChat from '../../Hooks/useConvoChat';
import useFollowRequest from '../../Hooks/useFollowRequest';
import useAlerts from '../../Hooks/useAlerts';
import useConvoList from '../../Hooks/useConvoList';
import Picker from 'emoji-picker-react';
import Card from '../../components/convos/Card';
import PostsCard from '../../components/posts/Card';
import { Link } from '../../AVCore/Link';
import * as AV from '../../AVCore/AVCore';
import Moment from 'moment';
import FilePicker from '../../Platform/FilePicker';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AHStack, AVBox, AVPressable, AVStack } from 'components/ui';
import SvgIcon from 'assets/icons';
import AVCancel from 'components/common/AVCancel';
import AVVideo from '../../Platform/AVVideo';
import AVInput from 'components/common/AVInput';
import { TouchableOpacity, Dimensions } from 'react-native';
import { AVButtonRoundInvert } from 'components/common/AVButtons';
import { State } from 'react-native-gesture-handler';
import Feeds from '../../Screens/Feeds/Feeds';
import { Store } from '../../Platform/Store';
import { setFeedShowing } from '../../Redux/Actions/userActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setFeeds, setFeedsUpdate } from '../../Redux/Actions/feedsAction';
import { setAlerts, setNewAlerts } from '../../Redux/Actions/alertActions';
import { setConvo } from '../../Redux/Actions/convoActions';
import { Loc } from '../../App/Loc';
import AVlinktext from '../common/AVlinktext';
import heart from '../../assets/images/heart.png';
import broken from '../../assets/images/broken.png';
import { Message, Avatar } from "@chatscope/chat-ui-kit-react";
import AVConverttime from '../../components/posts/AVConverttime';
import settings from '../../Platform/settings.json';
interface Props
{
    data : any | undefined;
    key : any | undefined;
    replyValue : any | undefined;
    setreplyValue : any | undefined;
    profile : any | undefined;
    itemHeights : any | undefined;
    setItemHeights : any | undefined;
    convoPost : any | undefined;
    setShowPreview : any | undefined;
    setSingleImage : any | undefined;
    setShowAllImage : any | undefined;
    setHeader : any | undefined;
    HandleEditSet : any | undefined;
    isOpen : any | undefined;
    setIsOpen : any | undefined;
    selectItem : any | undefined;
    setSelectItem : any | undefined;
    showFeedbackImpressions : any | undefined;
  setShowFeedbackImpressions : any | undefined;
  mdlCreateConvoVisible : any | undefined;
  setMdlCreateConvoVisible : any | undefined;
}

const ConvoCard : FC<Props> = React.memo(({ data, key, replyValue, setreplyValue, profile, itemHeights, setItemHeights, convoPost, setShowPreview, setSingleImage, setShowAllImage, setHeader, HandleEditSet, isOpen, setIsOpen, selectItem, setSelectItem, showFeedbackImpressions, setShowFeedbackImpressions, mdlCreateConvoVisible, setMdlCreateConvoVisible } ) =>
{
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location : any = useLocation();
    const dimensions = Dimensions.get( 'window' );
    const isMobile = dimensions?.width <= 600 || false;
    const userconvo = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const [ item, setitem ] = useState( data );
    const menuRef = useRef( null );
    useOutsideAlerter( menuRef );
    const [ isDeleteLoading, setisdeleteLoading ] = useState( false );
    const currentDate = new Date();
  const entitydatalist = useSelector((state : RootStateOrAny) => state.user.entitydatalist);
    const {
        setConvoPost
    } = useConvoChat();

    useEffect( () =>
    {
        setitem( data )
    }, [ convoPost ] )

    React.useEffect( () =>
    {
        console.log( "userconvo", userconvo )
    }, [ userconvo ] );
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            // Alert if clicked on outside of element
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setShowFeedbackImpressions( false );
                    setIsOpen(false)
                }
            }
            // Bind the event listener
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                // Unbind the event listener on clean up
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }
    const openPreview = ( item ) =>
    {
        setShowPreview( true );
        setSingleImage( item );
        setShowAllImage( item );
        setHeader( true )

    }
    const handleItemLayout = ( item, height ) =>
    {
        const index = convoPost?.findIndex( ( x ) => x?.id === item?.id );
        setItemHeights( prevHeights =>
        {
            const updatedHeights = [ ...prevHeights ];
            updatedHeights[ index ] = height;
            return updatedHeights;
        } );
    };

    return (
        <>
            { item?.person?.id === currentUser?.id ? (
                /*MyProfile*/
                <View zIndex={ -3 } onLayout={ ( { nativeEvent } ) => handleItemLayout( item, nativeEvent.layout.height ) }>
                    <VStack borderRadius={ [ 5, 10 ] } py={ [ 1, 1 ] } alignItems={ isMobile ? '' : 'flex-end' } zIndex={ -3 }>
                        {/*Text*/ }
                        { ( item?.media?.id?.trim()?.length === undefined || item?.media?.id?.trim()?.length === 0 ) && ( item?.galleryPost?.length === undefined || item?.galleryPost?.length === 0 ) && (
                            <>
                                {( item?.ReplyPost?.length > 0 && item?.ReplyPost[ 0 ]?.person?.id ) &&
                                    <>
                                    <VStack maxW={ isMobile ? '180px' : '210px' } padding={ '10px' } borderBottomRightRadius={ 0 } borderBottomLeftRadius={ 0 } borderRadius={ '10px' } background={ 'gray.100' } minW={ isMobile ? '' : '100px' } marginRight={ '15px' } marginTop={ '13px' } left={ isMobile ? '175px' : '' }
                                    >
                                    <HStack>
                                            <Text fontWeight={400}> { item?.ReplyPost[ 0 ]?.person?.title } </Text>
                                        </HStack>
                                        <Text maxW={ '195px' }>
                                            <AVlinktext text={ item?.ReplyPost[ 0 ]?.text } />
                                        </Text>
                                        <Pressable onPress={ () => openPreview( item?.ReplyPost[ 0 ] ) }>
                                            <LoadMedia item={ item?.ReplyPost[ 0 ] } isMobile={ isMobile } />
                                        </Pressable>
                                    </VStack>
                                    </>
                                }
                                <VStack>
                                <VStack maxW={ isMobile ? '180px' :'210px' } padding={ '10px' } borderBottomRightRadius={ '10px' } borderBottomLeftRadius={ '10px' } background={ '#32AE78' } borderTopLeftRadius={ '10px' } minW={ isMobile ? '' : '100px' } marginBottom={ '14px' } marginRight={ '15px' } left={ isMobile ? '175px' : '' } >
                                    {/*Text*/ }
                                    <Text maxW={ '195px' }><AVlinktext text={ item?.text } /></Text>
                                    {/*Time*/ }
                                    <HStack bottom={ '-5px' }><Text color={ 'gray.700' } fontSize={ '12px' }>
                                        <AVConverttime date={ item?.createDate } page={ 'Post' } />
                                    </Text>
                                    </HStack>
                                    <HStack position={ 'absolute' } bottom={ '-14px' } left={ 0}>
                                        {/*My profile Impression of text*/ }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.wow } } alt='Wow' size='18' />
                                                    <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.lol } } alt='Lol' size='18' />
                                                    <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.like } } alt='Like' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.unlike } } alt='Like' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.cheer } } alt='cheer' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.question } } alt='stars' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.sad } } alt='sad' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.mad } } alt='Mad' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.heart } } alt='heart' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.broken } } alt='sad' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                    </HStack>
                  </VStack>
                  { item?.id &&
                    <Pressable position={ 'absolute' } right={ '-5px' } top={ '10px' } onPress={ () => {
                      setIsOpen(!isOpen);
                      setSelectItem(item)
                    } }>
                      <MoreHorizontalIcon
                        height={ '4px' } width={ '16px' } size={ 'md' } color={ "gray.400" } style={ { transform: [ { rotate: '90deg' } ] } } />
                                        </Pressable> }
                                </VStack>
                            </>
                        ) }

                        {/*Media*/ }
                        { ( item?.media?.id?.trim()?.length > 0 || item?.galleryPost?.length > 0 ) && (
                            <>
                                { ( item?.ReplyPost?.length > 0 && item?.ReplyPost[ 0 ]?.person?.id ) &&
                                    <>
                                        <VStack maxW={ '210px' } padding={ '10px' } borderBottomRightRadius={ 0 } borderBottomLeftRadius={ 0 } borderRadius={ '10px' } background={ 'gray.100' } minW={ '100px' } marginRight={ '15px' } marginTop={ '13px' } >
                                            <HStack>
                                                <Text fontWeight={ 400 }> { item?.ReplyPost[ 0 ]?.person?.title } </Text>
                                            </HStack>
                                            <Text maxW={ '195px' }>
                                                <AVlinktext text={ item?.ReplyPost[ 0 ]?.text } />
                                        </Text>
                                        <Pressable onPress={ () => openPreview( item?.ReplyPost[ 0 ] ) }>
                                            <LoadMedia item={ item?.ReplyPost[ 0 ] } isMobile={ isMobile } />
                                        </Pressable>
                                        </VStack>
                                    </>
                                }
                                <VStack
                                    width={ '210px' }
                                    alignSelf={ 'flex-end' }
                                    borderBottomRightRadius={ '10px' }
                                    borderBottomLeftRadius={ '10px' }
                                    background={ '#32AE78' }
                                    borderTopLeftRadius={ '10px' }
                                    marginRight={ '15px' }
                                    padding={ '10px' }
                                    marginBottom={ '14px' }
                                >
                                    { item?.text && (<HStack paddingBottom={'8px'}>
                                        <Text><AVlinktext text={ item?.text } /></Text>
                                    </HStack>)}
                                    <Pressable onPress={ () => openPreview( item ) }>
                                        <LoadMedia item={ item } isMobile={ isMobile } />
                                    </Pressable>
                                    <HStack  bottom={'-4px'} >
                                        <Text color={ 'gray.700' } fontSize={ '12px' }>
                                            <AVConverttime date={ item?.createDate } page={ 'Post' } />
                                        </Text>
                                    </HStack>
                                    <HStack position={ 'absolute' } bottom={ '-14px' } left={0}>
                                        {/*My profile Impression of text*/ }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.wow } } alt='wow' size='18' />
                                                    <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.lol } } alt='Lol' size='18' />
                                                    <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.like } } alt='Like' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }

                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.unlike } } alt='UnLike' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.cheer } } alt='cheer' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.question } } alt='Question' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.sad } } alt='sads' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.mad } } alt='mad' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.heart } } alt='heart' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                        { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length > 0 && (
                                            <Box
                                                height={ '20px' }
                                                width={ '30px' }
                                                alignItems={ 'center' }
                                                backgroundColor={ 'white' }
                                                rounded={ 'full' }
                                                shadow={ 9 }
                                            >
                                                <HStack>
                            <Image source={ { uri: settings.broken } } alt='broken' size='18' />
                                                    <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                        { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length }
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        ) }
                                    </HStack>
                  </VStack>
                  { item?.id &&
                    <Pressable position={ 'absolute' } right={ '-5px' } top={ '10px' } onPress={ () => {
                      setIsOpen(!isOpen);
                      setSelectItem(item)
                    } }>
                      <MoreHorizontalIcon
                        height={ '4px' } width={ '16px' } size={ 'md' } color={ "gray.400" } style={ { transform: [ { rotate: '90deg' } ] } } />
                    </Pressable> }
                            </>
                        ) }
                    </VStack>
                </View>
            ) : (
                /*Other Person*/
                <View zIndex={ -3 } onLayout={ ( { nativeEvent } ) => handleItemLayout( item, nativeEvent.layout.height ) }>
                    <VStack key={ item?.id } borderRadius={ [ 5, 10 ] } py={ [ 1, 1 ] } zIndex={ -3 }>
                        <HStack space={ 2 } zIndex={ -3 } >
                            {/*profile and name*/ }
                  <HStack>
                    <Pressable onPress={ () => {
                      navigate(`/profile/${ item?.person?.id }/${ item?.person?.region }`, { state: { user: item?.person } });
                      setMdlCreateConvoVisible(!mdlCreateConvoVisible); } }>
                      <ProfilePic width={ 38 } height={ 38 } link={ currentUser?.id == item?.person?.id ?
                        AV.AVEntity.getLink(currentUser) :
                        entitydatalist?.find((x : any) => x?.id == item?.person?.id) ?
                          AV.AVEntity.getLink(entitydatalist?.find((x : any) => x?.id == item?.person?.id)) :  profile ? profile  : item?.person } />
                    </Pressable>
        
                                <HStack position={ 'absolute' } left={ '50px' } top={ '-11px' } space={ 1 } width={ '200px' }>
                      <Text fontSize={ '15px' } width={ '100px' } ellipsizeMode={ 'tail' } numberOfLines={ 1 } color={ 'rgba(0, 0, 0, 0.7)' }>
                                        { profile?.title ? profile?.title : item?.person?.title }
                                    </Text>
                                </HStack>
                                </HStack>
                              
                               
                            {/*Text*/ }
                                { ( item?.media?.id?.trim()?.length === undefined || item?.media?.id?.trim()?.length === 0 ) && ( item?.galleryPost?.length === undefined || item?.galleryPost?.length === 0 ) && (
                                    <>
                                        
                                        <VStack maxW={ '210px' } padding={ '10px' } borderBottomRightRadius={ '10px' } borderBottomLeftRadius={ '10px' } background={ '#c6e3fa' } borderTopRightRadius={ '10px' } minW={ '100px' } marginRight={ '15px' } marginTop={ '13px' } marginBottom={ '18px' }>
                                            { ( item?.ReplyPost?.length > 0 && item?.ReplyPost[ 0 ]?.person?.id ) &&
                                                <>
                                                <VStack maxW={ '210px' } padding={ '10px' } borderBottomRightRadius={ 0 } borderBottomLeftRadius={ 0 } borderRadius={ '10px' } background={ 'gray.100' } minW={ '100px' } marginRight={ '15px' } marginTop={ '0px' } marginBottom={'5px'} >
                                                        <HStack>
                                                            <Text fontWeight={ 400 }> { item?.ReplyPost[ 0 ]?.person?.title } </Text>
                                                        </HStack>
                                                        <Text maxW={ '195px' }>
                                                            <AVlinktext text={ item?.ReplyPost[ 0 ]?.text } />
                                                    </Text>
                                                    <Pressable onPress={ () => openPreview( item?.ReplyPost[ 0 ] ) }>
                                                        <LoadMedia item={ item?.ReplyPost[ 0 ] } isMobile={ isMobile } />
                                                    </Pressable>
                                                    </VStack>
                                                </>
                                            }
                                           
                                        {/*Text*/}
                                            <Text maxW={ '195px' }><AVlinktext text={ item?.text } /></Text>
                                            {/*Time*/}
                                            <HStack bottom={'-5px'}><Text color={ 'gray.700' } fontSize={ '12px' }>
                                                <AVConverttime date={ item?.createDate } page={ 'Post' } />
                                            </Text>
                                            </HStack>
                                            {/*Impression*/ }
                                            <HStack position={ 'absolute' } bottom={ '-12px' } right={0}>
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.wow } } alt='startruck' size='18' />
                                                            <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.lol } } alt='Lol' size='18' />
                                                            <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.like } } alt='Like' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }

                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.unlike } } alt='Unlike' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.cheer } } alt='cheer' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.question } } alt='stars' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.sad } } alt='sads' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.mad } } alt='sad' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.heart } } alt='heart' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                                { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length > 0 && (
                                                    <Box
                                                        height={ '20px' }
                                                        width={ '30px' }
                                                        alignItems={ 'center' }
                                                        backgroundColor={ 'white' }
                                                        rounded={ 'full' }
                                                        shadow={ 9 }
                                                    >
                                                        <HStack>
                                <Image source={ { uri: settings.broken } } alt='sad' size='18' />
                                                            <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                                { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length }
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                ) }
                                            </HStack>
                                        </VStack>
                      { item?.id &&
                        <Pressable top={ '10px' } width={ '25px' } left={ '-25px' } onPress={ () => {
                          setIsOpen(!isOpen);
                          setSelectItem(item)
                        } }>
                          <MoreHorizontalIcon
                            height={ '4px' } width={ '16px' } size={ 'md' } color={ "gray.400" } style={ { transform: [ { rotate: '90deg' } ] } } />

                        </Pressable> }
                                    </>
                                    ) }
                                
                            {/*Media*/ }
                                { ( item?.media?.id?.trim()?.length > 0 || item?.galleryPost?.length > 0 ) && (
                                    <>
                                        
                                        <VStack
                                            marginTop={ '10px' }
                                            width={ '210px' }
                                            alignSelf={ 'flex-start' }
                                            borderBottomRightRadius={ '10px' }
                                            borderBottomLeftRadius={ '10px' }
                                            background={ '#c6e3fa' }
                                            borderTopLeftRadius={ '10px' }
                                            marginRight={ '15px' }
                                            marginBottom={ '19px' }
                                            shadow={ 10 }
                                            padding={ '6px 9px 6px' }
                                        
                                        >
                                            <HStack paddingBottom={ '8px' }>
                          <Text width={'100%' }>{ item?.text }</Text>
                                            </HStack>
                                            <Pressable onPress={ () => openPreview( item ) }>
                                        <LoadMedia item={ item } isMobile={ isMobile } />
                                        </Pressable>
                                        <HStack bottom={ '-4px' }>
                                            <Text color={ 'gray.700' } fontSize={ '12px' }>
                                                <AVConverttime date={ item?.createDate } page={ 'Post' } />
                                            </Text>
                                        </HStack>
                        <HStack position={ 'absolute' } bottom={ '-14px' } right={0}>
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.wow } } alt='startruck' size='18' />
                                                        <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Wow' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.lol } } alt='smile' size='18' />
                                                        <Text fontSize={ 11 } fontWeight={ 'bold' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Lol' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.like } } alt='Like' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Like' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.unlike } } alt='Like' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Unlike' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.cheer } } alt='sad' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Cheer' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.question } } alt='stars' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Question' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.sad } } alt='sads' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Sad' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.mad } } alt='sad' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'Mad' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.heart } } alt='heart' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'heart' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length > 0 && (
                                                <Box
                                                    height={ '20px' }
                                                    width={ '30px' }
                                                    alignItems={ 'center' }
                                                    backgroundColor={ 'white' }
                                                    rounded={ 'full' }
                                                    shadow={ 9 }
                                                >
                                                    <HStack>
                              <Image source={ { uri: settings.broken } } alt='sad' size='18' />
                                                        <Text fontWeight={ 'bold' } fontSize={ '11' }>
                                                            { item?.impressions?.filter( ( x ) => x?.impression == 'broken' ).length }
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                            ) }
                                            </HStack>
                      </VStack>
                      { item?.id &&
                        <Pressable top={ '10px' } left={ '-25px' } onPress={ () => {
                          setIsOpen(true);
                          setSelectItem(item)
                        } } >
                          <MoreHorizontalIcon
                            height={ '4px' } width={ '16px' } size={ 'md' } color={ "gray.400" } style={ { transform: [ { rotate: '90deg' } ] } } />
                        </Pressable> }
                                </>
                            ) }
                        </HStack>

                    </VStack>
                </View>
            ) }
        </>
    );

});


const LoadMedia = ( { item, isDetail = false, isMobile } ) =>
{
    const [ imageUrls, setImagesUrl ] = React.useState( [] );
    const [ videoUrls, setVideoUrl ] = React.useState( [] );
    let videomedia : any = [];
    let imagemedia : any = [];

    React.useEffect( () =>
    {
        if (
            ( item?.galleryPost &&
                item?.galleryPost?.length > 0 &&
                item?.media?.type?.startsWith( 'image' ) ) ||
            ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
        )
        {
            for ( let i = 0; i < item?.galleryPost?.length; i++ )
            {
                imagemedia.push( item?.galleryPost[ i ].media );
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
            {
                imagemedia.push( item?.media );
                let images = imagemedia?.length && imagemedia.map( ( i ) => AV.Media.getURL( i ) );
                setImagesUrl( images );
            }
        }
        else
        {
            for ( var j = 0; j < item?.galleryPost?.length; j++ )
            {
                if (
                    item?.galleryPost[ j ]?.media?.storeId &&
                    item?.galleryPost[ j ]?.media?.type?.startsWith( 'video' )
                )
                {
                    videomedia.push( item?.galleryPost[ j ]?.media );
                    setVideoUrl( videomedia );
                } else
                {
                    imagemedia.push( item?.galleryPost[ j ]?.media );
                    let images = imagemedia?.length && imagemedia.map( ( i ) => AV.Media.getURL( i ) );
                    setImagesUrl( images );
                }
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'video' ) )
            {
                videomedia.push( item?.media );
                setVideoUrl( videomedia );
            }
        }
    }, [] );

    return (
        <AVStack width={ '100%' }>
            <div>
                { imageUrls && imageUrls.length > 0 && (
                    <div
                        className={
                            imageUrls.length === 1
                                ? 'grid_17'
                                : imageUrls.length === 2
                                    ? 'grid_2'
                                    : imageUrls.length === 3
                                        ? 'preview3'
                                        : imageUrls.length === 4
                                            ? 'grid_4'
                                            : imageUrls.length >= 5
                                                ? 'grid_5' :
                                                'default'
                        }
                    >
                        { imageUrls.slice( 0, 5 ).map( ( url, i ) => (
                            <img
                                src={ url }
                                key={ `${ url }_${ i }` }
                                alt=''
                                className={ `img-${ i }` }
                                style={ { margin: '1px', width: isMobile ? '200px' : imageUrls.length === 1 ? '100%': '100px', height: '100px', objectFit: 'cover' } }
                            />
                        ) ) }
                        { imageUrls.length > 5 && (
                            <div className='more-pics-shadow'>+{ imageUrls.length - 5 }</div>
                        ) }
                    </div>
                ) }
            </div>
            <div>
                { videoUrls && videoUrls.length > 0 && (
                    <div
                        className={
                            videoUrls.length === 1
                                ? 'grid_17'
                                : videoUrls.length === 2
                                    ? 'grid_2'
                                    : videoUrls.length === 3
                                        ? 'preview3'
                                        : videoUrls.length === 4
                                            ? 'grid_4'
                                            : videoUrls.length >= 5
                                                ? 'grid_5'
                                                : 'default'
                        }
                    >
                        { videoUrls.slice( 0, 5 ).map( ( vid, i ) => (
                            <AVVideo
                                url={ AV.Media.getURL( vid ) }
                                controls={ true }
                                light={ AV.Media.getVideoPicURL( vid ) }
                                pip={ true }
                                playsinline={ true }
                                playing={ true }
                                key={ `${ vid }_${ i }` }
                                className={ `video img-${ i }` }
                                style={ { width: '100%' } }
                            />
                        ) ) }

                        { videoUrls.length > 5 && (
                            <div className='more-pics-shadow'>+{ videoUrls.length - 5 }</div>
                        ) }
                    </div>
                ) }
            </div>
        </AVStack>
    );
};
export default React.memo( ConvoCard );
