import { Box, CloseIcon, HStack, Input, Pressable, ScrollView, SearchIcon, VStack, Text, Image, Divider, Tooltip, Button } from 'native-base';
import React, { FC, useEffect, useState } from 'react';
import { Loc } from '../../App/Loc';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import * as AV from '../../AVCore/AVCore';
import { setLocation } from '../../Redux/Actions/userActions';
import _ from 'lodash';
import locationsvg from '../../assets/images/location.svg';
import helping from '../../assets/images/helping.png';
import AVFieldMap from '../../components/common/AVFieldMap';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dropdown from '../../assets/images/dropdown.svg';

interface Props {
    isGame : boolean | undefined,
    isPractice : boolean | undefined,
    setSiteField : any | undefined,
    locationaddress : any | undefined,
    setlocationaddress : any | undefined,
    isCreate: any | undefined,
    setEventLocation: any | undefined,
    eventLocation: any | undefined,
    Entity : any | undefined,
    handleupdatelocationfield : any | undefined,
    setLocationEntity : any | undefined,
    locationEntity : any | undefined,
    mapField : any | undefined,
    setIsMapField : any | undefined,
    isShowEdit : any | undefined,
    isCreateevent : any | undefined,
}

const AVLocationBox : FC<Props> = ( { locationaddress, setlocationaddress, isCreate, setEventLocation, eventLocation, isShowEdit, Entity, handleupdatelocationfield, setLocationEntity, locationEntity, isCreateevent }) => {
    const dispatch = useDispatch();
    const [ filteredResults, setfilteredResults ] = useState([]);
    const [ locationNotFound, setLocationNotFound ] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [isLocationInput, setIsLocationInput] = useState(true);
    const LocaluserLocation = useSelector( ( state : RootStateOrAny ) => state?.user?.userLocation );
    const [ showFields, setShowFields ] = useState( false );
    const [ siteField, setSiteField ] = useState<any>();
    const [ updateField, setIsUpdateField ] = useState( false );
    const [ fieldName, setIsFieldName ] = useState( '' );
    const [ datafield, setIsDataField ] = useState<any>( [] );
    const [ visiblefield, setVisibleField ] = useState( false );
    const [ isActives, setIsActives ] = useState( false );
    const [ fieldValue, setFieldValue ] = useState( isCreate ? '' : Entity?.location?.field?.name ? Entity?.location?.field?.name : 'Field/Room' );
    const [isDisable,setIsDisable] = useState(false)


    const customS = {
        maxHeight: '53px', // Set your desired height here
    };

    const handlesearchitemsorglocation = (e) => {
        e.preventDefault();
        setIsDisable( false );
        setIsLocationInput(true);
        setLocationNotFound(false);
        searchItems(e.target.value);
    };
    //SearchLocation
    const searchItems = async (searchValue) => {
        setEventLocation(searchValue);
        setlocationaddress([]);
        var filteredData : any = await AV.Person.locationSearch(searchValue);
        if (searchValue === '') setfilteredResults([]);
        else {
            let loclocation = LocaluserLocation;
            if (loclocation === undefined) loclocation = [];
            loclocation = loclocation.concat(filteredData);
            // Flatten the array and remove duplicate IDs
            loclocation = _.uniqBy(loclocation, 'placeId');
            loclocation = loclocation?.filter((x) => x?.name !== 'More');
            filteredData = loclocation;
            filteredData = filteredData.filter(
                (x : any) =>
                    (x?.normalized !== undefined &&
                        x?.normalized?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                    (x?.title !== undefined &&
                        x?.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                    (x?.name !== undefined &&
                        x?.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                    (x?.address !== undefined &&
                        x?.address?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
            );
            if (filteredData === '') {
                let val = { name: 'More', address: 'More' };
                filteredData.push(val);
            }
            setfilteredResults(filteredData);
        }
    };

    const handleEventLocation = async ( e ) =>
    {
      if (e === 'More' && isDisable)
        {
            return;
        }
        setSearchLoader(true);
        if (e === 'More') {
            var location : any = await AV.Location.searchLocation(eventLocation);
            if (location?.length === 0) {
                setLocationNotFound(true);
            }
            setfilteredResults(location);
            let loclocation = LocaluserLocation;
            if (loclocation === undefined) loclocation = [];
            loclocation = loclocation.concat(location);
            // Flatten the array and remove duplicate IDs
            loclocation = _.uniqBy(loclocation, 'placeId');
            dispatch(setLocation(loclocation));
        } else if (e?.address === 'More') {
            setfilteredResults([]);
        } else {
            let title = e?.title ? e?.title : e?.name
            let name = title?.replace(/'/g, "")
            await AV.Location.getLocation(e.placeId, name).then((response) => {
                var loc : any = AV.Location.getLink(response);
                setlocationaddress( loc );
                setEventLocation( e.address ? e?.address : response?.address?.normalized );
                    setSiteField(response?.fields);
                    setLocationEntity(response);
                    setVisibleField( true );
                setfilteredResults([]);
                setIsLocationInput(false);
            });
        }
        setSearchLoader( false );
        setIsDisable( true );
    };

    useEffect(() => {
        setEventLocation('');
        setfilteredResults([]);
        setIsLocationInput( true );
        setLocationEntity( '' )
        setShowFields( false );
        setlocationaddress([]);
    }, [isCreate])

    useEffect( () =>
    {
        if ( isShowEdit )
        {
            setlocationaddress( Entity?.location );
            setEventLocation( Entity?.location?.normalized )
            setIsLocationInput( false );
        }
    }, [ isShowEdit ] )
    useEffect( () =>
    {
        const fetchdata = async() =>
        {
          if ( isCreateevent ? false : Entity?.location )
            {
                await AV.Location.getLocation( Entity?.location?.placeId, Entity?.location?.title ).then( ( response ) =>
                {
                    var loc : any = AV.Location.getLink( response );
                    setSiteField( response?.fields );
                    setLocationEntity( response );
                    setfilteredResults( [] );
                    setVisibleField(true);
                } );
            }
        }
        fetchdata();
    }, [ Entity, isShowEdit ])


    return (
        <>
            <Box
                width={ '100%' }
                height={ '65px' }
                borderRadius={ '3px' }
                borderWidth={ 1.2 }
                bgColor={ 'transparent' }
                borderColor={ 'gray.400' }
            >
                <VStack
                    alignItems='flex-start'
                    bottom={ -32 }
                    position='absolute'
                    width={ '100%' }
                    space={ 0.5 }
                >
                    { locationaddress !== ' ' && eventLocation !== '' && (
                        <Text variant={ 'AVTitleText' } padding={ 2 } numberOfLines={ 1 } width={'98%'}>
                            { locationaddress?.title
                                ? locationaddress?.title
                                : locationaddress?.name }
                        </Text>
                    ) }
                    <Input
                        placeholder={ Loc.currentLang.menu.location + "*"}
                        width={ '100%' }
                        size='xl'
                        bottom={ 6 }
                        minHeight='57'
                        variant={ 'unstyled' }
                        onChange={ handlesearchitemsorglocation }
                        value={ eventLocation }
                        InputRightElement={
                            <>
                                { eventLocation && (
                                    <HStack>
                                        <Pressable
                                            right={ '6px' }
                                            onPress={ () =>
                                            {
                                                setEventLocation( '' );
                                                setfilteredResults( [] );
                                                setIsLocationInput( true );
                                                setlocationaddress( [] );
                                                setVisibleField( false );
                                                setSiteField( '' );
                                                setlocationaddress( [] );
                                            } }
                                        >
                                            <CloseIcon marginTop={ '-30px' } zIndex={ '100px' } size={ '15px' } />
                                        </Pressable>
                                    </HStack>
                                ) }
                                { !eventLocation && (
                                    <Tooltip
                                        label={ Loc.currentLang.helpText.locations }
                                        w={ 200 }
                                    >
                                        <Box
                                            marginTop={ '-30px' }
                                            p={ '7px' }
                                            right={ '2px' }
                                            backgroundColor={ 'AVColor.liteBg' }
                                            rounded={ 'full' }
                                        >
                                            <img src={ helping } height={ '15px' } width={ '15px' } />
                                        </Box>
                                    </Tooltip> ) }
                            </>
                        }
                    ></Input>
                </VStack>
                {/*LocationDropDown*/ }
                { eventLocation?.trim()?.length > 0 && isLocationInput && (
                    <>
                        <Box
                            width={ '100%' }
                            left='0.5px'
                            position='absolute'
                            bottom='65px'
                            maxHeight='291px'
                            minHeight='43px'
                            bgColor={ [ '#FFF' ] }
                            shadow={ 100 }
                            borderColor={ 'gray.300' }
                            borderWidth={ '1px' }
                            borderRadius={ '10px' }
                            rounded={ 'lg' }
                        >
                            <ScrollView showsHorizontalScrollIndicator={ true } maxHeight={ '200px' }>
                                <VStack>
                                    { filteredResults.length > 0 &&
                                        filteredResults.map( ( item : any ) => (
                                            <div >
                                                <Pressable
                                                    top={ '5px' }
                                                    height={ '56px' }
                                                    onPress={ ( e ) =>
                                                    {
                                                        handleEventLocation( item );
                                                    } }
                                                    _hover={ {
                                                        backgroundColor: 'gray.100',
                                                        shadow: 1,
                                                    } }
                                                >
                                                    <VStack
                                                        left={ 2 }
                                                    >
                                                        <HStack>
                                                            <VStack width={ '94%' } left={ 2 } >
                                                                <Text fontWeight={ 500 } fontSize={ '16px' } numberOfLines={ 1 }>
                                                                    { item?.name
                                                                        ? item?.name === 'More'
                                                                            ? 'No Location Found'
                                                                            : item?.name
                                                                        : item?.title }
                                                                </Text>
                                                                <Text fontWeight={ 400 } fontSize={ '16px' } numberOfLines={ 1 }>
                                                                    { item?.address
                                                                        ? item?.address === 'More'
                                                                            ? ' '
                                                                            : item?.address
                                                                        : item?.normalized }
                                                                </Text>
                                                            </VStack>
                                                            <Image
                                                                source={ locationsvg }
                                                                alt='location'
                                                                size='20px'
                                                                alignSelf={ 'center' }
                                                            />
                                                        </HStack>
                                                    </VStack>
                                                </Pressable>

                                            </div>
                                        ) ) }

                                    <HStack
                                        width={ '100%' }
                                        alignSelf={ 'flex-end' }
                                        justifyContent={ 'flex-end' }
                                        space={ 1 }
                                        m={ 1 }
                                        p={ 3 }
                                        mt={ '1' }
                                        bgColor={ 'AVColor.white' }
                                    >
                                        { !searchLoader && (
                                            <HStack space={ 2 }>
                                                <Pressable onPress={ () => handleEventLocation( 'More' ) }>
                                                    <Text color='#00A6BC' fontWeight={ 400 } >{ Loc.currentLang.menu.moreLocation}</Text>
                                                </Pressable>
                                            </HStack>
                                        ) }
                                        { searchLoader && <div className='loader'></div> }
                                    </HStack>
                                </VStack>
                            </ScrollView>
                        </Box>
                    </>
                ) }
                {/*LocationNotFound*/ }
                { locationNotFound && (
                    <>
                        <Box
                            width={ '100%' }
                            left='0.5px'
                            position='absolute'
                            bottom='65px'
                            height='70px'
                            bgColor={ [ 'AVColor.white' ] }
                            shadow={ [ 10 ] }
                            borderColor={ 'AVColor.grey' }
                            borderWidth={ 1 }
                            rounded={ 'lg' }
                            justifyContent={ 'center' }
                            alignItems={ 'center' }
                        >
                            <Text fontSize={ 16 } fontWeight={ 800 }>
                                { Loc.currentLang.menu.noLocationFound }
                            </Text>
                        </Box>
                    </>
                ) }

            </Box>
            <HStack marginTop={'20px'}>
                { ( eventLocation?.trim()?.length > 0 && siteField?.length === 0 && ( visiblefield || isShowEdit ) ) && (
                    <Pressable
                        width={ '100%' }
                        marginTop={ '10px' }
                        onPress={ () =>
                        {
                            setShowFields( true );
                            setIsUpdateField( false );
                            setIsFieldName( '' );
                        } }
                    >
                        <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.addField }</Text>
                    </Pressable>
                ) }
                { (siteField?.length > 0 || datafield?.length > 0 ) && ( visiblefield || isShowEdit ) && (
                    <HStack width={ '100%' }>
                        <HStack width={ '35%' }>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>{ Loc.currentLang.menu.fieldaorRoom }</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={ fieldValue }
                    label={ Loc.currentLang.menu.fieldaorRoom }
                    onChange={ (e) => {
                      setIsActives(!isActives);
                      setFieldValue(e.target.value)
                      handleupdatelocationfield(e.target.value, siteField);
                    } }
                    MenuProps={ {
                      PaperProps: {
                        style: {
                          maxHeight: 300,  // Adjust the maxHeight as per your requirement
                        },
                      },
                    } }

                  >
                    { siteField?.map((option) => (
                      <MenuItem key={ option.id } value={  option.name  }>
                        { option?.name }
                      </MenuItem>
                    )) }
                  </Select>
                </FormControl>

                        </HStack>
                        <Pressable
                            marginTop={ '10px' }
                            marginLeft={ '40px' }
                            onPress={ () =>
                            {
                                setShowFields( true );
                                setIsUpdateField( true );
                                setIsFieldName( '' )
                            } }
                        >
                            <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.editField }</Text>
                        </Pressable>
                    </HStack>
                ) }
            </HStack>
            { showFields && ( <AVFieldMap updateField={ updateField } setShowFields={ setShowFields } locationEntity={ locationEntity } datafield={ datafield } fieldName={ fieldName } leagueTeamFK={ Entity } setLocationEntity={ setLocationEntity } locationaddress={ locationaddress } setIsDataField={ setIsDataField } setIsFieldName={ setIsFieldName } siteField={ siteField } setSiteField={ setSiteField } isCreateevent={ isCreateevent } setIsUpdateField={ setIsUpdateField } />)}
        </>

    );
};

export default AVLocationBox;
